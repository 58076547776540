import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/header";
import SearchList from "../../components/search-list";
import "./reportList.css";
import { getSearchResults } from "../../redux/actions";
import { IS_DASHBOARD_RESULTS_LOADING } from "../../redux/constants";

const SearchResults = () => {
  const dispatch = useDispatch();

  const searchResults = useSelector((state) => state.search.searchResults);
  const searchTerm = useSelector((state) => state.search.searchTerms);
  const user = useSelector((state) => state.user);
  const { isDashboardResultsLoading } = useSelector((state) => state.report);

  useEffect(() => {
    dispatch({ type: IS_DASHBOARD_RESULTS_LOADING, payload: true });
    dispatch(getSearchResults({ UserID: user.userId, searchText: searchTerm }));
  }, [searchTerm]);

  const [FilteredReports, setFilteredReports] = useState(searchResults || []);

  useEffect(() => {
    if (searchResults) {
      setFilteredReports(searchResults);
    }
  }, [searchResults]);

  return (
    <Box
      component="main"
      className="all-reports"
      sx={{ flexGrow: 1, paddingBottom: "2rem" }}
    >
      <Header />
      <SearchList
        reports={FilteredReports}
        searchTerm={searchTerm}
        isLoading={isDashboardResultsLoading}
      />
    </Box>
  );
};

export default SearchResults;
