import {
  SET_USER_TYPE,
  SET_USER_GROUPIDS,
  SET_USERNAME_AND_USERID,
  SET_USER_AVATAR,
  SET_USER_DETAIL,
} from "../constants";

const initialState = {
  isAdmin: null,
  userId: "",
  userEmailId: "",
  userName: "",
  firstName: "",
  lastName: "",
  lastLoginTime: "",
  avatarImage: null,
  groupIds: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_GROUPIDS:
      return { ...state, groupIds: action.payload };
    case SET_USERNAME_AND_USERID:
      return {
        ...state,
        userId: action.payload.userId,
        userName: action.payload.userName,
      };
    case SET_USER_DETAIL:
      let { mail, givenName, surname } = action.payload;
      return {
        ...state,
        userEmailId: mail,
        firstName: givenName,
        lastName: surname,
      };
    case SET_USER_AVATAR:
      return { ...state, avatarImage: action.payload };
    case SET_USER_TYPE:
      return { ...state, isAdmin: action.payload };
    default:
      return state;
  }
}
