// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-box {
  width: 100%;
  margin-top: 1rem;
}

.table-box div {
  font-family: "Roboto Slab", serif;

  font-weight: bold;
  color: #444444;
  font-size: 12px;
}

.table-box td {
  font-family: "Roboto Slab", serif;

  color: #444444;
  font-size: 0.75rem;
}

.group-label {
  background-color: #c4a7c6;
  color: #5a0f52;
  margin: 0.5rem;
  padding: 4px 6px 4px 6px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 5px;
}

.no-report-found {
  color: #000;
  text-align: center;
  margin-top: 4rem;
}

.sort-btn {
  width: 3px;
  height: 3px;
}

.sort-btn-disable {
  width: 3px;
  height: 3px;
  opacity: 0.3;
}

.sort-icon {
  width: 0.7rem;
  height: 0.7rem;
}

.persona-group-mapping .persona-td {
  display: flex;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/views/persona-group-mapping/personaGroupMapping.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;;EAEjC,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,iCAAiC;;EAEjC,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,cAAc;EACd,wBAAwB;EACxB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".table-box {\n  width: 100%;\n  margin-top: 1rem;\n}\n\n.table-box div {\n  font-family: \"Roboto Slab\", serif;\n\n  font-weight: bold;\n  color: #444444;\n  font-size: 12px;\n}\n\n.table-box td {\n  font-family: \"Roboto Slab\", serif;\n\n  color: #444444;\n  font-size: 0.75rem;\n}\n\n.group-label {\n  background-color: #c4a7c6;\n  color: #5a0f52;\n  margin: 0.5rem;\n  padding: 4px 6px 4px 6px;\n  font-size: 11px;\n  font-weight: bold;\n  border-radius: 5px;\n}\n\n.no-report-found {\n  color: #000;\n  text-align: center;\n  margin-top: 4rem;\n}\n\n.sort-btn {\n  width: 3px;\n  height: 3px;\n}\n\n.sort-btn-disable {\n  width: 3px;\n  height: 3px;\n  opacity: 0.3;\n}\n\n.sort-icon {\n  width: 0.7rem;\n  height: 0.7rem;\n}\n\n.persona-group-mapping .persona-td {\n  display: flex;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
