import {
  SET_FILTER_DRAWER,
  SET_LOADER_STATUS,
  SET_MAIN_DRAWER,
} from "../constants";

const initialState = {
  isLoading: false,
  isFilterDrawerOpen: false,
  isMainDrawerOpen: false,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADER_STATUS:
      return { ...state, isLoading: action.payload };
    case SET_FILTER_DRAWER:
      return { ...state, isFilterDrawerOpen: action.payload };
    case SET_MAIN_DRAWER:
      return { ...state, isMainDrawerOpen: action.payload };
    default:
      return state;
  }
}
