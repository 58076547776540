// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-reports .header-bottom .header-bottom-img {
  height: 65px;
  width: 86px;
  background-size: contain !important;
  background-position: right !important;
}`, "",{"version":3,"sources":["webpack://./src/views/report-list/reportList.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,mCAAmC;EACnC,qCAAqC;AACvC","sourcesContent":[".all-reports .header-bottom .header-bottom-img {\n  height: 65px;\n  width: 86px;\n  background-size: contain !important;\n  background-position: right !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
