import React, { useState, useEffect } from 'react'
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import ReportTile from '../report-tile'
import { FilterDrawer } from '../report-filter'
import { useDispatch, useSelector } from 'react-redux'
import { IS_REPORT_UPDATED, SET_CHECKED_FILTER, SET_FILTER_DRAWER } from '../../redux/constants'
import './ReportList.css'
import { NO_CONTENT_FOUND } from '../../constants/constants'
import { useParams } from 'react-router-dom'
import { getAllReports } from '../../redux/actions'

const ReportList = ({ contents, groupName }) => {

  const dispatch = useDispatch()
  const params = useParams();

  const [filtersArray, setFiltersArray] = useState([])
  const [FilteredReports, setFilteredReports] = useState(contents || []);

  const { isReportUpdated } = useSelector(state => state.report);
  const user = useSelector(state => state.user);

  const handleFilters = (value) => {
    setFiltersArray(value)
  }

  useEffect(() => {
    dispatch({ type: SET_CHECKED_FILTER, payload: [] });
    setFiltersArray([]);
    return () => {
      dispatch({ type: SET_CHECKED_FILTER, payload: [] });
    }
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (isReportUpdated) {
      setTimeout(() => {
        dispatch(getAllReports({ UserId: user.userId, groupIds: user.groupIds }));
      }, 1000);
      dispatch({ type: IS_REPORT_UPDATED, payload: false });
    }
    // eslint-disable-next-line 
  }, [isReportUpdated])

  useEffect(() => {
    if (contents.length) {
      if (filtersArray.length === 0) {
        setFilteredReports(contents)
      }
      else {
        const filteredValues = contents?.filter(item => filtersArray.find(filteritem => item.theme.includes(filteritem)));
        setFilteredReports(filteredValues);
      }
    }
  }, [filtersArray, contents])

  return (
    <Box sx={{ px: 3 }}>
      <Box className='reportsGroupHeader'>
        {groupName === 'favourites' ?
          <Typography variant='headingTwo'>{'Favourite Apps and Reports'}  {`(${FilteredReports.length})`}</Typography> :
          <Typography variant='headingTwo'>{(groupName).replace(/-/g, ' ')}  {`(${FilteredReports.length})`}</Typography>
        }
        <IconButton onClick={() => dispatch({ type: SET_FILTER_DRAWER, payload: true })}>
          <img src='/icons/icon_filter.svg' alt='filter_icon' />
        </IconButton>
      </Box>
      <Divider sx={{ marginBottom: '0.8rem' }} />
      {FilteredReports?.length === 0 ? <Box className='list-no-report'>{NO_CONTENT_FOUND}</Box> : <Grid container spacing={2}>
        {FilteredReports.map((content, index) => {
          return <Grid item xs={6} md={2} sm={4} key={index}>
            <ReportTile content={content} />
          </Grid>
        })}
      </Grid>}
      <FilterDrawer handleFilters={(filters) => handleFilters(filters)} />
    </Box>
  )
}

export default ReportList;