import { SET_ERROR_MODAL, SET_LOADER_STATUS } from "../../redux/constants";

const ExportReport = ({ workspaceId, reportId, dispatch }) => {
  const accessToken = sessionStorage.getItem("emb_access_token");

  let downloaded = false;

  if (workspaceId && reportId) {
    dispatch({ type: SET_LOADER_STATUS, payload: true });

    fetch(
      `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}/ExportTo`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ format: "PDF" }),
      }
    ).then(function (response) {
      if (response.status === 202) {
        for (let pair of response.headers.entries()) {
          if (pair[0] === "location") {
            let strSplit = pair[1].split("/");
            let exportId = strSplit[strSplit.length - 1];
            getFileStatus(exportId);
          }
        }
      } else if (response.status === 400 || response.status === 404) {
        dispatch({ type: SET_LOADER_STATUS, payload: false });
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "An error occurred while export, please try later",
          },
        });
      } else if (response.status === 401 || response.status === 403) {
        dispatch({ type: SET_LOADER_STATUS, payload: false });
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "You do not have access to export the report",
          },
        });
      }
    });
  }
  //api call to fetch the status of the report file
  const getFileStatus = (exportId) => {
    fetch(
      `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}/exports/${exportId}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken, //get it from session storage
        },
        method: "GET",
      }
    )
      .then(function (response) {
        if (response.status === 200) {
          if (!downloaded) {
            download(exportId);
          }
        } else if (response.status === 202) {
          setTimeout(function () {
            getFileStatus(exportId);
          }, 2000);
        } else if (response.status === 400 || response.status === 404) {
          dispatch({ type: SET_LOADER_STATUS, payload: false });
          dispatch({
            type: SET_ERROR_MODAL,
            payload: {
              status: true,
              errorMessage: "An error occurred while export, please try later",
            },
          });
        } else if (response.status === 401 || response.status === 403) {
          dispatch({ type: SET_LOADER_STATUS, payload: false });
          dispatch({
            type: SET_ERROR_MODAL,
            payload: {
              status: true,
              errorMessage: "You do not have access to export the report",
            },
          });
        }
      })
      .catch(console.log("Trying to download..."));

    //api call to download the file
    function download(exportID) {
      if (!downloaded) {
        downloaded = true;
        fetch(
          `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}/exports/${exportID}/file`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
            method: "GET",
          }
        ).then(function (response) {
          if (response.status === 200) {
            dispatch({ type: SET_LOADER_STATUS, payload: false });
            return response.blob().then((b) => {
              downloaded = true;
              let a = document.createElement("a");
              a.href = URL.createObjectURL(b);
              a.setAttribute("download", reportId);
              a.click();
              a.remove();
            });
          } else if (response.status === 400 || response.status === 404) {
            dispatch({ type: SET_LOADER_STATUS, payload: false });
            dispatch({
              type: SET_ERROR_MODAL,
              payload: {
                status: true,
                errorMessage:
                  "An error occurred while export, please try later",
              },
            });
          } else if (response.status === 401 || response.status === 403) {
            dispatch({ type: SET_LOADER_STATUS, payload: false });
            dispatch({
              type: SET_ERROR_MODAL,
              payload: {
                status: true,
                errorMessage: "You do not have access to export the report",
              },
            });
          }
        });
      }
    }
  };
  return <div>Export Report</div>;
};

export default ExportReport;
