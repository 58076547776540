// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterDrawer {
  width: 200px;
  padding: 0.5rem 1rem 1rem;
  overflow: auto;
}

.check-box {
  margin-left: -5px;
}

.check-box span svg {
  font-size: 0.9rem;
}

.check-box span {
  font-family: "Roboto Slab", serif;

  font-size: 0.75rem;
  color: "#444";
  font-weight: 400;
  padding: 3px 5px;
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.filterFooter {
  margin-top: 1rem;
}

.filterFooter button {
  font-family: "Roboto Slab", serif;

  font-size: 0.65rem;
  font-weight: 600;
  min-width: 90px;
}
`, "",{"version":3,"sources":["webpack://./src/components/report-filter/Filter.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;;EAEjC,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;;EAEjC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".filterDrawer {\n  width: 200px;\n  padding: 0.5rem 1rem 1rem;\n  overflow: auto;\n}\n\n.check-box {\n  margin-left: -5px;\n}\n\n.check-box span svg {\n  font-size: 0.9rem;\n}\n\n.check-box span {\n  font-family: \"Roboto Slab\", serif;\n\n  font-size: 0.75rem;\n  color: \"#444\";\n  font-weight: 400;\n  padding: 3px 5px;\n}\n\n.filterHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 1rem;\n}\n\n.filterFooter {\n  margin-top: 1rem;\n}\n\n.filterFooter button {\n  font-family: \"Roboto Slab\", serif;\n\n  font-size: 0.65rem;\n  font-weight: 600;\n  min-width: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
