import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MainTile from './components/MainTile/mainTile';
import { homeTopics } from '../../constants/constants';


const HomePage = () => {

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    setTopics(homeTopics);
  });

  return (
    <Box className='home' component="main" sx={{ flexGrow: 1, backgroundImage: `url(./images/home-bg.png)`, paddingBottom: '1.5rem', textAlign: 'center', backgroundSize: '100% 100%', overflow: 'hidden', height: '100%' }}>
      <img style={{ width: '320px', marginTop: '-35px', marginBottom: '-32px' }} src='./images/IH-logo.png' />
      <br />
      <Grid className='main-tile' container rowSpacing={4} columnSpacing={1} sx={{textAlign:'-webkit-center', padding: '0 60px'}}>
        {
          topics.filter(item => item.visible).map((topic, index) => (
            <Grid key={index} item xs={12} md={3}>
              <MainTile src={topic.url} link={topic.link} title={topic.title} content={topic.content} newTab={topic.newTab} />
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

export default HomePage