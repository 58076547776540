import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./personaGroupMapping.css";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import { getPersonaGroupMappings } from "../../redux/actions";
import { NO_MAPPING_FOUND } from "../../constants/constants";

const headCells = ["Persona Name", "Groups mapped to"];

export default function PersonaGroupTable(props) {
  const dispatch = useDispatch();

  const [selectedSort, setSelectedSort] = useState("");
  const { personaGroupMappings } = useSelector(
    (state) => state.userGroupManagement
  );

  useEffect(() => {
    dispatch(getPersonaGroupMappings());
    // eslint-disable-next-line
  }, []);

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              align={"left"}
              padding={"normal"}
              sx={index === 0 ? { width: "25%" } : {}}
            >
              <Box sx={{ display: "flex" }}>
                {headCell}
                {index === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "0.5rem",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      size="small"
                      className={
                        selectedSort === "asc" ? "sort-btn-disable" : "sort-btn"
                      }
                      onClick={() => {
                        setSelectedSort("asc");
                        if (selectedSort !== "asc") {
                          props.handleSort("asc");
                        }
                      }}
                    >
                      <img
                        className="sort-icon"
                        src="./icons/icn_uparrow.svg"
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      className={
                        selectedSort === "desc"
                          ? "sort-btn-disable"
                          : "sort-btn"
                      }
                      onClick={() => {
                        setSelectedSort("desc");
                        if (selectedSort !== "desc") {
                          props.handleSort("desc");
                        }
                      }}
                    >
                      <img
                        className="sort-icon"
                        src="./icons/icn_downarrow.svg"
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box className="table-box">
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
        <TableContainer>
          <Table
            sx={{ width: "95%", marginLeft: "2rem", marginRight: "2rem" }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead />
            <TableBody>
              {personaGroupMappings?.map((item, index) => {
                return (
                  <TableRow key={index} style={{ height: "2.5rem" }}>
                    <TableCell sx={{ width: "25%" }} align="left">
                      {item.personaName}
                    </TableCell>
                    <TableCell className="persona-td" align="left">
                      {item.adGroupList.map((item2, index2) => {
                        return (
                          <span key={index2} className="group-label">
                            {item2}
                          </span>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {personaGroupMappings?.length === 0 && (
          <Box className="no-report-found">{NO_MAPPING_FOUND}</Box>
        )}
      </Paper>
    </Box>
  );
}
