// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    background-color: #F7F5F3 !important;
    color: #4B3C32 !important;
}

.logo-name {
    display: flex;
    flex-direction: column;
}

.menu-text {
    font-size: 13px;
    white-space: normal;
}

.drawer-footer {
    flex-direction: column;
    bottom: 0;
    text-align: center;
    padding-bottom: 1rem;
}

.MuiList-root.MuiMenu-list {
    background-color: #EBEBEB;
}

.MuiDrawer-paper {
    overflow: hidden !important;
}

@media (max-width: 1024px) {

    .mainDrawer {
        width: 0;
    }

    .css-1rzvfzk-MuiDrawer-docked .MuiDrawer-paper,
    .css-1rzvfzk-MuiDrawer-docked {
        width: 0 !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/drawer/drawer.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;;IAEI;QACI,QAAQ;IACZ;;IAEA;;QAEI,mBAAmB;IACvB;AACJ","sourcesContent":[".css-12i7wg6-MuiPaper-root-MuiDrawer-paper {\n    background-color: #F7F5F3 !important;\n    color: #4B3C32 !important;\n}\n\n.logo-name {\n    display: flex;\n    flex-direction: column;\n}\n\n.menu-text {\n    font-size: 13px;\n    white-space: normal;\n}\n\n.drawer-footer {\n    flex-direction: column;\n    bottom: 0;\n    text-align: center;\n    padding-bottom: 1rem;\n}\n\n.MuiList-root.MuiMenu-list {\n    background-color: #EBEBEB;\n}\n\n.MuiDrawer-paper {\n    overflow: hidden !important;\n}\n\n@media (max-width: 1024px) {\n\n    .mainDrawer {\n        width: 0;\n    }\n\n    .css-1rzvfzk-MuiDrawer-docked .MuiDrawer-paper,\n    .css-1rzvfzk-MuiDrawer-docked {\n        width: 0 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
