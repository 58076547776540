import { useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./ListStyle.css";

const useStyles = {
  appItem: {
    cursor: "pointer",
    borderBottom: "1px solid #826E5F",
    backgroundColor: "#D9CDC1",
    color: "#5D4F47",
    wordBreak: "break-word",
    padding: "0 4px 0 11px",
    minWidth: '224px',
  },
  activeItem: {
    backgroundColor: "#826E5F",
    color: "#F7F5F3",
    // borderLeft: "4px solid #F07300",
  },
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    // fontSize: 11,
  },
}));

export default function ListDrawer({
  appList,
  selectedItem,
  appListOnClickHandle,
  appName,
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };


  return (
    <div className={`drawer ${isDrawerOpen ? "open" : "closed"}`}>
      <div className="appname">
        {isDrawerOpen ? <div>Contents</div> : ""}
        {isDrawerOpen ? (
          <IconButton onClick={handleDrawer} style={{ right: "12px" }}>
            <ArrowLeftIcon />
          </IconButton>
        ) : (
          <LightTooltip
            title="Click to view the contents"
            placement="top"
          >
            <IconButton onClick={handleDrawer} style={{ right: "15px" }}>
              <ArrowRightIcon />
            </IconButton>
          </LightTooltip>
        )}
      </div>
      <Divider sx={{ backgroundColor: "#826E5F" }} />
      {/* {isDrawerOpen ? ( */}
      <div>
        <List
          sx={{
            backgroundColor: "#D9CDC1",
            position: "relative",
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "90.6vh",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.2em",
              height: "auto",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {appList.length
            ? appList.map((item, index) => (
                <ListItem
                  key={index}
                  style={{
                    ...useStyles.appItem,
                    ...(selectedItem === index ? useStyles.activeItem : {}),
                    marginTop: index === 0 ? "-8px" : "0",
                    marginBottom: index === appList.length - 1 ? "-8px" : "0",
                  }}
                >
                  <div
                    onClick={() =>
                      appListOnClickHandle(item.reportType, item.id, index)
                    }
                  >
                    <ListItemText>
                      <div
                        style={{
                          fontSize: "0.85rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        { selectedItem === index ? <div className="active-item"></div> : '' }
                        <div style={{ padding: "0 4px" }}>
                          {(() => {
                            switch (item?.reportType) {
                              case "PowerBIReport":
                                return <LightTooltip title={item.name || item.displayName} placement="top">
                                          <img src="/icons/report_icon.svg" />
                                        </LightTooltip>;
                              case "PaginatedReport":
                                return <LightTooltip title={item.name || item.displayName} placement="top">
                                  <img src="/icons/paginated_report_icon.svg" />
                                  </LightTooltip>;
                              default:
                                return <LightTooltip title={item.name || item.displayName} placement="top">
                                          <img src="/icons/dashboard_icon.svg" />
                                        </LightTooltip>;
                            }
                          })()}
                        </div>
                        <div>
                          {isDrawerOpen ? (
                            <span>{item.name || item.displayName}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </ListItemText>
                  </div>
                </ListItem>
              ))
            : ""}
        </List>
      </div>
      {/* ) : (
        ""
      )} */}
    </div>
  );
}
