import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';
import './Button.css';

export const ButtonBack = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const backBtnHandler = () => {
    if (location.key === 'default') {
      navigate('/dashboard')
    } else {
      navigate(-1)
    }
  }

  return (
    <Button className='button-back' onClick={backBtnHandler} startIcon={<ChevronLeftIcon />}>Back</Button>
  )
}