import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Container,
  Button,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import "../../dashboard.css";

import ReportTile from "../../../../components/report-tile";
import {
  NO_REPORT_FOUND,
  NO_APP_FOUND,
  NO_FAVOURITE_FOUND,
} from "../../../../constants/constants";
import { SET_TAB_INDEX } from "../../../../redux/constants";
import { ResponseLoader } from "../../../../components/loader";

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    "&.Mui-selected": {
      color: "#F18523",
    },
    textTransform: "none",
    minWidth: "60px",
  })
);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default function DashboardTabs() {
  const dispatch = useDispatch();
  const {
    dashboardContentDetails,
    dashboardTabIndex,
    isDashboardResultsLoading,
  } = useSelector((state) => state.report);
  const [value, setValue] = useState(dashboardTabIndex);

  const favourite = dashboardContentDetails.get("Favourite");
  const app = dashboardContentDetails.get("App");
  const report = dashboardContentDetails.get("Report");
  const handleChange = (event, newValue) => {
    dispatch({ type: SET_TAB_INDEX, payload: newValue });
    setValue(newValue);
  };

  return (
    <Container maxWidth="70%">
      <Box sx={{ px: 1, borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#F18523",
            },
          }}
        >
          <StyledTab label="Favourite" />
          <StyledTab label="Apps" />
          <StyledTab label="Reports" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {isDashboardResultsLoading ? (
          <ResponseLoader
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "13%",
            }}
            loadingText="  Loading..."
          />
        ) : (
          <>
            {favourite !== undefined && favourite?.size > 0 ? (
              Array.from(favourite).map(([key, value]) => (
                <>
                  <Box className="dashGroupHeader" sx={{ mt: 4 }}>
                    <Typography
                      sx={{ px: 2, color: "#707070" }}
                      variant="headingTwo"
                    >
                      {"Favourite Apps and Reports"}
                    </Typography>
                    {value?.length ? (
                      <Link to={"/groups/favourites"}>
                        <Button className="viewAllBtn">VIEW ALL</Button>
                      </Link>
                    ) : (
                      <Button disabled className="viewAllBtn">
                        VIEW ALL
                      </Button>
                    )}
                  </Box>
                  <Divider sx={{ marginBottom: "0.8rem" }} />
                  <Grid container spacing={2}>
                    {value?.map((content, index) => {
                      if (index < 5) {
                        return (
                          <Grid item xs={6} md={2} sm={4} key={index}>
                            <ReportTile content={content} />
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </>
              ))
            ) : (
              <Box className="no-report">{NO_FAVOURITE_FOUND}</Box>
            )}
          </>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {app !== undefined && app.size > 0 ? (
          Array.from(app).map(([key, value]) => (
            <>
              <Box className="dashGroupHeader" sx={{ mt: 4 }}>
                <Typography
                  sx={{ px: 2, color: "#707070" }}
                  variant="headingTwo"
                >
                  {key}
                </Typography>
                {value?.length ? (
                  <Link to={`/apps/${key.replace(/ /g, "-")}`}>
                    <Button className="viewAllBtn">VIEW ALL</Button>
                  </Link>
                ) : (
                  <Button disabled className="viewAllBtn">
                    VIEW ALL
                  </Button>
                )}
              </Box>
              <Divider sx={{ marginBottom: "0.8rem" }} />
              <Grid container spacing={2}>
                {value.map((content, index) => {
                  if (index < 5) {
                    return (
                      <Grid item xs={6} md={2} sm={4} key={index}>
                        <ReportTile content={content} />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </>
          ))
        ) : (
          <Box className="no-report">{NO_APP_FOUND}</Box>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {report !== undefined && report.size > 0 ? (
          Array.from(report).map(([key, value]) => (
            <>
              <Box className="dashGroupHeader" sx={{ mt: 4 }}>
                <Typography
                  sx={{ px: 2, color: "#707070" }}
                  variant="headingTwo"
                >
                  {key}
                </Typography>
                {value?.length ? (
                  <Link to={`/reports/${key.replace(/ /g, "-")}`}>
                    <Button className="viewAllBtn">VIEW ALL</Button>
                  </Link>
                ) : (
                  <Button disabled className="viewAllBtn">
                    VIEW ALL
                  </Button>
                )}
              </Box>
              <Divider sx={{ marginBottom: "0.8rem" }} />
              <Grid container spacing={2}>
                {value.map((content, index) => {
                  if (index < 5) {
                    return (
                      <Grid item xs={6} md={2} sm={4} key={index}>
                        <ReportTile content={content} />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </>
          ))
        ) : (
          <Box className="no-report">{NO_REPORT_FOUND}</Box>
        )}
      </TabPanel>
    </Container>
  );
}
