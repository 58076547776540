import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from 'moment';
import MenuIcon from '@mui/icons-material/Menu';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Rating from '@mui/material/Rating';
import { Box } from '@mui/system';
import { EmbedReportDetail } from './powerbiEmbedReport';
import { IconButton, Stack, Typography } from '@mui/material';
import './reportDetails.css';
import { FeedbackModal, ShareModal } from '../../components/modal';
import { LikeUnlikeAction, reportRating } from '../../common';
import { ButtonBack } from '../../components/button';
import { COPIED, embedTokenError } from '../../constants/constants';
import { getReportDetails, markReportLikeFavourite, setReportRating } from '../../redux/actions';
import ExportReport from '../../components/report-export/ExportReport';
import { DownloadLoader } from '../../components/loader';
import { AUTH_CONFIG } from '../../AppConstant';
import { authContext } from '../../adalConfig';
import { SET_MAIN_DRAWER, SET_REPORT_DETAILS } from '../../redux/constants';
import AppNotFound from '../app-details/AppNotFound';

const ReportDetails = () => {

  const dispatch = useDispatch();
  const params = useParams();

  const user = useSelector(state => state.user)

  useEffect(() => {
    if (user.userId) {
      dispatch(getReportDetails({ UserID: user.userId, ReportID: params.id }));
    }
  }, [user.userId]);

  const { allReports, reportDetails } = useSelector(state => state.report);
  const { searchResults } = useSelector((state) => state.search);

  const config = {
    workspaceId: reportDetails.workSpaceID,
    reportId: reportDetails.contentID,
    // workspaceId: "deceb188-8226-46b4-bb68-7506a2bfbada",//authorized
    //reportId: "7892fe54-867d-470a-8926-f5590bbc0782",//authorized
    // workspaceId: "95080f25-e7bf-4066-8689-467a557f91db",  //un authorized
    // reportId: "72f82915-0bd4-4996-a232-6cee105ca6f8",  //un authorized
    ...AUTH_CONFIG
  };

  const [acc_token, setAcc_token] = useState("");
  const [embed_url, setEmbed_url] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [showEmbed, setShowEmbed] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [appNotFound, setAppNotFound] = useState(false);
  const [error, setError] = useState("");

  const publishedDate = reportDetails?.contentPublishedDate ? Moment(reportDetails.contentPublishedDate).format('DD MMM YYYY') : "";

  useEffect(() => {
    getPowerbiEmbedToken();
    return () => {
      dispatch({ type: SET_REPORT_DETAILS, payload: {} });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(reportDetails).length && acc_token) {
      getembedUrl();
    }
  }, [reportDetails, acc_token])

  const isLoading = useSelector(state => state.app.isLoading);

  const handleRaiseTicket = () => {
    if (reportDetails.serviceNowUrlLink) {
      window.open(reportDetails.serviceNowUrlLink, "_blank");
    }
  }

  const handleExport = () => {
    ExportReport({ workspaceId: reportDetails.workSpaceID, reportId: params.id, dispatch });
  }

  const handleFeedback = () => {
    setIsFeedback(true)
  }

  const handleShare = () => {
    setIsShare(true)
  }

  const handleFeebackModal = () => {
    setIsFeedback(!isFeedback);
  }

  const handleShareModal = () => {
    setIsShare(!isShare);
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/report-details/${params.id}`);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }

  const handleLike = () => {
    if (reportDetails?.contentID) {
      let payload = { Report_Id: reportDetails.contentID, Workspace_Id: reportDetails.workSpaceID, user_id: user.userId, emailid: user.userEmailId, IsLiked: !reportDetails.isLiked ? "1" : "0", IsFavourite: reportDetails.isFavourite ? "1" : "0" };
      dispatch(markReportLikeFavourite(payload));
      const totalLiked = reportDetails.totalLikes + (!reportDetails.isLiked ? 1 : -1);
      reportDetails.totalLikes = totalLiked;
      LikeUnlikeAction({ reportId: reportDetails?.contentID, isLiked: !reportDetails.isLiked, likeCount: totalLiked, allReports,searchReports:searchResults, dispatch });

    }
  }

  const menuList = [
    { title: "Raise Ticket", src: "/icons/icn_ticket.svg", clickFunc: handleRaiseTicket },
    { title: "Feedback", src: "/icons/icn_feedback.svg", clickFunc: handleFeedback },
    // { title: "Export", src: "/icons/icn-pdf.svg", clickFunc: handleExport, showDownload: isLoading },
    { title: "Share Report", src: "/icons/icn-email-share.svg", clickFunc: handleShare },
    { title: "Copy Link", src: "/icons/icn_copy.svg", clickFunc: handleCopyLink },
  ];

  function getPowerbiEmbedToken() {
    new Promise(function (resolve, reject) {
      authContext.acquireToken("https://analysis.windows.net/powerbi/api", function (message, token, msg) {
        if (!msg) {
          setAcc_token(token);
          sessionStorage.setItem("emb_access_token", token);
          resolve(token);
        } else{
          setAppNotFound(true);
          setError(embedTokenError);
        }
        // else reject({
        //   message: message,
        //   msg: msg
        // });

      });
    });
  }


  function getembedUrl() {
    fetch("https://api.powerbi.com/v1.0/myorg/reports/" + reportDetails.contentID, {
      headers: {
        "Authorization": "Bearer " + acc_token
      },
      method: "GET"
    })
      .then(function (response) {
        response.json()
          .then(function (body) {
            // Successful response
            if (response.ok) {
              setEmbed_url(body["embedUrl"])
              showEmbed(true);
              setShowIframe(false);
            }
            // If error message is available
            else {
              if (response.status !== 200) {
                setShowIframe(true);
                setShowEmbed(false);
              }
            }

          })
          .catch(function () {
            if (response.status !== 200) {
              setShowIframe(true);
              setShowEmbed(false);
            }
          });
      })
      .catch(function (err) {
        // Error in making the API call
        console.log(err);
      })
  }


  const handleRating = (event, newValue) => {
    console.log('handleRating called', reportDetails?.contentID);
    if (reportDetails?.contentID) {
      dispatch(setReportRating({ UserId: user.userId, emailid: user.userEmailId, Rating: newValue, reportId: reportDetails.contentID, workspaceId: reportDetails.workSpaceID }));
      reportRating({ reportId: reportDetails.contentID, reportRate: newValue, allReports,searchReports: searchResults, dispatch });
    } else {
      console.log('handleRating failed');
    }
  }

  const PowerBiReport = useCallback(() => {
    return (
      <>
        {acc_token && embed_url ? <EmbedReportDetail accessToken={acc_token} embedUrl={embed_url} config={config} /> :
          showIframe && <div className='powerBiDiv' dangerouslySetInnerHTML={{ __html: reportDetails.embedUrl }}></div>
        }
      </>
    );
  }, [acc_token, embed_url, showIframe]);

  const IconText = ({ index, icon, title, onClick }) => (
    <Box key={index} className='report-dtl-IconText' onClick={onClick}>
      {icon}
      <span style={{ "fontSize": "0.75rem", "paddingLeft": "5px", "fontWeight": "600", "color": "#5D4F46" }}>{title}</span>
    </Box>
  )
  const open = useSelector(state => state.app.isMainDrawerOpen)
  const handleDrawer = () => {
    dispatch({ type: SET_MAIN_DRAWER, payload: !open })
  };

  return (
    <Box className='main-box'>
      <Box className='report-dtl-header'>
        <Box className='report-dtl-display'>
          <Box>
            <ButtonBack />
          </Box>
          <Box className='report-detail-box'>
            <span style={{ fontSize: "normal", fontWeight: "bold" }}>{reportDetails?.contentName || "Report Name"}</span>
            <div className='report-info' ><span>Published on {publishedDate},</span><span>Created by - {reportDetails?.contentOwner}</span></div>
            <Box className='liked-count-view'>
              <Stack sx={{ alignItems: 'center' }} direction='row' spacing={0}>
                <IconButton size='small' aria-label="add to favorites" onClick={handleLike}>
                  {reportDetails?.isLiked ? <ThumbUpAltIcon sx={{ color: "#F07300" }} fontSize="inherit" /> : <ThumbUpOffAltIcon fontSize="inherit" />}
                </IconButton>
                <Typography sx={{ fontSize: '11px', fontWeight: '600' }}>{reportDetails?.totalLikes || 0} Likes</Typography>
              </Stack>
              <Rating onChange={handleRating} precision={0.5} size='small' style={{ verticalAlign: "middle", marginLeft: "30px", }} value={reportDetails?.rating || reportDetails?.reportRate || 0} />
              <Typography style={{ paddingLeft: "5px", fontSize: '0.75rem', fontWeight: "bold" }}>{reportDetails?.rating || reportDetails?.reportRate || 0}/5</Typography>
            </Box>
          </Box>
        </Box>

        <Box className='icon-box-main'>
          {isCopied && <span className='copied-text'>{COPIED}</span>}
          <Box className='icon-box'>
            {
              menuList.map((item, index) => (item.showDownload ? <Box className='icon-box-main'> <DownloadLoader loadingText='Downloading...' /></Box> : <IconText key={index} icon={<img className='avatar-icon' src={item.src} />} title={item.title} onClick={item.clickFunc} />)
              )
            }
          </Box>
        </Box>

      </Box>
      {appNotFound ? (
        <AppNotFound message={error} />
      ) : ( 
        <Box >
          <PowerBiReport />
        </Box>
      )}
      {<FeedbackModal reportId={reportDetails?.contentID} modalTitle='Feedback Form' inputPlaceHolder='Write a feedback' openFormDialog={isFeedback} handleClose={handleFeebackModal} />}
      {isShare && <ShareModal openShareModal={isShare} handleClose={handleShareModal} reportId={reportDetails?.contentID} contentType={reportDetails?.contentType}/>}
      <Box className='responsiveMenu'>
        <IconButton onClick={handleDrawer} aria-label="menu" size="small">
          {window.innerWidth <= 1024 && <MenuIcon fontSize="inherit" />}
        </IconButton>
      </Box>
    </Box>
  )
}

export default ReportDetails
