import React, { useState } from "react";
import { Card, Box, Menu, MenuItem, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ShareModal, UpdateThumbnailModal } from "../modal";
import { papeProps } from "../paper-props";
import "./ReportTile.css";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FavouriteUnfavouriteAction, LikeUnlikeAction } from "../../common";
import { COPIED, SHARE_REPORT, SHARE_APP } from "../../constants/constants";
import { markReportLikeFavourite } from "../../redux/actions";
// import ExportReport from "../report-export/ExportReport";
import { DownloadLoader } from "../../components/loader";

let currentReportId = "";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="top" {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f7f7f7",
    maxWidth: "170px",
    color: "#444444",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontFamily: "Roboto Slab",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: "#f7f7f7 !important",
      boxShadow: theme.shadows[1],
    },
  },
}));

export default function ReportTile({ content }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isShare, setIsShare] = useState(false);
  const [thumbnailOpen, setThumbnailOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const allReports = useSelector((state) => state.report.allReports);
  const searchResults = useSelector((state) => state.search.searchResults);
  const user = useSelector((state) => state.user);

  const isLoading = useSelector((state) => state.app.isLoading);

  // const handleExport = (id) => {
  //   currentReportId = id;
  //   handleClose();
  //   ExportReport({
  //     workspaceId: content.workSpaceID,
  //     reportId: content.contentID,
  //     dispatch,
  //   });
  // };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShare = () => {
    setIsShare(true);
  };

  const handleShareModal = () => {
    setAnchorEl(null);
    setIsShare(!isShare);
  };

  const handleThumbnailOpen = () => {
    setThumbnailOpen(true);
  };

  const handleThumbnailModal = () => {
    setAnchorEl(null);
    setThumbnailOpen(!thumbnailOpen);
  };

  const handleCopyLink = () => {
    const contentDetails = content?.contentType === 'Report' ? 'report-details' : 'app-details';
    navigator.clipboard.writeText(
      `${window.location.origin}/${contentDetails}/${content.contentID}`
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };
  const handleLike = () => {
    let payload = {
      Report_Id: content.contentID,
      Workspace_Id: content.workSpaceID,
      user_id: user.userId,
      emailid: user.userEmailId,
      IsLiked: !content.isLiked ? "1" : "0",
      IsFavourite: content.isFavourite ? "1" : "0",
    };
    dispatch(markReportLikeFavourite(payload));
    const totalLiked = !content.isLiked
      ? (content.totalLikes = content.totalLikes + 1)
      : (content.totalLikes = content.totalLikes - 1);
    LikeUnlikeAction({
      reportId: content?.contentID,
      isLiked: !content.isLiked,
      likeCount: totalLiked,
      allReports,
      searchReports: searchResults,
      dispatch,
    });
  };
  const handleFavourite = () => {
    if (content?.contentID) {
      let payload = {
        Report_Id: content.contentID,
        Workspace_Id: content.workSpaceID,
        user_id: user.userId,
        emailid: user.userEmailId,
        IsLiked: content.isLiked ? "1" : "0",
        IsFavourite: !content.isFavourite ? "1" : "0",
      };
      dispatch(markReportLikeFavourite(payload));
      const totalFav = !content.isFavourite
        ? (content.totalFavourite += 1)
        : (content.totalFavourite -= 1);
      FavouriteUnfavouriteAction({
        reportId: content.contentID,
        isFavourite: !content.isFavourite,
        favCount: totalFav,
        allReports,
        searchReports: searchResults,
        dispatch,
      });
    }
  };

  const shareButtonText = (type) => {
    if (type === 'Report') {
      return SHARE_REPORT
    } else {
      return SHARE_APP
    }
  }

  const actionOptions = [
    { label: "Copy Link", clickFunc: handleCopyLink },
    { label: shareButtonText(content?.contentType), clickFunc: handleShare },
  ];

  const handlePowerbiContent = (contentId) => {
    if (content?.contentType === 'Report') {
      navigate(`/report-details/${contentId}`);
    } else {
      navigate(`/app-details/${contentId}`);
    }
  }

  return (
    <>
      <Card
        onClick={() => { handlePowerbiContent(content?.contentID) }}
      >
        {/* <img src='/images/report-1.jpg'/> */}
        <Box
          className="report-card-img"
          sx={{
            cursor: "pointer",
            background: `url(${content?.imageSource || "/images/report-1.jpg"
              }) no-repeat center/100% 100%`,
          }}
        ></Box>
        <CardContent
          sx={{ cursor: "pointer", padding: "0px 16px", marginBottom: "-2px" }}
        >
          <Typography
            className="report-tile-title"
            sx={{ marginBottom: "3px" }}
          >
            {content?.contentName}
          </Typography>
          <LightTooltip
            placement="top"
            title={content?.contentDescription}
            arrow
            className="card-tooltip"
          >
            <Typography className="report-tile-desc">
              {content?.contentDescription?.substring(0, 25)}
              {content?.contentDescription?.length > 40 && `...`}
            </Typography>
          </LightTooltip>
        </CardContent>

        {isLoading && content.contentID === currentReportId ? (
          <DownloadLoader loadingText="Downloading..." />
        ) : (
          <CardActions
            sx={{ display: "flex", justifyContent: "space-between" }}
            disableSpacing
            onClick={(event) => event.stopPropagation()}
          >
            <Stack sx={{ alignItems: "center" }} direction="row" spacing={0}>
              <IconButton
                size="small"
                aria-label="add to favorites"
                onClick={handleLike}
              >
                {content?.isLiked ? (
                  <ThumbUpAltIcon
                    sx={{ color: "#F07300" }}
                    fontSize="inherit"
                  />
                ) : (
                  <ThumbUpOffAltIcon fontSize="inherit" />
                )}
              </IconButton>
              <Typography sx={{ fontSize: "0.75rem", fontWeight: "600" }}>
                {content?.totalLikes || 0} Likes
              </Typography>
            </Stack>
            <Box>
              <IconButton
                size="small"
                aria-label="share"
                onClick={handleFavourite}
              >
                {content?.isFavourite ? (
                  <FavoriteIcon sx={{ color: "#F07300" }} fontSize="inherit" />
                ) : (
                  <FavoriteBorderIcon fontSize="inherit" />
                )}
              </IconButton>
              <IconButton
                size="small"
                onClick={handleMoreClick}
                aria-label="settings"
              >
                <MoreVertIcon fontSize="inherit" />
              </IconButton>
              <Menu
                id="moreOptions"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={papeProps}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
              >
                {isCopied && <span className="menu-copied-text">{COPIED}</span>}
                {actionOptions.map((item, index) => (
                  <MenuItem
                    className="tile-menu-item"
                    key={index}
                    onClick={() => item.clickFunc(content.contentID)}
                  >
                    {item.label}
                  </MenuItem>
                ))}
                {user.isAdmin && (
                  <MenuItem
                    className="tile-menu-item"
                    key={"admin-option"}
                    onClick={handleThumbnailModal}
                  >
                    Update thumbnail
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </CardActions>
        )}
      </Card>
      {isShare && (
        <ShareModal
          openShareModal={isShare}
          handleClose={handleShareModal}
          reportId={content?.contentID}
          contentType={content?.contentType}
        />
      )}
      {thumbnailOpen && (
        <UpdateThumbnailModal
          openDialog={handleThumbnailOpen}
          handleClose={handleThumbnailModal}
          reportId={content?.contentID}
        />
      )}
    </>
  );
}
