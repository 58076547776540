import { 
  GET_USER_TYPE, 
  GET_USER_GROUPIDS, 
  GET_USER_AVATAR, 
  SET_USER_AVATAR, 
  GET_REPORT_DETAILS, 
  GET_ALL_REPORTS, 
  MARK_REPORT_LIKE_FAVOURITE, 
  GET_KEYWORD_LIST, 
  GET_FILTER_OPTIONS, 
  GET_SEARCH_RESULTS, 
  SHARE_REPORT_VIA_MAIL, 
  SET_REPORT_FEEDBACK, 
  SET_REPORT_RATING, 
  UPLOAD_FILENAME, 
  GET_ALL_PERSONA, 
  GET_ALL_REPORT_METADATA, 
  GET_ALL_GROUPS, 
  GET_PERSONAGROUP_MAPPINGS, 
  SET_REPORT_DETAILS, 
  GET_ALL_REPORT_METRICS, 
  GET_USER_DETAIL, 
  GET_ALL_REPORT_METRICS_DETAIL, 
  GET_REPORT_METADATA, 
  GET_ALL_REPORT_METADATA_WITH_SEARCH, 
  UPDATE_REPORT_METADATA, 
  GET_GROUP_REORDER, 
  UPDATE_GROUP_REORDER, 
  UPLOAD_REPORT_THUMBNAIL_IMAGE, 
  SET_IS_HIDE_VALUE,
  GET_ALL_DOWNLOAD_CONTENT,
  GET_ALL_REPORT_METRICS_WITH_SEARCH,
} from "../constants"


export const getUserType = (getUsertypePayload) => {
  return { type: GET_USER_TYPE, payload: getUsertypePayload }
}

export const getUserEmailId = () => {
  return { type: GET_USER_DETAIL }
}

export const getUserGroupIds = () => {
  return { type: GET_USER_GROUPIDS }
}
export const getUserAavatar = () => {
  return { type: GET_USER_AVATAR }
}

export const setUserAvatar = (avatar) => {
  return { type: SET_USER_AVATAR, payload: avatar }
}

export const getAllReports = (getReportPayload) => {
  return { type: GET_ALL_REPORTS, payload: getReportPayload }
}

export const getReportDetails = (id) => {
  return { type: GET_REPORT_DETAILS, payload: id }
}

export const markReportLikeFavourite = (payload) => {
  return { type: MARK_REPORT_LIKE_FAVOURITE, payload: payload }
};

export const getFilterOptions = () => {
  return { type: GET_FILTER_OPTIONS }
}

//search actions
export const getKeywordList = () => {
  return { type: GET_KEYWORD_LIST }
}

export const getSearchResults = (payload) => {
  return { type: GET_SEARCH_RESULTS, payload: payload }
}

//report actions
export const shareReportviaMail = (shareReportPayload) => {
  return { type: SHARE_REPORT_VIA_MAIL, payload: shareReportPayload }
}

export const setReportDetails = () => {
  return { type: SET_REPORT_DETAILS }
}

export const setReportFeedback = (data) => {
  return { type: SET_REPORT_FEEDBACK, payload: data }
}

export const setReportRating = (data) => {
  return { type: SET_REPORT_RATING, payload: data }
}

//upload filename in .net 
export const uploadFilename = (filename) => {
  return { type: UPLOAD_FILENAME, payload: filename }
};

export const uploadReportThumbnailImagename = (payload) => {
  return { type: UPLOAD_REPORT_THUMBNAIL_IMAGE, payload: payload }
};

//user management
export const getallPersona = () => {
  return { type: GET_ALL_PERSONA }
};
export const getallReportMetadata = (urlparams) => {
  return { type: GET_ALL_REPORT_METADATA, payload: urlparams }
};
export const getallReportMetadataWithSearch = (payload) => {
  return { type: GET_ALL_REPORT_METADATA_WITH_SEARCH, payload: payload }
};

export const getallReportMetrics = (data) => {
  return { type: GET_ALL_REPORT_METRICS, payload: data }
};
export const getallReportMetricsSearch = (data) => {
  return { type: GET_ALL_REPORT_METRICS_WITH_SEARCH, payload: data }
};
export const getallReportMetricsDetail = (id) => {
  return { type: GET_ALL_REPORT_METRICS_DETAIL, payload: id }
};
export const getallGroups = () => {
  return { type: GET_ALL_GROUPS }
};
export const getPersonaGroupMappings = (urlparams) => {
  return { type: GET_PERSONAGROUP_MAPPINGS, payload: urlparams }
};

export const getReportMetadata = (data) => {
  return { type: GET_REPORT_METADATA, payload: data }
}
export const updateReportMetadata = (data) => {
  return { type: UPDATE_REPORT_METADATA, payload: data }
}

export const getGroupsOrder = () => {
  return { type: GET_GROUP_REORDER }
};
export const updateGroupReorder = (data) => {
  return { type: UPDATE_GROUP_REORDER, payload: data }
}

export const showOrHideContentMetadata = (data) => {
  return { type: SET_IS_HIDE_VALUE, payload: data }
};

export const exportAndDownloadContent = () => {
  return { type: GET_ALL_DOWNLOAD_CONTENT }
}