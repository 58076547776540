import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from "moment";
import './reportMetricsDetail.css';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import AdminToolHeader from '../../components/adminToolHeader';
import { Rating } from '@mui/material';
import ReportMetricsDetailTable from './reportMetricsDetailTable';
import { getallReportMetrics } from '../../redux/actions';
import { IS_METRICS_LOADING } from '../../redux/constants';


const ReportMetricsDetail = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const [reportMetric, setReportMetric] = useState(null);

    const { reportMetrics, fromPageValue } = useSelector(state => state.userGroupManagement);
    const { reportMetrix, totalRecords } = reportMetrics;

    let pagination = {};
    pagination['fromPage'] = fromPageValue;
    pagination['takePage'] = 15;

    useEffect(() => {
        if (totalRecords === 0) {
            dispatch(getallReportMetrics(pagination));
            dispatch({ type: IS_METRICS_LOADING, payload: true })
        }
    }, [reportMetrics]);

    useEffect(() => {
        if (reportMetrics) {
            let report_metric = reportMetrix?.find(item => item.contentId === params.id);
            setReportMetric(report_metric);
        }
        else {
            dispatch(getallReportMetrics(pagination));
            dispatch({ type: IS_METRICS_LOADING, payload: true })
        }
    }, [reportMetrics]);

    return (
        <div>
            <AdminToolHeader pageName={reportMetric?.contentName || "Content Name"}
                description={`Published ${reportMetric?.contentPublishedDate ? Moment(reportMetric.contentPublishedDate).format('Do MMMM YYYY') : ""}`}
                showBackBtn={true}
                reportDetailComponent={
                    <div style={{ marginTop: "1rem", display: "flex" }}>
                        <ThumbUpOffAltIcon sx={{ color: "#444444", alignSelf: "center" }} fontSize="inherit" />
                        <span style={{ fontSize: '11px', marginLeft: "7px", color: "#444444", alignSelf: "center" }}>{reportMetric?.likes} Likes</span>
                        <Rating size='small' precision={0.5} style={{ marginLeft: "30px", alignSelf: "center" }} value={reportMetric?.avgRating || 0} readOnly />
                        <span style={{ fontSize: '11px', marginLeft: "7px", color: "#444444", alignSelf: "center" }}>{reportMetric?.ratedByUsersCount} Ratings</span>
                        <img style={{ marginLeft: "30px", alignSelf: "center" }} src={"/icons/icn_feedback.svg"} />
                        <span style={{ fontSize: '11px', marginLeft: "7px", color: "#444444", alignSelf: "center" }}>{reportMetric?.feedbackByUsersCount} Feedback</span>

                    </div>
                }
            />
            <ReportMetricsDetailTable reportMetric={reportMetric} />
        </div>
    )
}

export default ReportMetricsDetail;