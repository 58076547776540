// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer{
    background-color: #D9CDC1;
    transition: width 0.4s;
    /* max-height: 98vh; */
}

.drawer.open{
    width: 14.2rem;
}

.drawer.closed{
    width: 3.5rem;
}

.appname{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #5D4F47;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 25px;
    word-break: break-word;
    min-width: 200px;
    /* margin: 5px 0; */
}

.list-item{
    font-size: 0.85rem;
    display: flex;
    align-items: center;
}

.active-item{
    width: 4px;
    background-color: #F07300;
    z-index: 10;
    position: absolute;
    left: 0;
    height: -webkit-fill-available;
}`, "",{"version":3,"sources":["webpack://./src/views/app-details/ListStyle.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,8BAA8B;AAClC","sourcesContent":[".drawer{\n    background-color: #D9CDC1;\n    transition: width 0.4s;\n    /* max-height: 98vh; */\n}\n\n.drawer.open{\n    width: 14.2rem;\n}\n\n.drawer.closed{\n    width: 3.5rem;\n}\n\n.appname{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    color: #5D4F47;\n    font-size: 1rem;\n    font-weight: bold;\n    padding-left: 25px;\n    word-break: break-word;\n    min-width: 200px;\n    /* margin: 5px 0; */\n}\n\n.list-item{\n    font-size: 0.85rem;\n    display: flex;\n    align-items: center;\n}\n\n.active-item{\n    width: 4px;\n    background-color: #F07300;\n    z-index: 10;\n    position: absolute;\n    left: 0;\n    height: -webkit-fill-available;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
