import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, Button, IconButton, TextField, Typography } from '@mui/material'
import './adminToolHeader.css'
import { ButtonBack } from '../button';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack } from '@mui/system';
import { SearchBox } from '../search-box';
import DownloadIcon from '@mui/icons-material/SaveAlt';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SET_USER_MGMT_FILTER_VALUE, SET_META_DRAWER, SET_USER_MGMT_SEARCH_VALUE, SET_MAIN_DRAWER } from '../../redux/constants';
import MetaDataDrawer from '../meta-drawer';
import MetaDataEditDrawer from '../meta-drawer/MetaDataEditDrawer';
import { exportAndDownloadContent } from '../../redux/actions';
import AxiosInstance from '../../services/api/AxiosInstance';
import { SET_ERROR_MODAL } from "../../redux/constants";
import { DownloadLoader } from '../loader';
import { API_BASE_URL } from '../../AppConstant'

const AdminToolHeader = (props) => {
	const dispatch = useDispatch();

	const { pageName, description, showBackBtn, dropdownOptions = [], searchLabel } = props;
	const { showDropdown, showSearch, showImport, showDownload, reportDetailComponent = "" } = props;

	const [dropdownValue, setDropdownValue] = useState(null);
	const [exportExcel, setExportExcel] = useState(null);
	const [isDownload, setIsDownload] = useState(null);

	useEffect(() => {
		dispatch({ type: SET_USER_MGMT_FILTER_VALUE, payload: "" });
		dispatch({ type: SET_USER_MGMT_SEARCH_VALUE, payload: "" });
	}, [])
	useEffect(() => {
		if (!dropdownValue) {
			setDropdownValue(dropdownOptions[0]);
		}
	}, [dropdownOptions])

	const open = useSelector(state => state.app.isMainDrawerOpen)
	const handleDrawer = () => {
		dispatch({ type: SET_MAIN_DRAWER, payload: !open })
	};

	let currentDate = new Date().toJSON().slice(0, 10);

	useEffect(() => {
		if (exportExcel) {
			exportExcel === "success" &&
				dispatch({
					type: SET_ERROR_MODAL,
					payload: {
						status: true,
						errorMessage: "Metadata downloaded successfully",
						modalTitle: "Success",
					},
				});
			exportExcel === "failed" &&
				dispatch({
					type: SET_ERROR_MODAL,
					payload: {
						status: true,
						errorMessage: "Metadata downloaded failed",
						modalTitle: "Failed",
					},
				});
			setExportExcel(null) //reset status
		}
		// eslint-disable-next-line
	}, [exportExcel]);

	const exportToExcel = async () => {
		setIsDownload(true);
		try {
			AxiosInstance.get(`${API_BASE_URL}/metadataservice/api/Report/GetDump`)
				.then((res) => { prepareExcel(res.data) })
				.catch((error =>{
					if(error){
						setExportExcel('failed');
						setIsDownload(false);
					}
				}))

		} catch (error) {
			console.log('Error in export excel', error);
			setExportExcel('failed')
			setIsDownload(false);
		}

	}
	const prepareExcel = (downloadContent) => {
		if (downloadContent) {
			setExportExcel('success');
		}
		const allContentDetails = downloadContent?.allContentDetails;
		const allPersonaADGroupMappnings = downloadContent?.allPersonaADGroupMappnings;

		const columnsToExcludeSheet1 = ['Id', 'Image Source'];
		const columnsToExcludeSheet2 = ['Id'];

		const capitalizeHeader = (data) => {
			return data?.map(obj => {
				const newObj = {};
				for (const key in obj) {
					if (Object.prototype.hasOwnProperty.call(obj, key)) {
						let formatedKey;
						if (key === 'workSpaceID' || key === 'contentID' || key == 'aD_Group' || key == 'aD_GroupID') {
							formatedKey = key.replace(/([A-Z])([A-Z])/g, ' $1$2');
							formatedKey = formatedKey.charAt(0).toUpperCase() + formatedKey.slice(1);
						} else if(key === 'keyContent'){
							formatedKey = 'Key Content (Y/N)'
						} else if(key === 'isHide'){
							formatedKey = 'Is Hide (0/1)'
						} else if(key === 'isAdmin'){
							formatedKey = 'Is Admin (0/1)'
						} else {
							formatedKey = key.replace(/([A-Z])/g, ' $1');
							formatedKey = formatedKey.charAt(0).toUpperCase() + formatedKey.slice(1);
						}
						newObj[formatedKey] = obj[key];
					}
				}
				return newObj;
			})
		}

		const capitalizedDataSheet1 = capitalizeHeader(allContentDetails);
		const capitalizedDataSheet2 = capitalizeHeader(allPersonaADGroupMappnings);

		const filteredDataSheet1 = capitalizedDataSheet1?.map((content) => {
			const filteredContent = { ...content };
			columnsToExcludeSheet1.forEach(column => delete filteredContent[column]);
			return filteredContent
		})

		const filteredDataSheet2 = capitalizedDataSheet2?.map((content) => {
			const filteredContent = { ...content };
			columnsToExcludeSheet2.forEach(column => delete filteredContent[column]);
			return filteredContent
		})

		const wb = XLSX.utils.book_new();

		if (allContentDetails && allContentDetails?.length > 0) {
			const sheet1 = XLSX.utils.json_to_sheet(filteredDataSheet1);
			XLSX.utils.book_append_sheet(wb, sheet1, 'Report_App');
		}

		if (allPersonaADGroupMappnings && allPersonaADGroupMappnings?.length > 0) {
			const sheet2 = XLSX.utils.json_to_sheet(filteredDataSheet2);
			XLSX.utils.book_append_sheet(wb, sheet2, 'Persona_User_Mapping');
		}

		if (allContentDetails?.length > 0 && allPersonaADGroupMappnings?.length > 0) {
			XLSX.writeFile(wb, `Metadata_${currentDate}.xlsx`);
			setIsDownload(false);
		}
	}

	return (
		<>
			<header className='header'>
				<Box className='box-header-top'>
					{showBackBtn && <Box className='admin-back-button' sx={{ width: '80px' }}>
						<ButtonBack />
					</Box>}
					<Box className='column-flex'>
						<Typography variant='paraLight' className='header-title'>{pageName}</Typography>
						{description && <Typography variant='paraLight' className='title-description'>{description}</Typography>}

						{reportDetailComponent}
					</Box>
				</Box>
				<Box className='row-flex' sx={{ marginTop: "1rem" }}>
					<Box className='box-header-bottom'>
						{showDropdown && <Stack direction='row' spacing={1}>
							<Typography variant='paraLight' sx={{ fontWeight: "bold", color: "#444444", alignSelf: "center", marginRight: "1rem" }}>Showing for:</Typography>
							<Autocomplete
								disableClearable
								popupIcon={<ExpandMoreIcon />}
								size='small'
								className='auto-complete'
								id="combo-box-demo"
								value={dropdownValue}
								onChange={(_event, value) => {
									dispatch({ type: SET_USER_MGMT_FILTER_VALUE, payload: value });
									setDropdownValue(value);
									if (props.handleChange) { props.handleChange(value) }
								}}
								options={dropdownOptions}
								sx={{ width: 300 }}
								renderInput={(params) => <TextField {...params} sx={{ fontSize: "0.8rem !important" }} />}
							/>
						</Stack>}
						{showSearch && <Stack className='srchStack' direction='row' spacing={1}>
							<Typography variant='paraLight' sx={{ fontWeight: "bold", color: "#444444", alignSelf: "center", marginRight: "1rem" }}>Search:</Typography>
							<SearchBox placeholder={searchLabel || "Search..."} onChange={(event) => dispatch({ type: SET_USER_MGMT_SEARCH_VALUE, payload: event.target.value })}
								onClick={() => { if (props.handleChange) { props.handleChange() } }}
							/>
						</Stack>}
						<Box className='responsiveMenu'>
							<IconButton onClick={handleDrawer} aria-label="menu" size="small">
								{window.innerWidth <= 1024 && <MenuIcon fontSize="inherit" />}
							</IconButton>
						</Box>
						<div className='responsiveBack'>
							<ButtonBack />
						</div>
					</Box>
					<Box>
						{showDownload &&
							<Button
								onClick={exportToExcel}
								className='importBtn'
								startIcon={<DownloadIcon />}
							>
								{isDownload ? (
									<DownloadLoader
										style={{ height: "24px !important", padding: "0 10px", background: 'rgb(192 170 156 / 4%)' }}
										loadingText="Downloding..."
									/>
								) : (
									"Download Metadata"
								)}

							</Button >
						}
						{showImport && <Button onClick={() => dispatch({ type: SET_META_DRAWER, payload: true })} className='importBtn' startIcon={<FileUploadOutlinedIcon />} >Upload Content</Button >}
					</Box>
				</Box>
			</header>
			{<MetaDataDrawer />}
			{<MetaDataEditDrawer />}
		</>
	)
}

export default AdminToolHeader;