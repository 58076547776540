import React, { useEffect, useState } from 'react';
import { Drawer, Box, Typography, IconButton, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { SET_CHECKED_FILTER, SET_FILTER_DRAWER } from '../../redux/constants'
import CloseIcon from '@mui/icons-material/Close';
import './Filter.css'
import { Stack } from '@mui/system';

export const FilterDrawer = ({ handleFilters }) => {

  const isDrawerOpen = useSelector(state => state.app.isFilterDrawerOpen)
  const filterList = useSelector(state => state.report.filterList)

  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch({ type: SET_FILTER_DRAWER, payload: false })
  }

  const [checked, setChecked] = useState([])
  const checkedFilter = useSelector(state => state.report.checkedFilter)

  useEffect(() => {
    setChecked(checkedFilter)
  }, [isDrawerOpen, checkedFilter])


  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const handleApplyFilter = () => {
    dispatch({ type: SET_CHECKED_FILTER, payload: checked })
    handleFilters(checked)
  }



  return (
    <>
      <Drawer anchor='right' open={isDrawerOpen} onClose={closeHandler}>
        <Box className='filterDrawer' role='presentation'  >
          <Box className='filterHeader'>
            <Typography variant='headingTwo' sx={{ fontWeight: 'normal' }}>Filters By Themes</Typography>
            <IconButton onClick={() => dispatch({ type: SET_FILTER_DRAWER, payload: false })}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
          <Box>
            <FormGroup>
              {filterList.map((item, index) => {
                return (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel className='check-box' control={<Checkbox onChange={() => handleToggle(item)} checked={checked.indexOf(item) === -1 ? false : true} />} label={item} />
                  </Box>
                )
              })}
            </FormGroup>
          </Box>
          <Stack direction='row' spacing={2} className='filterFooter'>
            <Button onClick={() => dispatch({ type: SET_FILTER_DRAWER, payload: false })} size='small' color='secondary' variant='contained'>CANCEL</Button>
            <Button onClick={handleApplyFilter} size='small' color='primary' variant='contained'>APPLY FILTER</Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}