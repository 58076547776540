import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import { menuProperty, menuItemStyle } from './drawer.style';
import { Link } from 'react-router-dom';
import { PAGE_NAME } from '../../constants/constants';
import { MENU_NAME } from '../../constants/constants';

export const DashboardSubMenu = ({ dashboardAnchor, handleClose }) => {
    return (<Menu
        open={Boolean(dashboardAnchor)}
        anchorEl={dashboardAnchor}
        onClose={handleClose}
        anchorOrigin={menuProperty.anchorOrigin}
        transformOrigin={menuProperty.transformOrigin}
        sx={menuProperty.sx}
    >
        <Link to={`/dashboard`}>
            <MenuItem sx={menuItemStyle} onClick={handleClose}>PowerBI Library</MenuItem>
        </Link>
        <Link to={'/groups/favourites'}>
            <MenuItem sx={menuItemStyle} onClick={handleClose}>Favourites</MenuItem>
        </Link>
    </Menu >)
};

export const VisualSubMenu = ({ visualAnchor, handleClose }) => {
    return (<Menu
        open={Boolean(visualAnchor)}
        anchorEl={visualAnchor}
        onClose={handleClose}
        anchorOrigin={menuProperty.anchorOrigin}
        transformOrigin={menuProperty.transformOrigin}
        sx={menuProperty.sx}
    >
        <Link to={MENU_NAME.visualDiscovery.externalLink} target="_blank">
            <MenuItem sx={menuItemStyle} onClick={handleClose}>ViDi</MenuItem>
        </Link>
    </Menu >)
};
export const DataCubesSubMenu = ({ dataCubesAnchor, handleClose }) => {
    return (<Menu
        open={Boolean(dataCubesAnchor)}
        anchorEl={dataCubesAnchor}
        onClose={handleClose}
        anchorOrigin={menuProperty.anchorOrigin}
        transformOrigin={menuProperty.transformOrigin}
        sx={menuProperty.sx}
    >
        <Link to={MENU_NAME.dataCubes.externalLink} target="_blank">
            <MenuItem sx={menuItemStyle} onClick={handleClose}>Data Cubes</MenuItem>
        </Link>
    </Menu >)
};

export const ConsumerResearchSubMenu = ({ consumerResearchAnchor, handleClose }) => {
    return (<Menu
        open={Boolean(consumerResearchAnchor)}
        anchorEl={consumerResearchAnchor}
        onClose={handleClose}
        anchorOrigin={menuProperty.anchorOrigin}
        transformOrigin={menuProperty.transformOrigin}
        sx={menuProperty.sx}
    >
    <Link to= {MENU_NAME.consumerResearch.externalLink} target="_blank">  
        <MenuItem sx={menuItemStyle} onClick={handleClose}>Consumer Research</MenuItem>
    </Link>
    </Menu >)
};

export const DmsSubMenu = ({ dmsAnchor, handleClose }) => {
    return (<Menu
        open={Boolean(dmsAnchor)}
        anchorEl={dmsAnchor}
        onClose={handleClose}
        anchorOrigin={menuProperty.anchorOrigin}
        transformOrigin={menuProperty.transformOrigin}
        sx={menuProperty.sx}
    >
        <Link to={MENU_NAME.documentManagementSystem.externalLink} target="_blank">
            <MenuItem sx={menuItemStyle} onClick={handleClose}>DMS</MenuItem>
        </Link>
    </Menu >)
};

export const AdminSubMenu = ({ adminAnchor, handleClose }) => {
    return (<Menu
        open={Boolean(adminAnchor)}
        anchorEl={adminAnchor}
        onClose={handleClose}
        anchorOrigin={menuProperty.anchorOrigin}
        transformOrigin={menuProperty.transformOrigin}
        sx={menuProperty.sx}
    >

        <Link to={`/metadata`}>
            <MenuItem sx={menuItemStyle} onClick={handleClose}>Metadata</MenuItem>
        </Link>
        <Link to={`/persona-group-mapping`}>
            <MenuItem sx={menuItemStyle} onClick={handleClose}>Persona Group Mapping</MenuItem>
        </Link>
        <Link to={`/metrics`}>
            <MenuItem sx={menuItemStyle} onClick={handleClose}>Metrics</MenuItem>
        </Link>
        <Link to={`/manage-group-order`}>
            <MenuItem sx={menuItemStyle} onClick={handleClose}>Manage Group Order</MenuItem>
        </Link>
    </Menu >)
};