import React, { useEffect, createRef } from "react";
import "../report-details/reportDetails.css";
import { service, factories, models } from "powerbi-client";

const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

let reportContainer = "";
let reportRef = "";
let error = [];


export const EmbedAppDetail = ({ accessToken, embedUrl, config }) => {
    reportRef = createRef();

    useEffect(() => {

        if (reportRef !== null) {
            reportContainer = reportRef["current"];
        }

        // User input - null check
        if (config.workspaceId === "" || config.reportId === "") {
            error = ["Please assign values to workspace Id and report Id in Config.ts file"];
        }
        processEmbed();

        return () => powerbi.reset(reportContainer);
    }, []);

    function processEmbed() {
        if (error.length) {
            // Cleaning the report container contents and rendering the error message in multiple lines
            reportContainer.textContent = "";
            error.forEach(line => {
                reportContainer.appendChild(document.createTextNode(line));
                reportContainer.appendChild(document.createElement("br"));
            });
        }
        else if (accessToken !== "" && embedUrl !== "") {
            const embedConfiguration = {
                type: config.type || "report",
                tokenType: models.TokenType.Aad,
                accessToken,
                embedUrl,
                id: config.reportId,
                settings: {
                    bars: {
                        actionBar: {
                            visible: true
                        }
                    },
                    persistentFiltersEnabled: true,
                    personalBookmarksEnabled: true
                }
                /*
                // Enable this setting to remove gray shoulders from embedded report
                settings: {
                    background: models.BackgroundType.Transparent
                }
                */
            };

            const report = powerbi.embed(reportContainer, embedConfiguration);

            // Clear any other loaded handler events
            report.off("loaded");

            // Triggers when a content schema is successfully loaded
            report.on("loaded", function () {
                console.log("Report load successful");
            });

            // Clear any other rendered handler events
            report.off("rendered");

            // Triggers when a content is successfully embedded in UI
            report.on("rendered", function () {
                console.log("Report render successful");
            });

            // Clear any other error handler event
            report.off("error");

            // Below patch of code is for handling errors that occur during embedding
            report.on("error", function (event) {
                const errorMsg = event.detail;

                // Use errorMsg variable to log error in any destination of choice
                console.error(errorMsg);
            });
        }

    }

    return (
        <div
            className="app-container"
            id="reportContainer"
            ref={reportRef} >
            Loading the report...
        </div>
    )
}