// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-page .blank button {
  visibility: hidden;
}

.dashGroupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 10px;
}

.dashGroupHeader h3 {
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
}

.dashGroupHeader .viewAllBtn {
  background-color: #efefef;
  color: #5D4F46 !important;
  font-weight: 600;
  font-size: 0.65rem;
  padding: 2px 12px 2px;
}

.dashGroupHeader .viewAllBtn.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.no-report {
  color: #000;
  text-align: center;
  margin-top: 6rem;
}


@media (max-width: 1024px) {
  .dashboard-page .blank button {
    visibility: visible;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;;AAGA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".dashboard-page .blank button {\n  visibility: hidden;\n}\n\n.dashGroupHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 1rem;\n  margin-bottom: 10px;\n}\n\n.dashGroupHeader h3 {\n  margin: 0;\n  font-weight: 600;\n  text-transform: capitalize;\n}\n\n.dashGroupHeader .viewAllBtn {\n  background-color: #efefef;\n  color: #5D4F46 !important;\n  font-weight: 600;\n  font-size: 0.65rem;\n  padding: 2px 12px 2px;\n}\n\n.dashGroupHeader .viewAllBtn.Mui-disabled {\n  color: rgba(0, 0, 0, 0.26) !important;\n}\n\n.no-report {\n  color: #000;\n  text-align: center;\n  margin-top: 6rem;\n}\n\n\n@media (max-width: 1024px) {\n  .dashboard-page .blank button {\n    visibility: visible;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
