import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import "./reportMetricsDetail.css";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { IconButton, Rating } from '@mui/material';
import { getallReportMetricsDetail } from '../../redux/actions';
import { NO_DETAIL_FOUND } from '../../constants/constants';
import { IS_METRICS_LOADING } from '../../redux/constants';
import {ResponseLoader} from '../../components/loader';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f7f7f7',
        maxWidth: '250px',
        color: '#444444',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        fontFamily: "Roboto Slab"
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&::before": {
            backgroundColor: '#f7f7f7',
            boxShadow: theme.shadows[1],
        }
    },
}));

export default function ReportMetricsDetailTable({ reportMetric }) {
    const dispatch = useDispatch();
    const params = useParams();

    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(15);
    const [selectedSort, setSelectedSort] = useState({ column: "", value: "" });
    const [fromPage, setFromPage] = useState(0);
    const [takePage, setTakePage] = useState(15);
    const [urlPramsData, setUrlParamsData] = useState();

    let pagination = {};
    pagination['fromPage'] = fromPage;
    pagination['takePage'] = takePage;


    const { reportMetricsDetail, isMetricsLoading } = useSelector(state => state.userGroupManagement);
    const { specifiMetricResponse, totalRecord } = reportMetricsDetail;

    const paginationPayload = `&fromPage=${pagination.fromPage}&takePage=${pagination.takePage}`;
    const contentId = params?.id
    useEffect(() => {

        if (urlPramsData) {
            dispatch(getallReportMetricsDetail({ contentId, paginationPayload, urlPramsData }));
            dispatch({ type: IS_METRICS_LOADING, payload: true })
        } else {
            dispatch(getallReportMetricsDetail({ contentId, paginationPayload }));
            dispatch({ type: IS_METRICS_LOADING, payload: true })
        }
    }, [fromPage, urlPramsData]);

    useEffect(() => {
        setPage(0);
    }, [reportMetricsDetail]);

    const handleSort = (columnName, value) => {
        let urlParams = "";
        if (columnName) {
            urlParams = `&sortColumn=${columnName}&sortOrder=${value || "asc"}`;
            setUrlParamsData(urlParams)
        }
    }

    const headCells = [
        {
            label: 'User ID',
            column: "userid",
            sort: true,
        },
        {
            label: 'Email',
            column: "emailid",
            sort: true,
        },
        {
            label: `Liked (${reportMetric?.likes || 0})`,
            column: "isliked",
            align: "center",
            sort: true,
            minWidth: "5rem"
        },
        {
            label: `Marked Favourite (${reportMetric?.favourites || 0})`,
            column: "isfavourite",
            align: "center",
            sort: true,
            minWidth: "9rem"
        },
        {
            label: `Rating (${reportMetric?.ratedByUsersCount || 0})`,
            column: "rating",
            sort: true,
        },
        {
            label: `Feedback (${reportMetric?.feedbackByUsersCount || 0})`,
            column: "feedback",
            sort: true,
        }
    ];


    function EnhancedTableHead() {
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell, index) => (
                        <TableCell
                            key={index}
                            padding={'normal'}
                            style={headCell.minWidth ? { minWidth: headCell.minWidth } : {}}
                        >
                            <Box sx={{ display: "flex", justifyContent: headCell.align || 'left' }}>
                                {headCell.label}
                                {headCell.sort && <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "0.5rem", justifyContent: "center" }}>
                                    <IconButton size='small' className={selectedSort.column === headCell.column && selectedSort.value === "asc" ? 'sort-btn-disable' : 'sort-btn'}
                                        onClick={() => {
                                            setSelectedSort({ column: headCell.column, value: "asc" });
                                            if (selectedSort.column !== headCell.column || selectedSort.value !== "asc") { handleSort(headCell?.column, "asc"); }
                                        }}>
                                        <img className='sort-icon' src='../icons/icn_uparrow.svg' /> </IconButton>
                                    <IconButton size='small' className={selectedSort.column === headCell.column && selectedSort.value === "desc" ? 'sort-btn-disable' : 'sort-btn'}
                                        onClick={() => {
                                            setSelectedSort({ column: headCell.column, value: "desc" });
                                            if (selectedSort.column !== headCell.column || selectedSort.value !== "desc") { handleSort(headCell?.column, "desc"); }
                                        }}>
                                        <img className='sort-icon' src='../icons/icn_downarrow.svg' /></IconButton>
                                </Box>}
                            </Box>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalRecord);

    const handlePrevPage = () => {
        setFromPage(Math.max(0, fromPage - takePage));
    };

    const handleNextPage = () => {
        setFromPage(fromPage + takePage);
    };

    return (
        <Box className='table-box'>
            {isMetricsLoading ? (
                <ResponseLoader
                    style={{ display: 'flex', justifyContent: 'center', marginTop: '13%' }}
                    loadingText="  Loading..."
                />
            ) :
                <Paper sx={{ width: '100%', mb: 2, boxShadow: "none" }}>
                    <TableContainer>
                        <Table
                            sx={{ width: "95%", marginLeft: "2rem", marginRight: "2rem" }}
                            aria-labelledby="tableTitle"
                            size={'small'}
                        >
                            <EnhancedTableHead />
                            {totalRecord > 0 && <TableBody>
                                {
                                    specifiMetricResponse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow key={index} >
                                                    <TableCell align="left">{row.userID}</TableCell>
                                                    <TableCell align="left">{row.emailid}</TableCell>
                                                    <TableCell align="center" style={{ minWidth: "5rem" }}>
                                                        {row.isLiked && <ThumbUpOffAltIcon sx={{ color: "gray" }} fontSize="small" />}</TableCell>
                                                    <TableCell align="center" style={{ minWidth: "9rem" }}>{row.isFavourite && <FavoriteBorderIcon sx={{ color: "gray" }} fontSize="small" />}</TableCell>
                                                    <TableCell align="left"><Rating size='small' precision={0.5} value={row.rating || 0} readOnly /></TableCell>

                                                    <LightTooltip className="card-tooltip" placement="top" title={row.feedback} arrow>
                                                        <TableCell align="left">{row.feedback.substring(0, 35)}{row.feedback.length > 35 && `...`}</TableCell>
                                                    </LightTooltip>
                                                </TableRow>
                                            );
                                        })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 20 * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>}
                        </Table>
                    </TableContainer>

                    {totalRecord === 0 && <Box className='no-report-found'>{NO_DETAIL_FOUND}</Box>}
                    {totalRecord > 0 && <Box className='meatadata-pagination'>
                        <IconButton
                            disabled={fromPage === 0}
                            onClick={handlePrevPage}
                        >
                            <img src='../icons/icn_left_arrow.svg' />
                        </IconButton>
                        <span>Page </span>
                        <div className='meatadata-pagination-box'>{page + 1}</div>
                        <span> of {Math.ceil(totalRecord / takePage)}</span>
                        <IconButton
                            disabled={fromPage + takePage >= totalRecord}
                            onClick={handleNextPage}
                        >
                            <img src='../icons/icn_right_arrow.svg' />
                        </IconButton>
                    </Box>}
                </Paper>
            }
        </Box>
    );
}
