import { AuthenticationContext } from 'react-adal';
import { AUTH_CONFIG, API_BASE_URL } from "./AppConstant";

export const adalConfig = {
    tenant: AUTH_CONFIG.tenantId,
    clientId: AUTH_CONFIG.clientId,
    redirectUri: window.location.href,
    endpoints: {
        api: API_BASE_URL
    },
    cacheLocation: 'sessionStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
    return authContext.getCachedToken(adalConfig.clientId);
}
