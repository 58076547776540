import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Authentication from "../auth/authentication";
import Dashboard from "../views/dashboard";
import ReportDetails from "../views/report-details";
import SearchReports from "../views/report-list/searchResults";
import FavouriteReports from "../views/report-list/FavReports";
import AllReports from "../views/report-list/allReports";
import AllApps from '../views/report-list/allApps';
import { Box } from "@mui/material";
import ReportMetadata from "../views/report-metadata";
import PersonaGroupMapping from "../views/persona-group-mapping";
import ReportMetrics from "../views/report-metrics";
import ManageGroupOrder from "../views/manage-group-order";
import ReportMetricsDetail from "../views/report-metrics-detail";
import NotFound from "../views/error-page/NotFound";
import AppDetails from '../views/app-details';
import AllSearchReports from '../views/report-list/allSearchReports';
import AllSearchApps from '../views/report-list/allSearchApps';

function App() {
  const { isAdmin } = useSelector((state) => state.user);

  return (
    <Box sx={{ minHeight: "calc(100vh - 35px)" }}>
      <Routes>
        <Route index element={<Authentication />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/search-results" element={<SearchReports />} />
        <Route path="/groups/:groupName" element={<FavouriteReports />} />
        <Route path="/reports/:groupName" element={<AllReports />} />
        <Route path="/apps/:groupName" element={<AllApps />} />
        <Route path="/report-details/:id" element={<ReportDetails />} />
        <Route path="/app-details/:id" element={<AppDetails />} />
        <Route path="/reports/search-results" element={<AllSearchReports />} />
        <Route path="/apps/search-results" element={<AllSearchApps />} />
        <Route
          path="/metadata"
          element={checkAdminAuth(<ReportMetadata />)}
        />
        <Route
          path="/persona-group-mapping"
          element={checkAdminAuth(<PersonaGroupMapping />)}
        />
        <Route
          path="/metrics"
          element={checkAdminAuth(<ReportMetrics />)}
        />
        <Route
          path="/report-metrics-detail/:id"
          element={checkAdminAuth(<ReportMetricsDetail />)}
        />
        <Route
          path="/manage-group-order"
          element={checkAdminAuth(<ManageGroupOrder />)}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Box>
  );

  function checkAdminAuth(page) {
    if (isAdmin === null) {
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    return isAdmin ? (
      page
    ) : (
      <div className="not-authorised">
        You are not authorised to access this page
      </div>
    );
  }

}
export default App;
