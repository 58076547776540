import {
  SET_ALL_REPORTS,
  SET_REPORT_DETAILS,
  SET_FILTER_OPTIONS,
  SET_CHECKED_FILTER,
  SET_REPORT_FEEDBACK,
  SET_REPORT_RATING,
  SET_ERROR_MODAL,
  IS_REPORT_UPDATED,
  EMAIL_SENT_STATUS,
  UPLOAD_THUMBNAIL_STATUS,
  SET_DASH_CONTENT_DETAILS,
  SET_TAB_INDEX,
  FEEDBACK_SENT_STATUS,
  SET_CONTENT_STATUS,
  IS_DASHBOARD_RESULTS_LOADING,
} from "../constants";

const initialState = {
  allReports: [],
  favReports: [],
  reportDetails: {},
  filterList: [],
  checkedFilter: [],
  reportFeedback: "",
  reportRating: 0,
  errorModalTitle: "",
  isErrorModalVisible: false,
  errorMessage: "",
  isReportUpdated: false,
  emailSentStatus: null,
  uploadThumbnailStatus: null,
  dashboardContentDetails: new Map(),
  dashboardTabIndex: 0,
  feedbackSendtStatus: null,
  contentStatus: null,
  isDashboardResultsLoading: false,
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_REPORTS:
      return { ...state, allReports: action.payload };
    case SET_REPORT_DETAILS:
      return { ...state, reportDetails: action.payload };
    case SET_FILTER_OPTIONS:
      return { ...state, filterList: action.payload };
    case SET_CHECKED_FILTER:
      return { ...state, checkedFilter: action.payload };
    case SET_REPORT_FEEDBACK:
      return { ...state, reportFeedback: action.payload };
    case SET_REPORT_RATING:
      return { ...state, reportRating: action.payload };
    case SET_ERROR_MODAL:
      let { status, errorMessage, modalTitle } = action.payload;
      return {
        ...state,
        isErrorModalVisible: status,
        errorMessage: errorMessage,
        errorModalTitle: modalTitle,
      };
    case IS_REPORT_UPDATED:
      return { ...state, isReportUpdated: action.payload };
    case EMAIL_SENT_STATUS:
      return { ...state, emailSentStatus: action.payload };
    case UPLOAD_THUMBNAIL_STATUS:
      return { ...state, uploadThumbnailStatus: action.payload };
    case SET_DASH_CONTENT_DETAILS:
      return { ...state, dashboardContentDetails: action.payload };
    case SET_TAB_INDEX:
      return { ...state, dashboardTabIndex: action.payload };
    case FEEDBACK_SENT_STATUS:
      return { ...state, feedbackSendtStatus: action.payload };
    case SET_CONTENT_STATUS:
      return { ...state, contentStatus: action.payload };
    case IS_DASHBOARD_RESULTS_LOADING:
      return { ...state, isDashboardResultsLoading: action.payload };
    default:
      return state;
  }
}
