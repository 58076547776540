import React, {useEffect} from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/header';
import ReportList from '../../components/report-list'
import { useParams } from 'react-router-dom';
import { IS_REPORT_UPDATED, SET_DASH_CONTENT_DETAILS } from '../../redux/constants';
import { getAllReports } from '../../redux/actions';
import { prepareTabData } from './utils';
import { NO_REPORT_FOUND } from '../../constants/constants';
import { ResponseLoader } from "../../components/loader";
import './reportList.css';

const AllReports = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const groupName = params?.groupName?.replace(/-/g, ' ');

  const { dashboardContentDetails, allReports, isReportUpdated, isDashboardResultsLoading } = useSelector(state => state.report);
  const user = useSelector(state => state.user);
  const reports = dashboardContentDetails.get('Report');

  useEffect(() => {
    if (isReportUpdated) {
      setTimeout(() => {
        dispatch(getAllReports({ UserId: user.userId, groupIds: user.groupIds }));
      }, 1000);
      dispatch({ type: IS_REPORT_UPDATED, payload: false });
    }
    // eslint-disable-next-line 
  }, [isReportUpdated])

  useEffect(() => {
    const tabDataMap = prepareTabData(allReports)
    dispatch({ type: SET_DASH_CONTENT_DETAILS, payload: tabDataMap });
  }, [allReports])

  return (
    <Box component="main" className='all-reports' sx={{ flexGrow: 1, paddingBottom: '2rem' }}>
      <Header />
      {isDashboardResultsLoading ? (
        <ResponseLoader
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "13%",
          }}
          loadingText="  Loading..."
        />
        ) : (
          <>
            {reports !== undefined && reports.size > 0 ?
              Array.from(reports)?.map(([key, value]) => (
                <div>
                  {key === groupName ? <ReportList contents={value} groupName={params.groupName} /> : ''}
                </div>
              ))
              : <Typography sx={{ color: "#000", textAlign: "center", marginTop: "8rem" }}>{NO_REPORT_FOUND}</Typography>
            }
          </>
      )}  
    </Box>
  )
}

export default AllReports