import React, { useState, useEffect } from "react";
import "./modal.css";
import { v4 as uuidv4 } from "uuid";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  EMAIL_ADDRESS,
  SHARE_REPORT,
  UPLOAD_FILE_PAGE,
  SHARE_APP,
} from "../../constants/constants";
import {
  shareReportviaMail,
  setReportFeedback,
  uploadReportThumbnailImagename,
} from "../../redux/actions";
import {
  EMAIL_SENT_STATUS,
  SET_ERROR_MODAL,
  SET_LOADER_STATUS,
  UPLOAD_THUMBNAIL_STATUS,
  UPLOAD_REPORT_METADATA_EXCEL_STATUS,
  UPLOAD_REPORT_METADATA_EXCEL_ERROR,
  IS_REPORT_UPDATED,
  FEEDBACK_SENT_STATUS,
} from "../../redux/constants";
import { DownloadLoader } from "../loader";
import {
  generateFilename,
  uploadFileToStorage,
  validateFileType,
} from "../../common";

//Report Feedback Modal
export function FeedbackModal(props) {
  const dispatch = useDispatch();

  const [feedbackText, setfeedbackText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);

  const { userId, userEmailId } = useSelector((state) => state.user);
  const { feedbackSendtStatus } = useSelector((state) => state.report);

  useEffect(() => {
    if (!props.openFormDialog) {
      setfeedbackText("");
      setErrorMessage("");
      setErrorStatus(false);
    }
  }, [props.openFormDialog]);

  useEffect(() => {
    if (feedbackSendtStatus === "success") {
      dispatch({
        type: SET_ERROR_MODAL,
        payload: {
          status: true,
          errorMessage: "Feedback successfully submitted",
          modalTitle: "Success",
        },
      });
    }
    if (feedbackSendtStatus === "failed") {
      dispatch({
        type: SET_ERROR_MODAL,
        payload: {
          status: true,
          errorMessage: "Feedback submit failed",
          modalTitle: "Failed",
        },
      });
    }
    dispatch({ type: FEEDBACK_SENT_STATUS, payload: null });
  }, [feedbackSendtStatus]);

  const onChangeFeedbackText = (event) => {
    setfeedbackText(event.target.value);
  };

  const submitHandler = () => {
    if (feedbackText === "") {
      setErrorStatus(true);
      setErrorMessage("Please fill out this field");
    } else {
      dispatch(
        setReportFeedback({
          FeedbackDescription: feedbackText,
          reportid: props.reportId,
          userid: userId,
          emailid: userEmailId,
        })
      );
      setfeedbackText("");
      setErrorMessage("");
      setErrorStatus(false);
      props.handleClose();
    }
  };

  return (
    <div>
      <Dialog
        open={props.openFormDialog}
        onClose={props.handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{props.modalTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={true}
            error={errorStatus}
            id="feedback"
            variant="outlined"
            label={props.inputPlaceHolder}
            multiline
            fullWidth
            maxRows={4}
            value={feedbackText}
            onChange={onChangeFeedbackText}
            sx={{ marginTop: "5px" }}
            helperText={errorMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={props.handleClose}
            color="warning"
          >
            Cancel
          </Button>
          <Button onClick={submitHandler} variant="contained" size="small">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ShareModal(props) {
  const dispatch = useDispatch();

  const [emails, setEmails] = useState([]);
  const [value, setValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { emailSentStatus } = useSelector((state) => state.report);
  const user = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.app.isLoading);

  useEffect(() => {
    if (!props.openShareModal) {
      setEmails([]);
      setValue("");
      setErrorMessage("");
    }
  }, [props.openShareModal]);

  useEffect(() => {
    if (emailSentStatus) {
      dispatch({ type: SET_LOADER_STATUS, payload: false });
      props.handleClose();
      emailSentStatus === "success" &&
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: `${props?.contentType} shared successfully`,
            modalTitle: "Success",
          },
        });
      emailSentStatus === "failed" &&
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Report shared failed",
            modalTitle: "Failed",
          },
        });
      dispatch({ type: EMAIL_SENT_STATUS, payload: null }); //reset status
    }
  }, [emailSentStatus]);

  const handleShareReport = async () => {
    if (!isLoading) {
      // prevent this func call when loading
      if (value) {
        let validatedEmails = await validateEmailandAdd(value.trim());
        setEmails(validatedEmails);
        setValue("");
        if (validatedEmails.length) {
          shareReport([...emails, ...validatedEmails]);
        }
      } else {
        shareReport(emails);
      }
    }
  };
  function shareReport(emailIds) {
    const contentTypeDetails =
      props?.contentType === "Report" ? "report-details" : "app-details";
    if (emailIds.length === 0) {
      setErrorMessage("Please enter email address");
    } else {
      let payload = {
        Recipient_Email: emailIds,
        Sender_Email: user.userEmailId,
        Sender_FirstName: user.firstName,
        Sender_LastName: user.lastName,
        Content_Type: props?.contentType,
        Report_URL: `${window.location.origin}/${contentTypeDetails}/${props.reportId}`,
      };
      dispatch({ type: SET_LOADER_STATUS, payload: true });
      dispatch(shareReportviaMail(payload));
      setErrorMessage("");
    }
  }

  const handleKeyDown = async (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      let validatedEmails = await validateEmailandAdd(value.trim());
      setEmails(validatedEmails);
      setValue("");
    }
  };

  async function validateEmailandAdd(newValue) {
    newValue.split(",").map((item) => {
      if (item && isValid(item)) {
        emails.push(item);
        return item;
      }
    });
    return emails;
  }

  const handleChange = (evt) => {
    setValue(evt.target.value);
    setErrorMessage(null);
  };

  const handleDelete = (item) => {
    setEmails(emails.filter((i) => i !== item));
  };

  function isValid(email) {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }
    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (error) {
      setErrorMessage(error);
      return false;
    }
    return true;
  }

  function isInList(email) {
    return emails.includes(email);
  }

  function isEmail(email) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    );
  }
  const shareButtonText = (type) => {
    if (type === "Report") {
      return SHARE_REPORT;
    } else {
      return SHARE_APP;
    }
  };
  return (
    <div>
      <Dialog
        open={props.openShareModal}
        onClose={props.handleClose}
        fullWidth
        maxWidth="sm"
      >
        {props?.contentType === "Report" ? (
          <DialogTitle>{SHARE_REPORT}</DialogTitle>
        ) : (
          <DialogTitle>{SHARE_APP}</DialogTitle>
        )}
        <DialogContent>
          <>
            {emails.map((email) => (
              <div className="tag-item" key={email}>
                {email}
                <button
                  type="button"
                  className="button"
                  onClick={() => handleDelete(email)}
                >
                  &times;
                </button>
              </div>
            ))}

            <input
              className={"input " + (errorMessage && " has-error")}
              value={value}
              placeholder={EMAIL_ADDRESS}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />

            {errorMessage && <p className="error">{errorMessage}</p>}
          </>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={props.handleClose}
            color="warning"
          >
            Cancel
          </Button>
          <Button variant="contained" size="small" onClick={handleShareReport}>
            {isLoading ? (
              <DownloadLoader
                style={{ height: "24px !important" }}
                loadingText="Loading..."
              />
            ) : (
              shareButtonText(props?.contentType)
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ErrorModal() {
  const {
    isErrorModalVisible,
    errorMessage,
    errorModalTitle = "Alert!",
  } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({
      type: SET_ERROR_MODAL,
      payload: { status: false, errorMessage: "", modalTitle: "" },
    });
    dispatch({ type: UPLOAD_REPORT_METADATA_EXCEL_STATUS, payload: null }); //reset status
    dispatch({ type: UPLOAD_REPORT_METADATA_EXCEL_ERROR, payload: null }); //reset error
  };

  return (
    <div>
      <Dialog
        sx={{ textAlign: "center" }}
        open={isErrorModalVisible}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          {errorModalTitle}
        </DialogTitle>
        <DialogContent>
          <Typography variant="paraBold" sx={{ fontSize: "0.8rem" }}>
            {Array.isArray(errorMessage) ? (
              <ol style={{ textAlign: "left" }}>
                {errorMessage.map((item, index) => (
                  <li style={{ padding: "5px 0px" }} key={index}>
                    {item}
                  </li>
                ))}
              </ol>
            ) : (
              errorMessage
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={handleClose}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//Update thumbnail Modal
export function UpdateThumbnailModal(props) {
  const dispatch = useDispatch();

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState();
  const { uploadThumbnailStatus } = useSelector((state) => state.report);
  const isLoading = useSelector((state) => state.app.isLoading);

  useEffect(() => {
    if (uploadThumbnailStatus) {
      dispatch({ type: SET_LOADER_STATUS, payload: false });
      uploadThumbnailStatus === "success" &&
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Image uploaded successfully",
            modalTitle: "Success",
          },
        });
      uploadThumbnailStatus === "failed" &&
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Image upload failed",
            modalTitle: "Failed",
          },
        });
      dispatch({ type: UPLOAD_THUMBNAIL_STATUS, payload: null }); //reset status
      props.handleClose();
      dispatch({ type: IS_REPORT_UPDATED, payload: true });
    }
  }, [uploadThumbnailStatus]);

  async function handleChange(e) {
    let file = e.target.files[0];
    if (file) {
      const validFile = await validateFileType(
        UPLOAD_FILE_PAGE.thumbnail,
        file.name
      );
      if (!validFile) {
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage:
              "Invalid file, please select a file with .png / .jpeg / .jpg extension",
            modalTitle: "Alert!",
          },
        });
        setFile(null);
        setFileName("");
        setFileUrl("");
      } else {
        setFile(file);
        setFileName(generateFilename(file));
        setFileUrl(URL.createObjectURL(file));
      }
    } else {
      setFileUrl("");
    }
  }

  const uploadFile = async () => {
    try {
      if (!isLoading) {
        dispatch({ type: SET_LOADER_STATUS, payload: true });
        if (file) {
          let response = await uploadFileToStorage(file, fileName);
          if (response?._response?.status === 201) {
            dispatch(
              uploadReportThumbnailImagename({
                reportID: props.reportId,
                imageSource: fileName,
              })
            );
          } else {
            dispatch({ type: SET_LOADER_STATUS, payload: false });
            dispatch({
              type: SET_ERROR_MODAL,
              payload: {
                status: true,
                errorMessage: "Image upload failed. Please try after sometime",
                modalTitle: "Alert!",
              },
            });
          }
        } else {
          dispatch({ type: SET_LOADER_STATUS, payload: false });
          dispatch({
            type: SET_ERROR_MODAL,
            payload: {
              status: true,
              errorMessage: "Please choose image",
              modalTitle: "Alert!",
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: SET_LOADER_STATUS, payload: false });
      dispatch({
        type: SET_ERROR_MODAL,
        payload: {
          status: true,
          errorMessage: "Image upload failed. Please try after sometime",
          modalTitle: "Alert!",
        },
      });
    }
  };

  return (
    <div>
      <Dialog
        open={props.openDialog}
        onClose={props.handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Update Thumbnail</DialogTitle>
        <DialogContent>
          <div className="choose-file-container">
            <div className="select-thumbnail">
              <input
                type="file"
                id="thumbnail"
                onChange={handleChange}
                accept="image/x-png,image/jpg,image/jpeg"
              />
              <span className="choosefile-text">
                {file?.name || "No file chosen"}
              </span>
            </div>
            <img className="thumbnail-img" src={fileUrl} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={props.handleClose}
            color="warning"
          >
            Cancel
          </Button>
          <Button onClick={uploadFile} variant="contained" size="small">
            {isLoading ? (
              <DownloadLoader
                style={{ height: "24px !important" }}
                loadingText="Loading..."
              />
            ) : (
              "Update"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
