import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import AxiosInstance from '../../services/api/AxiosInstance';
import {
    GET_ALL_GROUPS,
    GET_ALL_PERSONA,
    GET_ALL_REPORT_METADATA,
    GET_ALL_REPORT_METADATA_WITH_SEARCH,
    GET_ALL_REPORT_METRICS,
    GET_ALL_REPORT_METRICS_DETAIL,
    GET_GROUP_REORDER,
    GET_PERSONAGROUP_MAPPINGS,
    GET_REPORT_METADATA,
    REPORT_METADATA_EDIT_STATUS,
    SET_ALL_GROUPS,
    SET_ALL_PERSONA,
    SET_ALL_REPORT_METADATA,
    SET_ALL_REPORT_METRICS,
    SET_ALL_REPORT_METRICS_DETAIL,
    SET_GROUP_REORDER,
    SET_PERSONAGROUP_MAPPINGS,
    SET_REPORT_METADATA,
    UPDATE_GROUP_REORDER,
    UPDATE_GROUP_REORDER_STATUS,
    UPDATE_REPORT_METADATA,
    SET_IS_HIDE_VALUE,
    GET_ALL_DOWNLOAD_CONTENT,
    SET_ALL_DOWNLOAD_CONTENT,
    UPDATE_IS_HIDE_VALUE,
    SEARCH_RESPONSE_STATUS,
    GET_ALL_REPORT_METRICS_WITH_SEARCH,
    SEARCH_METRICS_RESPONSE_STATUS,
    IS_METADATA_LOADING,
    IS_METRICS_LOADING,
} from '../constants';

// getall persona's
function* getAllPersona() {
    try {
        const response = yield AxiosInstance.post(`/reportsearchservice/api/Search/GetAllPersonas`);
        const data = yield response.data;
        if (data) {
            yield put({ type: SET_ALL_PERSONA, payload: data });
        }
    } catch (error) {
        console.log('getAllPersona error', error);
    }
}

function* getAllReportMetadata(payload) {
    try {
        const response = yield AxiosInstance.get(`/reportsearchservice/api/Search/SearchByPersonaOrReportId?fromPage=${payload.pagination.fromPage}&takePage=${payload.pagination.takePage}${payload.urlparams || ""}`);
        const data = yield response.data;
        yield put({ type: SET_ALL_REPORT_METADATA, payload: data });
        if (data) {
            yield put({ type: IS_METADATA_LOADING, payload: false })
        }
    } catch (error) {
        console.log('getAllReportMetadata error', error);
        yield put({ type: SET_ALL_REPORT_METADATA, payload: [] });
        yield put({ type: IS_METADATA_LOADING, payload: false })
    }
}
function* getAllReportMetadataWithSearch(payload) {
    try {
        const response = yield AxiosInstance.post(`/reportsearchservice/api/Search/SearchByPersonaOrReportId?fromPage=${payload.pagination.fromPage}&takePage=${payload.pagination.takePage}${payload.urlparams || ""}`, payload.payload);
        const data = yield response.data;
        yield put({ type: SET_ALL_REPORT_METADATA, payload: data });
        yield put({ type: SEARCH_RESPONSE_STATUS, payload: true });
        if (data) {
            yield put({ type: IS_METADATA_LOADING, payload: false })
        }
    } catch (error) {
        console.log('getAllReportMetadataWithSearch error', error);
        yield put({ type: SET_ALL_REPORT_METADATA, payload: [] });
        yield put({ type: IS_METADATA_LOADING, payload: false })
    }
}

function* getAllReportMetricsDetails(payload) {
    try {
        const response = yield AxiosInstance.get(`/metadataservice/api/Report/GetReportSpecificMetric?reportId=${payload.contentId}${payload.paginationPayload}${payload.urlPramsData || ''}`);
        const data = yield response.data;
        yield put({ type: SET_ALL_REPORT_METRICS_DETAIL, payload: data });
        if (data) {
            yield put({ type: IS_METRICS_LOADING, payload: false });
        }
    } catch (error) {
        console.log('getAllReportMetrics error', error);
        yield put({ type: SET_ALL_REPORT_METRICS_DETAIL, payload: [] });
        yield put({ type: IS_METRICS_LOADING, payload: false });
    }
}

function* getAllGroups() {
    try {
        const response = yield AxiosInstance.post(`/reportsearchservice/Api/Search/GetADGroups`);
        const data = yield response.data;
        if (data) {
            yield put({ type: SET_ALL_GROUPS, payload: data });
        }
    } catch (error) {
        console.log('getAllGroups error', error);
    }
}

function* getPersonaGroupMappings(urlparams) {
    try {
        const response = yield AxiosInstance.get(`/metadataservice/api/Report/GetPersonaGroupMappings${urlparams || ""}`);
        const data = yield response.data;
        yield put({ type: SET_PERSONAGROUP_MAPPINGS, payload: data });
    } catch (error) {
        console.log('getPersonaGroupMappings error', error);
        yield put({ type: SET_PERSONAGROUP_MAPPINGS, payload: [] });
    }
}

function* getReportMetadata(payload) {
    try {
        const response = yield AxiosInstance.post(`/reportsearchservice/api/Search/GetReportDetailsByID?Id=${payload}`);
        const data = yield response.data;
        yield put({ type: SET_REPORT_METADATA, payload: data });
    } catch (error) {
        console.log('getReportMetadata error', error);
    }
}

function* updateReportMetadata(payload) {
    try {
        const response = yield AxiosInstance.post(`/reportsearchservice/api/Search/UpdateReport`, payload);
        const data = yield response.data;
        yield put({ type: REPORT_METADATA_EDIT_STATUS, payload: "success" });
    } catch (error) {
        console.log('updateReportMetadata error', error);
        yield put({ type: REPORT_METADATA_EDIT_STATUS, payload: "failed" });
    }
}

// likes&sortOrder=desc
// likes&sortOrder=asc

function* getAllReportMetrics(payload) {
    try {
        const response = yield AxiosInstance.get(`/metadataservice/api/Report/GetReportMetrix?fromPage=${payload.pagination?.fromPage}&takePage=${payload.pagination?.takePage}${payload.urlParams || ""}`);
        const data = yield response.data;
        yield put({ type: SET_ALL_REPORT_METRICS, payload: data });
        if (data) {
            yield put({ type: IS_METRICS_LOADING, payload: false });
        }
    } catch (error) {
        console.log('getAllReportMetrics error', error);
        yield put({ type: SET_ALL_REPORT_METRICS, payload: [] });
        yield put({ type: IS_METRICS_LOADING, payload: false })
    }
}

function* getAllReportMetricsSearch(payload) {
    try {
        const response = yield AxiosInstance.get(`/metadataservice/api/Report/GetReportMetrix${payload.searchString || ""}?fromPage=${payload.pagination.fromPage}&takePage=${payload.pagination.takePage}${payload.urlParams || ""}`);
        const data = yield response.data;
        yield put({ type: SET_ALL_REPORT_METRICS, payload: data });
        yield put({ type: SEARCH_METRICS_RESPONSE_STATUS, payload: true });
        if (data) {
            yield put({ type: IS_METRICS_LOADING, payload: false });
        }
    } catch (error) {
        console.log('getAllReportMetricsSearch error', error);
        yield put({ type: SET_ALL_REPORT_METRICS, payload: [] });
        yield put({ type: IS_METRICS_LOADING, payload: false })
    }
}

// get  reportGroups for reorder
function* getGroupsOrder() {
    try {
        const response = yield AxiosInstance.get(`/groupreorderservice/API/GroupReorder/GetGroupOrdering`);
        const data = yield response.data;
        yield put({ type: SET_GROUP_REORDER, payload: data });
    } catch (error) {
        console.log('getReportGroups error', error);
    }
}

function* updateGroupReorder(payload) {
    try {
        const response = yield AxiosInstance.post(`/groupreorderservice/API/GroupReorder/UpdateGroupNameOrdering`, payload);
        const data = yield response.data;
        if (data) {
            yield put({ type: GET_GROUP_REORDER });
            yield put({ type: UPDATE_GROUP_REORDER_STATUS, payload: "success" });
        }
    } catch (error) {
        console.log('getGroupReorder error', error);
        yield put({ type: GET_GROUP_REORDER });
        yield put({ type: UPDATE_GROUP_REORDER_STATUS, payload: "failed" });
    }
}

// show or hide content
function* showOrHideContentMetadata(payload) {
    try {
        if (payload !== undefined) {
            const response = yield AxiosInstance.put(`/metadataservice/api/Report/ShowOrHideContent?id=${payload.Id}&newValue=${payload.newValue}`);
            const data = yield response.data;
            if (data) {
                yield put({ type: SET_IS_HIDE_VALUE });
                yield put({ type: UPDATE_IS_HIDE_VALUE, payload: 'success' })
            }
        }
    } catch (error) {
        console.log('set isHide error', error);
        yield put({ type: UPDATE_IS_HIDE_VALUE, payload: 'failed' })
    }
}

function* exportAndDownloadContent() {
    try {
        const response = yield AxiosInstance.get(`/metadataservice/api/Report/GetDump`);
        const data = yield response.data;
        yield put({ type: SET_ALL_DOWNLOAD_CONTENT, payload: data });
    } catch (error) {
        console.log('getDownloadContent error', error);
    }
}

export function* userGroupSaga() {
    yield takeEvery(GET_ALL_PERSONA, getAllPersona);
    yield takeEvery(GET_ALL_REPORT_METADATA, (action) => getAllReportMetadata(action.payload));
    yield takeEvery(GET_REPORT_METADATA, (action) => getReportMetadata(action.payload));
    yield takeEvery(UPDATE_REPORT_METADATA, (action) => updateReportMetadata(action.payload));
    yield takeEvery(GET_ALL_REPORT_METADATA_WITH_SEARCH, (action) => getAllReportMetadataWithSearch(action.payload));
    yield takeEvery(GET_ALL_REPORT_METRICS_DETAIL, (action) => getAllReportMetricsDetails(action.payload));
    yield takeEvery(GET_GROUP_REORDER, getGroupsOrder);
    yield takeEvery(GET_ALL_GROUPS, getAllGroups);
    yield takeEvery(GET_PERSONAGROUP_MAPPINGS, (action) => getPersonaGroupMappings(action.payload));
    yield takeEvery(GET_ALL_REPORT_METRICS, (action) => getAllReportMetrics(action.payload));
    yield takeEvery(GET_ALL_REPORT_METRICS_WITH_SEARCH, (action) => getAllReportMetricsSearch(action.payload));
    yield takeEvery(UPDATE_GROUP_REORDER, (action) => updateGroupReorder(action.payload));
    yield takeEvery(SET_IS_HIDE_VALUE, (action) => showOrHideContentMetadata(action.payload));
    yield takeLatest(GET_ALL_DOWNLOAD_CONTENT, exportAndDownloadContent);
}