import React, { useState, useEffect } from "react";
import { Drawer, Box, Typography, IconButton, Button } from "@mui/material";
import {
  SET_ERROR_MODAL,
  SET_META_DRAWER,
  SET_LOADER_STATUS,
  IS_REPORT_UPDATED,
  IS_META_UPDATED,
} from "../../redux/constants";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import "./metaDrawer.css";
import { Stack } from "@mui/system";
import { uploadFilename } from "../../redux/actions";
import { Link } from "react-router-dom";
import { uploadFileToStorage, validateFileType } from "../../common";
import { DownloadLoader } from "../loader";
import { UPLOAD_FILE_PAGE } from "../../constants/constants";

const MetaDataDrawer = () => {
  const isLoading = useSelector((state) => state.app.isLoading);

  const { isMetaDrawerOpen, uploadReportExcelStatus, uploadReportExcelError } =
    useSelector((state) => state.userGroupManagement);
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Choose FIle");

  useEffect(() => {
    if (uploadReportExcelStatus) {
      if (uploadReportExcelStatus === "success") {
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "File imported successfully",
            modalTitle: "Success",
          },
        });
        dispatch({ type: IS_META_UPDATED, payload: true }); //fetch updated result
        dispatch({ type: IS_REPORT_UPDATED, payload: true });
      }
      dispatch({ type: SET_LOADER_STATUS, payload: false });
      setFileName("Choose FIle");
      setFile(null);
      setTimeout(() => {
        dispatch({ type: SET_META_DRAWER, payload: false });
      }, 2000);

      if (uploadReportExcelStatus === "failed") {
        const errorMsg = uploadReportExcelError
          ? uploadReportExcelError
          : "File import failed. Please try after sometime";
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: errorMsg,
            modalTitle: "Import from Excel Failed!",
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [uploadReportExcelStatus, uploadReportExcelError]);

  const onFileChange = async (event) => {
    let selectedFile = event.target.files[0];
    const validFile = await validateFileType(UPLOAD_FILE_PAGE.reportMetadata, selectedFile.name);
    if (!validFile) {
      dispatch({
        type: SET_ERROR_MODAL,
        payload: {
          status: true,
          errorMessage: "Invalid file, please select a file with .xlsx extension",
          modalTitle: "Alert!",
        },
      });
      setFile(null);
      setFileName("Choose File");
    }
    else {
      if (selectedFile.name.split(" ").length > 1) {
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "File name should not have space",
            modalTitle: "Alert!",
          },
        });
        setFile(null);
        setFileName("Choose File");
      } else {
        setFile(selectedFile);
        setFileName(selectedFile.name);
      }
    }
  };
  const handleDownload = () => {
    setTimeout(() => {
      dispatch({
        type: SET_ERROR_MODAL,
        payload: {
          status: true,
          errorMessage: "File downloaded successfully",
          modalTitle: "Success",
        },
      });
    }, 2000);
  };

  const uploadFile = async () => {
    try {
      if (!isLoading) {
        dispatch({ type: SET_LOADER_STATUS, payload: true });
        if (file) {
          let response = await uploadFileToStorage(file);
          if (response?._response?.status === 201) {
            dispatch(uploadFilename(fileName));
          } else {
            dispatch({
              type: SET_ERROR_MODAL,
              payload: {
                status: true,
                errorMessage: "File import failed. Please try after sometime",
                modalTitle: "Alert!",
              },
            });
            dispatch({ type: SET_LOADER_STATUS, payload: false });
          }
        } else {
          dispatch({
            type: SET_ERROR_MODAL,
            payload: {
              status: true,
              errorMessage: "Please choose file",
              modalTitle: "Alert!",
            },
          });
          dispatch({ type: SET_LOADER_STATUS, payload: false });
        }
      }
    } catch (error) {
      dispatch({
        type: SET_ERROR_MODAL,
        payload: {
          status: true,
          errorMessage: "File import failed. Please try after sometime",
        },
      });
      dispatch({ type: SET_LOADER_STATUS, payload: false });
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isMetaDrawerOpen}
        onClose={() =>{if(!isLoading) dispatch({ type: SET_META_DRAWER, payload: false })}}
      >
        <Box className="metaDrawer" role="presentation">
          <Box className="metaHeader">
            <Typography variant="headingTwo" sx={{ fontWeight: "normal" }}>
              Upload content via Template
            </Typography>
            <IconButton
              onClick={() =>
                dispatch({ type: SET_META_DRAWER, payload: false })
              }
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box>
            <Box className="upload-btn-wrapper">
              <label
                style={{
                  fontSize: "0.70rem",
                  fontWeight: "600",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Select the Excel file to upload content metadata
              </label>
              <input
                onChange={onFileChange}
                type="file"
                id="fileSelect"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <button className="outlined">
                <span>{fileName}</span>{" "}
                <img src="./icons/icn_file-explorer.svg" />
              </button>
            </Box>
            <Box onClick={handleDownload} className="upload-btn-wrapper">
              <Link to="/MetadataTemplate.xlsx" target="_blank" download>
                <button className="contained">
                  <span>Download Template</span>
                  <img src="./icons/icn_MSexcel.svg" />
                </button>
              </Link>
            </Box>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            className="filterFooter metaDrawerFooter"
          >
            <Button
              onClick={() => {
                setFile(null);
                setFileName("Choose File");
                dispatch({ type: SET_META_DRAWER, payload: false });
              }}
              size="small"
              color="secondary"
              variant="contained"
            >
              CANCEL
            </Button>
            <Button
              onClick={uploadFile}
              size="small"
              color="primary"
              variant="contained"
            >
              {" "}
              {isLoading ? (
                <DownloadLoader
                  style={{ height: "24px !important" }}
                  loadingText=""
                />
              ) : (
                " Upload Content"
              )}
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default MetaDataDrawer;
