import axios from "axios";
import { API_BASE_URL } from "../../AppConstant";

const axiosApiInstance = axios.create({
  baseURL: API_BASE_URL,
})

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    const accessToken = sessionStorage.getItem("access_token");
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
      "Accept": "application/json",
      "Content-Type": "application/json"
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

export default axiosApiInstance;