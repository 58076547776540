import React, { useState, useEffect } from "react";
import { IconButton, } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import { SET_SEARCH_TERM } from "../../redux/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { getSearchResults } from "../../redux/actions";
import "./SearchBox.css";

export function MultipleSearchBox() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { searchTerms } = useSelector((state) => state.search);
  const { uploadThumbnailStatus } = useSelector((state) => state.report);
  const user = useSelector(state => state.user);

  const [value, setValue] = useState('');

  useEffect(() => {
    if (location.pathname === "/search-results") {
      setValue(searchTerms);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (uploadThumbnailStatus === "success" && location.pathname === "/search-results") {
      dispatch(getSearchResults({ UserID: user.userId, searchText: value }));
    }
    // eslint-disable-next-line 
  }, [uploadThumbnailStatus])

  const handleSearch = () => {
    dispatch({ type: SET_SEARCH_TERM, payload: value });
    if (location.pathname !== "/search-results") {
      navigate("/search-results");
    }
  };

  return (
    <>
      <div className="searchBox" style={{ borderRadius: "28px", width: '70%' }}>
        <input
          onChange={e=>{setValue(e.target.value)}}
          onKeyDown={(evt) => {
            if (evt.key === "Enter") {
              handleSearch();
            }
          }}
          placeholder="Search ..."
          type="search"
          style={{ height: "35px" }}
          value={value}
        />
        <IconButton
          onClick={handleSearch}
          aria-label="delete"
          size="small"
          style={{ padding: "7px 10px" }}
        >
          <SearchRounded />
        </IconButton>
      </div>
    </>
  );
}

export function SearchBox({ onChange, onClick, placeholder }) {
  return (
    <>
      <div className="searchBox" style={{ borderRadius: "10px" }}>
        <input
          onChange={onChange}
          onKeyDown={(evt) => {
            if (evt.key === "Enter") {
              onClick();
            }
          }}
          placeholder={placeholder || "Search ..."}
          type="search"
          style={{ height: "35px" }}
        />
        <IconButton
          onClick={onClick}
          aria-label="delete"
          size="small"
          style={{ padding: "7px 10px" }}
        >
          <SearchRounded />
        </IconButton>
      </div>
    </>
  );
}
