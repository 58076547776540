import React from 'react'

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const AppNotFound = ({message}) => {
    return (
        <Box sx={{p: 3, mt:8, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img style={{width: '400px'}} src='/icons/icn_app_not_found.svg' alt='' />
            <Typography variant='body2' color='#444444'>{message}</Typography>
        </Box>
    )
}

export default AppNotFound