import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './personaGroupMapping.css';
import AdminToolHeader from '../../components/adminToolHeader';
import { USER_MANAGEMENT } from '../../constants/constants';
import { getallGroups, getPersonaGroupMappings } from '../../redux/actions';
import PersonaGroupTable from './personaGroupTable';

const { personaGroup } = USER_MANAGEMENT;

const PersonaGroupMapping = () => {
    const dispatch = useDispatch();

    const { groups, searchValue, filterValue } = useSelector(state => state.userGroupManagement);

    useEffect(() => {
        dispatch(getallGroups());
    }, []);


    const personaGroupSearchFilter = (filter = filterValue, search = searchValue) => {
        searchFilterSortAction(filter, search);
    }
    const personaGroupSort = (sortValue) => {
        searchFilterSortAction(filterValue, searchValue, sortValue);
    }

    function searchFilterSortAction(filter, search, sort) {
        let urlParams = "";
        if (search) {
            urlParams = `?persona=${encodeURIComponent(search)}`;
        }
        if (filter && filter !== "All Groups") {
            if (!urlParams) urlParams += "?";
            else urlParams += "&";
            urlParams += `adgroup=${encodeURIComponent(filter)}`;
        }
        if (sort) {
            if (!urlParams) urlParams += "?";
            else urlParams += "&";
            urlParams += `sortOrder=${sort}`;
        }
        dispatch(getPersonaGroupMappings(urlParams));
    }


    return (
        <div className='persona-group-mapping'>
            <AdminToolHeader
                pageName={personaGroup.pageName}
                description={personaGroup.pageDescription}
                dropdownOptions={["All Groups", ...groups]} searchLabel={personaGroup.searchLabel}
                showDropdown={true} showSearch={true}
                handleChange={personaGroupSearchFilter}
            />
            <PersonaGroupTable handleSort={personaGroupSort} />
        </div>
    )
}

export default PersonaGroupMapping;