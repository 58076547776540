import { takeEvery, put } from "redux-saga/effects";
import {
  GET_KEYWORD_LIST,
  SET_KEYWORD_LIST,
  SET_SEARCH_RESULTS,
  GET_SEARCH_RESULTS,
  IS_DASHBOARD_RESULTS_LOADING,
} from "../constants";
import AxiosInstance from "../../services/api/AxiosInstance";

function* getKeywordList() {
  try {
    const response = yield AxiosInstance.post(
      "/reportsearchservice/api/Search/GetAllTaggedWords"
    );
    const data = yield response.data;
    yield put({ type: SET_KEYWORD_LIST, payload: data });
  } catch (error) {
    console.log("getKeywordList error", error);
  }
}

function* getAllSearchReports(payload) {
  try {
    const response = yield AxiosInstance.post(
      "/reportsearchservice/api/Search",
      payload
    );
    const data = yield response.data;
    yield put({ type: SET_SEARCH_RESULTS, payload: data });
    if (data) {
      yield put({ type: IS_DASHBOARD_RESULTS_LOADING, payload: false });
    }
  } catch (error) {
    console.log("get all Search Reports error", error);
    yield put({ type: IS_DASHBOARD_RESULTS_LOADING, payload: false });
  }
}

export function* searchSaga() {
  yield takeEvery(GET_KEYWORD_LIST, getKeywordList);
  yield takeEvery(GET_SEARCH_RESULTS, (action) =>
    getAllSearchReports(action.payload)
  );
}
