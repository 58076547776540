import React from 'react'
import { ButtonBack } from '../../components/button'
import './errorpage.css'

const NotFound = () => {
  return (
    <>
      <ButtonBack />
      <div className='errorView'>404 Error : Page Not Found</div>
    </>
  )
}

export default NotFound