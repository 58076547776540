import { DMS_URL, VIDI_URL } from '../AppConstant';

export const PAGE_NAME = {
    Keyreport: "Key Reports",
    Search: "search",
    Favourite: "Favourite Contents"
};

const dmsUrl = DMS_URL;
const vidiUrl = VIDI_URL;

export const MENU_NAME = {
    knowledgeBase: { title: "PowerBI Library", description: "Self-serve online library of PowerBI reports, dashboards and apps", released: true, newTabRedirection: false },
    visualDiscovery: { title: "Visual Discovery", description: "Visual discovery (data exploration) platform", released: true, externalLink: vidiUrl, newTabRedirection: true },
    documentManagementSystem: { title: "Document Management System", description: "Workplace and Document collaboration platform", released: false, externalLink: dmsUrl, newTabRedirection: true },
    dataCubes: {title:"Data Cubes", description: "Self-serve library of Hive-based data, downloadable in Excel cube format",released: true,externalLink:"https://imptob.sharepoint.com/sites/HiveData",newTabRedirection:true},
    consumerResearch: {title:"Consumer Research", description: "Global repository of Consumer Research projects, Consumer Voices, and Survey news",released: true,externalLink:"https://imptob.sharepoint.com/sites/ConsumerResearchProjects/",newTabRedirection:true},
    adminTools: { title: "Admin Tools", description: "", released: true }
};

export const pageList = ["keyreport", "businessreport", "search", "favourite"];
export const ReportTypes = ["Key Reports", "Business Group Report"];

export const homeTopics = [
    {
        url: "./images/powerBI.png",
        link: "/dashboard",
        title: MENU_NAME.knowledgeBase.title,
        content: MENU_NAME.knowledgeBase.description,
        visible: MENU_NAME.knowledgeBase.released,
        newTab: MENU_NAME.knowledgeBase.newTabRedirection,
    },
    {
        url: "./images/ViDi_officialLogo.png",
        link: MENU_NAME.visualDiscovery.externalLink,
        title: MENU_NAME.visualDiscovery.title,
        content: MENU_NAME.visualDiscovery.description,
        visible: MENU_NAME.visualDiscovery.released,
        newTab: MENU_NAME.visualDiscovery.newTabRedirection,
    },
    {
        url: "./images/dms.png",
        link: MENU_NAME.documentManagementSystem.externalLink,
        title: MENU_NAME.documentManagementSystem.title,
        content: MENU_NAME.documentManagementSystem.description,
        visible: MENU_NAME.documentManagementSystem.released,
        newTab: MENU_NAME.documentManagementSystem.newTabRedirection,

    },
    {
        url: "./images/knowledge-base.png",
        link: MENU_NAME.dataCubes.externalLink,
        title: MENU_NAME.dataCubes.title,
        content: MENU_NAME.dataCubes.description,
        visible: MENU_NAME.dataCubes.released,
        newTab: MENU_NAME.dataCubes.newTabRedirection,
    },
    {
        url: "./images/Consumer_research.png",
        link: MENU_NAME.consumerResearch.externalLink,
        title: MENU_NAME.consumerResearch.title,
        content: MENU_NAME.consumerResearch.description,
        visible: MENU_NAME.consumerResearch.released,
        newTab: MENU_NAME.consumerResearch.newTabRedirection,

    },
];

export const appFooterText = "ⓒ2022, Imperial Insights HUB, ITG.  Privacy Policy | Disclamier";

//MODEL
export const EMAIL_ADDRESS = "Email Address";
export const COPIED = "Copied!"
export const SHARE_REPORT = "Share Report";
export const SHARE_APP = "Share App";
export const NO_REPORT_FOUND = "No report found";
export const NO_APP_FOUND = "No app found";
export const NO_FAVOURITE_FOUND = "No favourite apps/reports found";
export const NO_MAPPING_FOUND = "No mapping found";
export const NO_DETAIL_FOUND = "No detail found";
export const NO_GROUP_FOUND = "No group found";
export const NO_CONTENT_FOUND = "No content found";

export const USER_MANAGEMENT = {
    reportMetadata: {
        pageName: "Metadata",
        searchLabel: "Search by ID, name"
    },
    personaGroup: {
        pageName: "Persona Group Mapping",
        pageDescription: "For making any changes to the mappings please use Persona mapping (metadata) from the Admin tools",
        searchLabel: "Search by persona"
    },
    reportMetrics: {
        pageName: "Metrics",
        searchLabel: "Search by ID, name"
    },
    groupReorder: {
        pageName: "Group re-order",
        pageDescription: "Drag and re-arrange the groups as you want them to appear for the user",
    }
};

export const UPLOAD_FILE_PAGE = {
    thumbnail: "thumbnail",
    reportMetadata: "metadata",
};

export const embedTokenError = 'Something went wrong, please try again';
export const appNotFoundMessage = "To view this app, ask the owner for access";
export const errorMessage = "Something went wrong, please try again later";