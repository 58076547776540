import { ErrorModal } from '../modal';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <span><big>&copy;</big><b>Imperial Insights HUB</b>, ITG</span>
      <div>
        <a href="#">Privacy Policy</a> | <a href="#">Disclaimer</a>
      </div>
      {<ErrorModal />}
    </footer>
  )
}

export default Footer;