import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { MultipleSearchBox } from '../search-box';
import './Header.css'
import { ButtonBack } from '../button';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MAIN_DRAWER } from '../../redux/constants';
import { MENU_NAME } from '../../constants/constants';

const Header = () => {

  const open = useSelector(state => state.app.isMainDrawerOpen)
  const dispatch = useDispatch();

  const handleDrawer = () => {
    dispatch({ type: SET_MAIN_DRAWER, payload: !open })
  };

  return (
    <header className='header'>
      {/* <Box className='heder-box' sx={{ backgroundImage: "url('/images/mind-bulb.png')" }}></Box> */}
      <Box className='header-top'>
        <Box className='blank back-btn header-top-btn' sx={{ width: '80px' }}>
          <ButtonBack />
        </Box>
        <MultipleSearchBox />
        <Box className='blank media-menu-btn header-top-btn' sx={{ width: '80px' }}>
          <IconButton onClick={handleDrawer} aria-label="menu" size="small">
            {window.innerWidth <= 1024 && <MenuIcon fontSize="inherit" />}
          </IconButton>
        </Box>
      </Box>
      <Box className='header-bottom'>
        <img className='header-bottom-img' src='/icons/icn_powerbi_tile.svg'/>
        {/* <Box className='header-bottom-img' sx={{ background: "url('/icons/icn_powerbi_tile.svg') no-repeat center/cover" }}> */}
        {/* </Box> */}
        <Box className='header-bottom-txt'>
          <Typography variant='headingOne'>{MENU_NAME.knowledgeBase.title}</Typography>
          <Typography sx={{fontSize: '0.7rem'}}>{MENU_NAME.knowledgeBase.description}</Typography>
        </Box>
      </Box>
    </header>
  )
}

export default Header