import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './loader.css';
import { Box, Typography } from '@mui/material';

export function Loader(style) {
  return (
    <Box sx={style} className='loader'>
      <CircularProgress color="secondary" />
    </Box>
  );
}

export function DownloadLoader({ loadingText, style }) {
  return (
    <Box sx={style} className='download-loader'>
      <CircularProgress size={15} color='orange' />
      <Typography>{loadingText}</Typography>
    </Box>
  );
}

export function ResponseLoader({ loadingText, style }) {
  return (
    <Box sx={style} className='download-loader'>
      <CircularProgress size={25} color='primary' />
      <Typography>{  loadingText}</Typography>
    </Box>
  );
}
