import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./reportMetadata.css";
import AdminToolHeader from "../../components/adminToolHeader";
import ReportMetadataTable from "./reportMetadataTable";
import { USER_MANAGEMENT } from "../../constants/constants";
import {
  getallPersona,
  getallReportMetadata,
  getallReportMetadataWithSearch,
} from "../../redux/actions";
import {
  IS_META_UPDATED,
  IS_METADATA_LOADING,
  SET_META_SORT_TERM,
} from "../../redux/constants";

const { reportMetadata } = USER_MANAGEMENT;

const ReportMetadata = () => {
  const dispatch = useDispatch();

  const [fromPage, setFromPage] = useState(0);
  const [takePage, setTakePage] = useState(15);
  const [totalRows, setTotalRows] = useState(0);
  const [payloadData, setPayloadData] = useState({});
  const [urlPramsData, setUrlParamsData] = useState("");

  let pagination = {};
  pagination["fromPage"] = fromPage;
  pagination["takePage"] = takePage;

  const {
    personaList,
    searchValue,
    filterValue,
    metadataSortTerm,
    isMetadataUpdated,
  } = useSelector((state) => state.userGroupManagement);

  useEffect(() => {
    dispatch(getallPersona());
  }, []);

  useEffect(() => {
    if (isMetadataUpdated) {
      setTimeout(() => {
        handleSort(metadataSortTerm.column, metadataSortTerm.value);
        dispatch({ type: IS_META_UPDATED, payload: false });
      }, 1000);
    }
  }, [isMetadataUpdated]);

  const reportMetadataWithSearchFilter = (
    filter = filterValue,
    search = searchValue
  ) => {
    searchFilterSortAction({ filter, search });
  };
  const handleSort = (sortColumn, sortValue) => {
    searchFilterSortAction({
      filter: filterValue,
      search: searchValue,
      sortColumn,
      sortValue,
    });
  };
  function searchFilterSortAction({ filter, search, sortColumn, sortValue }) {
    let urlparams = "";
    if (sortColumn) {
      urlparams = `&sortColumn=${sortColumn}&sortOrder=${sortValue || "asc"}`;
      setUrlParamsData(urlparams);
    }

    if ((filter === "All Persona" || !filter) && !search) {
      setFromPage(0);
      dispatch(getallReportMetadata({ pagination, urlparams }));
    } else {
      let payload = {};
      payload["Persona"] = filter === "All Persona" ? "" : filter;
      if (searchValue) {
        payload["ReportIdOrName"] = searchValue;
      }
      setPayloadData(payload);

      dispatch(
        getallReportMetadataWithSearch({ payload, urlparams, pagination })
      );
      dispatch({ type: IS_METADATA_LOADING, payload: true });
    }
  }

  return (
    <div>
      <AdminToolHeader
        pageName={reportMetadata.pageName}
        dropdownOptions={["All Persona", ...personaList]}
        searchLabel={reportMetadata.searchLabel}
        showDropdown={true}
        showSearch={true}
        showImport={true}
        showDownload={true}
        handleChange={reportMetadataWithSearchFilter}
      />
      <ReportMetadataTable
        handleSort={handleSort}
        fromPage={fromPage}
        setFromPage={setFromPage}
        takePage={takePage}
        setTakePage={setTakePage}
        totalRows={totalRows}
        setTotalRows={setTotalRows}
        searchValue={searchValue}
        pagination={pagination}
        searchPayload={payloadData}
        urlPramsData={urlPramsData}
        setUrlParamsData={setUrlParamsData}
      />
    </div>
  );
};

export default ReportMetadata;
