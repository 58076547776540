import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ReportTile from "../report-tile";
import { FilterDrawer } from "../report-filter";
import { useDispatch } from "react-redux";
import {
  SET_CHECKED_FILTER,
  SET_FILTER_DRAWER,
  SET_REPORT_FILTER,
  SET_APP_FILTER,
} from "../../redux/constants";
import { NO_REPORT_FOUND, NO_APP_FOUND } from "../../constants/constants";
import { ResponseLoader } from "../loader";

import "../../views/dashboard/dashboard.css";

const SearchList = ({ reports, searchTerm, isLoading }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [filtersArray, setFiltersArray] = useState([]);
  const [FilteredReports, setFilteredReports] = useState(reports || []);

  const handleFilters = (value) => {
    setFiltersArray(value);
  };

  useEffect(() => {
    dispatch({ type: SET_CHECKED_FILTER, payload: [] });
    setFiltersArray([]);
    return () => {
      dispatch({ type: SET_CHECKED_FILTER, payload: [] });
    };
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (filtersArray.length === 0) {
      setFilteredReports(reports);
    } else {
      const filteredValues = reports?.filter((item) =>
        filtersArray.find((filteritem) => item.theme.includes(filteritem))
      );
      setFilteredReports(filteredValues);
    }
  }, [filtersArray, reports]);

  const appValues = FilteredReports.filter(
    (content) => content.contentType === "App"
  );
  const reportValues = FilteredReports.filter(
    (content) => content.contentType === "Report"
  );

  useEffect(() => {
    if (reportValues) {
      dispatch({ type: SET_REPORT_FILTER, payload: reportValues });
    }
    if (appValues) {
      dispatch({ type: SET_APP_FILTER, payload: appValues });
    }
  }, [appValues, reportValues]);

  return (
    <Box sx={{ px: 3 }}>
      {isLoading ? (
        <ResponseLoader
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "13%",
          }}
          loadingText="  Loading..."
        />
      ) : (
        <>
          <Typography
            sx={{ mt: 2, color: "#919191", fontSize: "0.8rem" }}
          >{`About ${FilteredReports?.length} results found`}</Typography>
          <Box className="reportsGroupHeader" sx={{ mt: 0 }}>
            <Typography variant="headingTwo" sx={{ color: "#5C5046" }}>
              <span>Search Results </span>"
              {searchTerm ?? <span>{searchTerm}</span>}"
            </Typography>
            <IconButton
              onClick={() =>
                dispatch({ type: SET_FILTER_DRAWER, payload: true })
              }
            >
              <img src="/icons/icon_filter.svg" alt="filter" />
            </IconButton>
          </Box>
          <Divider sx={{ marginBottom: "0.8rem" }} />
          <Box className="dashGroupHeader" sx={{ pt: "1rem" }}>
            <Typography variant="headingTwo" sx={{ color: "#5C5046" }}>
              Reports {`(${reportValues?.length})`}
            </Typography>
            {reportValues.length ? (
              <Link to={"/reports/search-results"}>
                <Button className="viewAllBtn">VIEW ALL</Button>
              </Link>
            ) : (
              <Button disabled className="viewAllBtn">
                VIEW ALL
              </Button>
            )}
          </Box>
          <Divider sx={{ marginBottom: "0.8rem" }} />
          {reportValues.length === 0 ? (
            <Box className="no-report" sx={{ mt: "2rem" }}>
              {NO_REPORT_FOUND}
            </Box>
          ) : (
            <Grid container spacing={2}>
              {reportValues.map((report, index) => {
                if (index < 5) {
                  return (
                    <Grid item xs={6} md={2} sm={4} key={index}>
                      <ReportTile content={report} />
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}

          <Box className="dashGroupHeader" sx={{ pt: "1rem" }}>
            <Typography variant="headingTwo" sx={{ color: "#5C5046" }}>
              Apps {`(${appValues?.length})`}
            </Typography>
            {appValues.length ? (
              <Link to={"/apps/search-results"}>
                <Button className="viewAllBtn">VIEW ALL</Button>
              </Link>
            ) : (
              <Button disabled className="viewAllBtn">
                VIEW ALL
              </Button>
            )}
          </Box>
          <Divider sx={{ marginBottom: "0.8rem" }} />
          {appValues.length === 0 ? (
            <Box className="no-report" sx={{ mt: "2rem" }}>
              {NO_APP_FOUND}
            </Box>
          ) : (
            <Grid container spacing={2}>
              {appValues.map((report, index) => {
                if (index < 5) {
                  return (
                    <Grid item xs={6} md={2} sm={4} key={index}>
                      <ReportTile content={report} />
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}
          <FilterDrawer handleFilters={(filters) => handleFilters(filters)} />
        </>
      )}
    </Box>
  );
};

export default SearchList;
