// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-back {
  color: #F07300 !important;
  font-size: 0.75rem !important;
  text-transform: capitalize !important;
}

.button-back span {
  color: #000;
  margin-right: 0px !important;
}

.button-back span svg {
  font-size: 1.5rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/button/Button.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,qCAAqC;AACvC;;AAEA;EACE,WAAW;EACX,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".button-back {\n  color: #F07300 !important;\n  font-size: 0.75rem !important;\n  text-transform: capitalize !important;\n}\n\n.button-back span {\n  color: #000;\n  margin-right: 0px !important;\n}\n\n.button-back span svg {\n  font-size: 1.5rem !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
