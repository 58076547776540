
import { BlobServiceClient } from '@azure/storage-blob';
import { v4 as uuidv4 } from "uuid";
import { SAS_TOKEN, STORAGE_ACCOUNT_NAME, STORAGE_CONTAINER } from '../AppConstant';
import { PAGE_NAME, UPLOAD_FILE_PAGE } from '../constants/constants';
import { SET_REPORT_DETAILS, SET_ALL_REPORTS, SET_SEARCH_RESULTS, IS_REPORT_UPDATED } from '../redux/constants';

export const generateFilename = (file) => {
  return `${uuidv4()}.${file?.name.substr(file?.name.lastIndexOf('.') + 1, file?.name.length)}`;
}

export const getUserInitials = (username) => {
  return username.split(' ')
    .map(word => word[0])
    .join(' ');
}

export async function getReportDetailsById(reports, reportId) {
  return reports.reduce((acc, { reportDetails, ...rest }) => (
    [...acc, rest, ...reportDetails]
  ), [])?.find(item => item.reportID === reportId) || null;
}


export async function getSearchReportDetailsById(reports, reportId) {
  return reports?.find(item => item.reportID === reportId) || null;
}


export function LikeUnlikeAction({ reportId, isLiked, likeCount, allReports, searchReports = [], dispatch }) {
  let reports = Object.create(allReports);
  reports.map(reportGroup => {
    reportGroup.contentDetails= mapLike(reportGroup.contentDetails, reportId, isLiked, likeCount, dispatch);
    return reportGroup;
  })
  dispatch({ type: SET_ALL_REPORTS, payload: reports });

  if (searchReports.length) {
    let search_reports = Object.create(searchReports);
    search_reports = mapLike(search_reports, reportId, isLiked, likeCount, dispatch);
    dispatch({ type: SET_SEARCH_RESULTS, payload: search_reports });
  }
  setTimeout(() => {
    dispatch({ type: IS_REPORT_UPDATED, payload: true });
  }, 1000);
}

function mapLike(contentDetails, reportId, isLiked, likeCount, dispatch) {
  return contentDetails.map(contentDetail => {
    if (contentDetail.contentID === reportId) {
      contentDetail.isLiked = isLiked;
      contentDetail.totalLikes = likeCount;
      dispatch({ type: SET_REPORT_DETAILS, payload: contentDetail });
    }
    return contentDetail;
  })
}

export function FavouriteUnfavouriteAction({ reportId, isFavourite, favCount, allReports, searchReports = [], dispatch }) {
  let reports = Object.create(allReports);
  let reportDetailObj = null;
  reports.map(reportGroup => {
    reportGroup.contentDetails = mapFavourite(reportGroup.contentDetails, reportId, isFavourite, favCount);
    if (!reportDetailObj) {
      reportDetailObj = reportGroup.contentDetails.find(detail => detail.contentID === reportId);
    }
    return reportGroup;
  })

  if (searchReports.length) {
    let search_reports = Object.create(searchReports);
    search_reports = mapFavourite(search_reports, reportId, isFavourite, favCount);
    if (!reportDetailObj) {
      reportDetailObj = search_reports.find(detail => detail.contentID === reportId);
    }
    dispatch({ type: SET_SEARCH_RESULTS, payload: search_reports });
  }

  if (isFavourite) {
    reports.forEach(reportGroup => {
      if (reportGroup.groupName === PAGE_NAME.Favourite && reportDetailObj) {
        reportGroup.contentDetails.push(reportDetailObj);
      }
    })
  }
  else {
    reports.forEach(reportGroup => {
      if (reportGroup.groupName === PAGE_NAME.Favourite) {
        const indexOfObject = reportGroup.contentDetails.findIndex(object => {
          return object.contentID === reportId;
        });
        reportGroup.contentDetails.splice(indexOfObject, 1);
      }
    })
  }
  dispatch({ type: SET_REPORT_DETAILS, payload: reportDetailObj });
  dispatch({ type: SET_ALL_REPORTS, payload: reports });
  setTimeout(() => {
    dispatch({ type: IS_REPORT_UPDATED, payload: true });
  }, 1000);
}

function mapFavourite(contentDetails, reportId, isFavourite, favCount) {
  return contentDetails.map(contentDetail => {
    if (contentDetail.contentID === reportId) {
      contentDetail.isFavourite = isFavourite;
      contentDetail.totalFavourite = favCount;
    }
    return contentDetail;
  })
}

export function reportRating({ reportId, reportRate, allReports, searchReports = [], dispatch }) {
  let reports = Object.create(allReports);
  reports.map(reportGroup => {
    reportGroup.contentDetails.map(contentDetail => {
      if (contentDetail.contentID === reportId) {
        contentDetail.contentRate = reportRate;
        contentDetail.rating = reportRate;
        dispatch({ type: SET_REPORT_DETAILS, payload: contentDetail });
      }
      return contentDetail;
    })
    return reportGroup;
  })
  dispatch({ type: SET_ALL_REPORTS, payload: reports });

  if (searchReports.length) {
    let search_reports = Object.create(searchReports);
    search_reports.map(contentDetail => {
      if (contentDetail.contentID === reportId) {
        contentDetail.contentRate = reportRate;
        contentDetail.rating = reportRate;
        dispatch({ type: SET_REPORT_DETAILS, payload: contentDetail });
      }
      return contentDetail;
    });
    dispatch({ type: SET_SEARCH_RESULTS, payload: search_reports });
  }
  setTimeout(() => {
    dispatch({ type: IS_REPORT_UPDATED, payload: true });
  }, 1000);
}

export async function uploadFileToStorage(file, fileName) {

  const blobService = new BlobServiceClient(
    `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/?${SAS_TOKEN}`
  );

  const ContainerClient = blobService.getContainerClient(`${STORAGE_CONTAINER}`)

  const blobClient = ContainerClient.getBlockBlobClient(fileName || file.name);
  const options = { blobHTTPHeaders: { blobContentType: file.type } }

  return await blobClient.uploadData(file, options);

}

export async function validateFileType(type, fileName) {
  if (fileName) {
    let extension = fileName.substr(fileName.lastIndexOf('.') + 1, fileName.length)?.toLowerCase(); //to get extension
    let allowedExtensions = [];
    if (type === UPLOAD_FILE_PAGE.thumbnail) {
      allowedExtensions = ["png", "jpeg", "jpg"];
    }
    else if (type === UPLOAD_FILE_PAGE.reportMetadata) {
      allowedExtensions = ["xlsx"];
    }
    if (allowedExtensions.includes(extension)) {
      return true;
    }
  }
  return false;
}