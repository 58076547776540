import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "./reportMetadata.css";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import {
  getallReportMetadata,
  getReportMetadata,
  showOrHideContentMetadata,
  getallReportMetadataWithSearch,
} from "../../redux/actions";
import {
  SET_EDIT_META_DRAWER,
  SET_META_SORT_TERM,
  SET_ERROR_MODAL,
  UPDATE_IS_HIDE_VALUE,
  SEARCH_RESPONSE_STATUS,
  IS_METADATA_LOADING,
} from "../../redux/constants";
import { NO_REPORT_FOUND } from "../../constants/constants";
import { ResponseLoader } from "../../components/loader";

const headCells = [
  {
    label: "ID",
    column: "contentId",
    sort: true,
  },
  {
    label: "Type",
    column: "contentType",
    sort: true,
  },
  {
    label: "Name",
    column: "contentName",
    sort: true,
  },
  {
    label: "Workspace",
    column: "workspaceName",
    sort: true,
  },
  {
    label: "Date Published",
    column: "contentPublishedDate",
    sort: true,
  },
  {
    label: "Persona Mapped To",
    column: "persona",
    sort: true,
  },
  {
    label: "Action",
    align: "right",
    sort: false,
  },
];

export default function ReportMetadataTable(props) {
  const dispatch = useDispatch();

  const {
    fromPage,
    setFromPage,
    takePage,
    searchPayload,
    urlPramsData,
    setUrlParamsData,
  } = props;

  let pagination = {};
  pagination["fromPage"] = fromPage;
  pagination["takePage"] = takePage;

  const [page, setPage] = useState(false);
  const [rowsPerPage] = useState(15);
  const [updateRow, setUpdateRow] = useState(false);

  const {
    reportMetadata,
    metadataSortTerm,
    isHideStatus,
    searchValue,
    isSearchSuccess,
    isMetadatLoading,
  } = useSelector((state) => state.userGroupManagement);

  const { searchByPersonaResponse, totalCount } = reportMetadata;

  const [isHideContent, setIsHideContent] = useState(
    searchByPersonaResponse?.isHide
  );

  useEffect(() => {
    dispatch({
      type: SET_META_SORT_TERM,
      payload: { column: "", value: "" },
    });
  }, []);

  useEffect(() => {
    if (!searchValue) {
      dispatch({ type: IS_METADATA_LOADING, payload: true });
      dispatch({ type: SEARCH_RESPONSE_STATUS, payload: false });
      setTimeout(() => {
        dispatch(getallReportMetadata({ pagination, urlparams: urlPramsData }));
      }, 1000);
    }

    if (isSearchSuccess && searchValue) {
      setTimeout(() => {
        dispatch(
          getallReportMetadataWithSearch({
            payload: searchPayload,
            urlparams: urlPramsData,
            pagination,
          })
        );
        dispatch({ type: IS_METADATA_LOADING, payload: true });
      }, 1000);
    }
  }, [fromPage, isHideContent]);

  useEffect(() => {
    setFromPage(0);
  }, [searchValue, urlPramsData, searchPayload]);

  useEffect(() => {
    if (searchValue && isSearchSuccess) {
      setUrlParamsData("");
      dispatch({
        type: SET_META_SORT_TERM,
        payload: { column: "", value: "" },
      });
    }
  }, [searchValue]);

  useEffect(() => {
    if (isHideStatus) {
      // dispatch({ type: SET_LOADER_STATUS, payload: false });
      isHideStatus === "success" &&
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Preference updated successfully",
            modalTitle: "Success",
          },
        });
      isHideStatus === "failed" &&
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Preference update failed",
            modalTitle: "Failed",
          },
        });
      dispatch({ type: UPDATE_IS_HIDE_VALUE, payload: null }); //reset status
      // dispatch({ type: IS_META_UPDATED, payload: true });
      // dispatch({ type: SET_EDIT_META_DRAWER, payload: false });
    }
    // eslint-disable-next-line
  }, [isHideStatus]);

  const handleEditBtn = (id) => {
    dispatch(getReportMetadata(id));
    dispatch({ type: SET_EDIT_META_DRAWER, payload: true });
  };

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              align={headCell?.align || "left"}
              padding={"normal"}
            >
              <Box sx={{ display: "flex" }}>
                {headCell.label}
                {headCell.sort && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "0.5rem",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      size="small"
                      className={
                        metadataSortTerm.column === headCell.column &&
                        metadataSortTerm.value === "asc"
                          ? "sort-btn-disable"
                          : "sort-btn"
                      }
                      onClick={() => {
                        dispatch({
                          type: SET_META_SORT_TERM,
                          payload: {
                            column: headCell.column,
                            value: "asc",
                          },
                        });
                        if (
                          metadataSortTerm.column !== headCell.column ||
                          metadataSortTerm.value !== "asc"
                        ) {
                          props.handleSort(headCell?.column, "asc");
                        }
                      }}
                    >
                      <img
                        className="sort-icon"
                        src="./icons/icn_uparrow.svg"
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      className={
                        metadataSortTerm.column === headCell.column &&
                        metadataSortTerm.value === "desc"
                          ? "sort-btn-disable"
                          : "sort-btn"
                      }
                      onClick={() => {
                        dispatch({
                          type: SET_META_SORT_TERM,
                          payload: {
                            column: headCell.column,
                            value: "desc",
                          },
                        });
                        if (
                          metadataSortTerm.column !== headCell.column ||
                          metadataSortTerm.value !== "desc"
                        ) {
                          props.handleSort(headCell?.column, "desc");
                        }
                      }}
                    >
                      <img
                        className="sort-icon"
                        src="./icons/icn_downarrow.svg"
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(
    0,
    (1 + page) * rowsPerPage - searchByPersonaResponse?.length
  );

  const handleHide = (id, isHideRow) => {
    const value = isHideRow === 0 ? 1 : 0;
    setIsHideContent(!isHideContent);
    dispatch(showOrHideContentMetadata({ Id: id, newValue: value }));
  };

  const handlePrevPage = () => {
    setFromPage(Math.max(0, fromPage - takePage));
  };

  const handleNextPage = () => {
    setFromPage(fromPage + takePage);
  };

  return (
    <Box className="table-box">
      {isMetadatLoading ? (
        <ResponseLoader
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "13%",
          }}
          loadingText="  Loading..."
        />
      ) : (
        <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
          <TableContainer>
            <Table
              sx={{ width: "95%", marginLeft: "2rem", marginRight: "2rem" }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead />

              {searchByPersonaResponse?.length > 0 && (
                <TableBody>
                  {searchByPersonaResponse.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">{row.contentId}</TableCell>
                        <TableCell align="left">{row.contentType}</TableCell>
                        <TableCell align="left">{row.contentName}</TableCell>
                        <TableCell align="left">{row.workspaceName}</TableCell>
                        <TableCell align="left">
                          {row.contentPublishedDate
                            ? Moment(row.contentPublishedDate).format(
                                "Do MMMM YYYY"
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          <span className="persona-label">{row.persona}</span>
                        </TableCell>
                        <TableCell align="center">
                          {
                            <Box sx={{ display: "flex" }}>
                              <IconButton
                                onClick={() => handleEditBtn(row.id.toString())}
                                size="small"
                              >
                                <img
                                  style={{ width: "20px" }}
                                  src={"/icons/icn_edit.svg"}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => handleHide(row.id, row.isHide)}
                                size="small"
                              >
                                {row?.isHide ? (
                                  <img
                                    style={{ width: "20px" }}
                                    src={"/icons/icn_close_eye.svg"}
                                  />
                                ) : (
                                  <img
                                    style={{ width: "20px" }}
                                    src={"/icons/icn_open_eye.svg"}
                                  />
                                )}
                              </IconButton>
                            </Box>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 20 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {totalCount === 0 && (
            <Box className="no-report-found">{NO_REPORT_FOUND}</Box>
          )}
          {totalCount > 0 && (
            <Box className="meatadata-pagination">
              <IconButton disabled={fromPage === 0} onClick={handlePrevPage}>
                <img src="./icons/icn_left_arrow.svg" />
              </IconButton>
              <span>Page </span>
              <div className="meatadata-pagination-box">
                {Math.round(fromPage / takePage) + 1}
              </div>
              <span> of {Math.ceil(totalCount / rowsPerPage)}</span>
              <IconButton
                disabled={fromPage + takePage >= totalCount}
                onClick={handleNextPage}
              >
                <img src="./icons/icn_right_arrow.svg" />
              </IconButton>
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
}
