export const SET_LOADER_STATUS = "SET_LOADER_STATUS";
export const SET_MAIN_DRAWER = "SET_MAIN_DRAWER";

//User details
export const SET_USERNAME_AND_USERID = "SET_USERNAME_AND_USERID";
export const GET_USER_TYPE = "GET_USER_TYPE";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const GET_USER_GROUPIDS = "GET_USER_GROUPIDS";
export const SET_USER_GROUPIDS = "SET_USER_GROUPIDS";
export const GET_USER_AVATAR = "GET_USER_AVATAR";
export const SET_USER_AVATAR = "SET_USER_AVATAR";

//Reports
export const GET_ALL_REPORTS = "GET_ALL_REPORTS";
export const SET_ALL_REPORTS = "SET_ALL_REPORTS";
export const GET_REPORT_DETAILS = "GET_REPORT_DETAILS";
export const SET_REPORT_DETAILS = "SET_REPORT_DETAILS";
export const SET_DASH_CONTENT_DETAILS = "SET_DASH_CONTENT_DETAILS";
export const SET_TAB_INDEX = "SET_TAB_INDEX";
export const SET_CONTENT_STATUS = 'SET_CONTENT_STATUS';
export const IS_DASHBOARD_RESULTS_LOADING = 'IS_DASHBOARD_RESULTS_LOADING';

//Report actions
export const SET_FILTER_DRAWER = "SET_FILTER_DRAWER";
export const SHARE_REPORT_VIA_MAIL = "SHARE_REPORT_VIA_MAIL";

export const GET_FILTER_OPTIONS = "GET_FILTER_OPTIONS";
export const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";

export const GET_CHECKED_FILTER = "GET_CHECKED_FILTER";
export const SET_CHECKED_FILTER = "SET_CHECKED_FILTER";

export const SET_REPORT_FEEDBACK = "SET_REPORT_FEEDBACK";
export const SET_REPORT_RATING = "SET_REPORT_RATING";
export const SET_ERROR_MODAL = "SET_ERROR_MODAL";

export const IS_REPORT_UPDATED = "IS_REPORT_UPDATED";

export const MARK_REPORT_LIKE_FAVOURITE = "MARK_REPORT_LIKE_FAVOURITE";

export const UPLOAD_REPORT_THUMBNAIL_IMAGE = "UPLOAD_REPORT_THUMBNAIL_IMAGE";
export const UPLOAD_THUMBNAIL_STATUS = "UPLOAD_THUMBNAIL_STATUS";

//email
export const EMAIL_SENT_STATUS = "EMAIL_SENT_STATUS";

// feedback
export const FEEDBACK_SENT_STATUS = "FEEDBACK_SENT_STATUS";

//Search
export const GET_KEYWORD_LIST = "GET_KEYWORD_LIST";
export const SET_KEYWORD_LIST = "SET_KEYWORD_LIST";
export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const GET_SEARCH_TERM = "GET_SEARCH_TERM";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_REPORT_FILTER = "SET_REPORT_FILTER";
export const SET_APP_FILTER = "SET_APP_FILTER";

// user Group Management

export const SET_META_DRAWER = "SET_META_DRAWER";
export const SET_EDIT_META_DRAWER = "SET_EDIT_META_DRAWER";

export const GET_ALL_PERSONA = "GET_ALL_PERSONA";
export const SET_ALL_PERSONA = "SET_ALL_PERSONA";
export const GET_ALL_REPORT_METADATA = "GET_ALL_REPORT_METADATA";
export const SET_ALL_REPORT_METADATA = "SET_ALL_REPORT_METADATA";
export const GET_ALL_REPORT_METADATA_WITH_SEARCH =
  "GET_ALL_REPORT_METADATA_WITH_SEARCH";

export const GET_REPORT_METADATA = "GET_REPORT_METADATA";
export const SET_REPORT_METADATA = "SET_REPORT_METADATA";
export const UPDATE_REPORT_METADATA = "UPDATE_REPORT_METADATA";
export const REPORT_METADATA_EDIT_STATUS = "REPORT_METADATA_EDIT_STATUS";
export const UPLOAD_REPORT_METADATA_EXCEL_STATUS =
  "UPLOAD_REPORT_METADATA_EXCEL_STATUS";
export const UPLOAD_REPORT_METADATA_EXCEL_ERROR =
  "UPLOAD_REPORT_METADATA_EXCEL_ERROR";
export const SET_META_SORT_TERM = "SET_META_SORT_TERM";
export const IS_META_UPDATED = "IS_META_UPDATED";
export const IS_METADATA_LOADING = "IS_METADATA_LOADING";

export const GET_ALL_REPORT_METRICS = "GET_ALL_REPORT_METRICS";
export const SET_ALL_REPORT_METRICS = "SET_ALL_REPORT_METRICS";
export const GET_ALL_REPORT_METRICS_DETAIL = "GET_ALL_REPORT_METRICS_DETAIL";
export const SET_ALL_REPORT_METRICS_DETAIL = "SET_ALL_REPORT_METRICS_DETAIL";
export const GET_ALL_REPORT_METRICS_WITH_SEARCH = "GET_ALL_REPORT_METRICS_WITH_SEARCH";
export const SET_FROM_PAGE = 'SET_FROM_PAGE';
export const IS_METRICS_LOADING = "IS_METRICS_LOADING";

export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const SET_ALL_GROUPS = "SET_ALL_GROUPS";
export const GET_PERSONAGROUP_MAPPINGS = "GET_PERSONAGROUP_MAPPINGS";
export const SET_PERSONAGROUP_MAPPINGS = "SET_PERSONAGROUP_MAPPINGS";

export const GET_GROUP_REORDER = "GET_GROUP_REORDER";
export const SET_GROUP_REORDER = "SET_GROUP_REORDER";
export const UPDATE_GROUP_REORDER = "UPDATE_GROUP_REORDER";
export const UPDATE_GROUP_REORDER_STATUS = "UPDATE_GROUP_REORDER_STATUS";

export const SET_USER_MGMT_SEARCH_VALUE = "SET_USER_MGMT_SEARCH_VALUE";
export const SET_USER_MGMT_FILTER_VALUE = "SET_USER_MGMT_FILTER_VALUE";
export const SET_IS_HIDE_VALUE = 'SET_IS_HIDE_VALUE';
export const UPDATE_IS_HIDE_VALUE = 'UPDATE_IS_HIDE_VALUE';
export const GET_ALL_DOWNLOAD_CONTENT = 'GET_ALL_DOWNLOAD_CONTENT';
export const SET_ALL_DOWNLOAD_CONTENT = 'SET_ALL_DOWNLOAD_CONTENT';
export const SEARCH_RESPONSE_STATUS = 'SEARCH_RESPONSE_STATUS';
export const SEARCH_METRICS_RESPONSE_STATUS = 'SEARCH_METRICS_RESPONSE_STATUS';

//Upload file
export const UPLOAD_FILENAME = "UPLOAD_FILENAME";
