// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportsGroupHeader img {
  height: 33px;
}

.reportsGroupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;
  margin-bottom: 0px;
}

.list-no-report {
  color: #000;
  text-align: center;
  margin-top: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/components/report-list/ReportList.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".reportsGroupHeader img {\n  height: 33px;\n}\n\n.reportsGroupHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 1.2rem;\n  margin-bottom: 0px;\n}\n\n.list-no-report {\n  color: #000;\n  text-align: center;\n  margin-top: 6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
