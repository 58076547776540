
//WITH ENV
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const MS_GRAPH_BASE_URL = 'https://graph.microsoft.com/v1.0/';
export const DMS_URL = process.env.REACT_APP_DMS_URL;
export const VIDI_URL = process.env.REACT_APP_VIDI_URL;

export const APP_REDIRECTION_URL = process.env.REACT_APP_APP_REDIRECTION_URL;
export const AUTH_CONFIG = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    tenantId: "d14c9c9a-6bb5-430f-99ff-6c2815b3a95e",
    // tenantId: "https://login.microsoftonline.com/d14c9c9a-6bb5-430f-99ff-6c2815b3a95e",
    scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
};
export const STORAGE_ACCOUNT_NAME = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
export const SAS_TOKEN = process.env.REACT_APP_SAS_TOKEN;
export const STORAGE_CONTAINER = process.env.REACT_APP_STORAGE_CONTAINER;


