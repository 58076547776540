import React, { useEffect, useState, useRef } from "react";
import Moment from "moment";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Paper,
  InputBase,
  FormControl,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import {
  IS_META_UPDATED,
  IS_REPORT_UPDATED,
  REPORT_METADATA_EDIT_STATUS,
  SET_EDIT_META_DRAWER,
  SET_ERROR_MODAL,
  SET_LOADER_STATUS,
} from "../../redux/constants";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import "./metaDrawer.css";
import { Stack } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { updateReportMetadata, uploadReportThumbnailImagename } from "../../redux/actions";
import { DownloadLoader } from "../loader";
import { generateFilename, uploadFileToStorage, validateFileType } from "../../common";
import { UPLOAD_FILE_PAGE } from "../../constants/constants";

const MetaDataEditDrawer = () => {
  //------------ Map / List data ------------

  const metaData = useSelector(
    (state) => state.userGroupManagement.reportMetadataForEdit
  );
  const { isMetaEditDrawerOpen, metadataEditStatus } = useSelector(
    (state) => state.userGroupManagement
  );

  const { filterList } = useSelector((state) => state.report);
  const isLoading = useSelector((state) => state.app.isLoading);

  const dispatch = useDispatch();

  const [taggedWord, setTaggedWord] = useState();
  const [keyReport, setKeyReport] = useState();
  const [addedTaggedWords, setAddedTaggedWords] = useState([]);
  const [file, setFile] = useState();
  const fileInput = useRef(null);

  const handleDeleteTaggedWord = (tagWord) => {
    setAddedTaggedWords(addedTaggedWords.filter((item) => item !== tagWord));
  };

  const UserCard = ({ type, reportOwner, reportOwnerEmail }) => (
    <Box>
      <Typography variant="body1">
        {type} <b>:</b>
      </Typography>
      <Box className="user-card">
        <Typography sx={{ fontWeight: "600" }}>{reportOwner}</Typography>
        <Typography variant="body1">{reportOwnerEmail}</Typography>
      </Box>
    </Box>
  );

  //------------ Update / Save data ------------

  const [state, setState] = useState({
    report: metaData?.contentName,
    reportDescription: metaData?.contentDescription,
    workspace: metaData?.workspace,
    workSpaceID: metaData?.workSpaceID,
    imageSource: metaData?.imageSource,
    rankOrder: metaData?.rankOrder,
  });
  const { report, reportDescription, workspace, workSpaceID, imageSource, rankOrder } = state;

  const [persona, setPersona] = useState([]);
  const [theme, setTheme] = useState([]);

  useEffect(() => {
    if (metaData) {
      setState({
        report: metaData.contentName,
        reportDescription: metaData.contentDescription,
        workspace: metaData.workspace,
        workSpaceID: metaData.workSpaceID,
        imageSource: metaData.imageSource,
        rankOrder: metaData?.rankOrder,
      });

      setPersona(metaData?.persona?.split(",") || []);
      setTheme(metaData.theme || []);
      setAddedTaggedWords(metaData.taggedWords);
      setKeyReport(metaData.keyContent);
    }
  }, [metaData]);

  useEffect(() => {
    if (metadataEditStatus) {
      dispatch({ type: SET_LOADER_STATUS, payload: false });
      metadataEditStatus === "success" &&
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Data updated successfully",
            modalTitle: "Success",
          },
        });
      metadataEditStatus === "failed" &&
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Data update failed",
            modalTitle: "Failed",
          },
        });
      dispatch({ type: REPORT_METADATA_EDIT_STATUS, payload: null }); //reset status
      dispatch({ type: IS_META_UPDATED, payload: true });
      dispatch({ type: SET_EDIT_META_DRAWER, payload: false });
    }
    // eslint-disable-next-line
  }, [metadataEditStatus]);

  async function handleImageChange(e) {
    let file = e.target.files[0];
    if(file){
      const validFile = await validateFileType(UPLOAD_FILE_PAGE.thumbnail, file.name)
      if (!validFile) {
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Invalid file, please select a file with .png / .jpeg / .jpg extension",
            modalTitle: "Alert!",
          },
        });
        setFile(null);
      }
      else {
        setFile(file);
        setState({ ...state, imageSource: URL.createObjectURL(file) });
      }
    }
  }

  const handlePersonaChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersona(typeof value === "string" ? value.split(",") : value);
  };

  const handleThemeChange = (event) => {
    const {
      target: { value },
    } = event;
    setTheme(typeof value === "string" ? value.split(",") : value);
  };

  const handleOnChangeKeyReport = (event) => {
    if (event.target.checked) {
      setKeyReport("Y");
    } else {
      setKeyReport("N");
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const onSubmitHandler = async () => {
    let validFields = true;
    const payload = {
      contentName: report,
      contentDescription: reportDescription,
      taggedWords: addedTaggedWords.join(),
      theme: theme.join(),
      workspace: workspace,
      workSpaceID: workSpaceID,
      persona: persona.join(),
      keyContent: keyReport,
      rankOrder: rankOrder,
    };
    Object.keys(payload).forEach((key) => {
      if (
        (Array.isArray(payload[`${key}`]) && payload[`${key}`].length === 0) ||
        !payload[`${key}`]
      ) {
        validFields = false;
        dispatch({
          type: SET_ERROR_MODAL,
          payload: { status: true, errorMessage: `${key} is required!` },
        });
      }
    });
    if (validFields) {
      dispatch({ type: SET_LOADER_STATUS, payload: true });
      let reqPayload = { ...JSON.parse(JSON.stringify(metaData)), ...payload };
      if(metaData.imageSource === imageSource){
        dispatch(updateReportMetadata(reqPayload));
      }
      else{
        try {
          let fileName = generateFilename(file);
          let response = await uploadFileToStorage(file, fileName);
          if (response?._response?.status === 201) {
            dispatch(uploadReportThumbnailImagename({ reportID: metaData.contentID, imageSource: fileName }));
            dispatch(updateReportMetadata(reqPayload));
          }
          else {  showErrorAlert(); }
        }
        catch(e){
          showErrorAlert();          
        }
      }
      dispatch({ type: IS_REPORT_UPDATED, payload: true });
    }
  };

  function showErrorAlert() {
    dispatch({ type: SET_LOADER_STATUS, payload: false });
    dispatch({
      type: SET_ERROR_MODAL,
      payload: {
        status: true,
        errorMessage: "Image upload failed. Please try after sometime",
        modalTitle: "Alert!",
      },
    });
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={isMetaEditDrawerOpen}
        onClose={() => dispatch({ type: SET_EDIT_META_DRAWER, payload: false })}
      >
        <Box className="edit-drawer">
          <Box className="metaDrawer" role="presentation">
            <Box className="metaHeader">
              <Typography variant="headingTwo" sx={{ fontWeight: "normal" }}>
                Metadata
              </Typography>
              <IconButton
                onClick={() =>
                  dispatch({ type: SET_EDIT_META_DRAWER, payload: false })
                }
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>

            <Box className="edit-drawer-form">
              <Typography variant="h6">Content Name</Typography>
              <TextField
                name="report"
                onChange={handleOnChange}
                placeholder="Content Name"
                value={state.report || ""}
                fullWidth
                variant="outlined"
              />

              <Typography variant="h6">Content ID</Typography>
              <Typography variant="body1">{metaData?.contentID}</Typography>

              <Typography variant="h6">Content Description</Typography>
              <TextField
                name="reportDescription"
                onChange={handleOnChange}
                value={state.reportDescription || ""}
                fullWidth
                placeholder="Description"
                multiline
                rows={3}
                variant="outlined"
              />

              <Typography variant="h6">Published</Typography>
              <Typography variant="body1">
                {metaData?.contentPublishedDate
                  ? Moment(metaData.contentPublishedDate).format("Do MMMM YYYY")
                  : "-"}
              </Typography>

              <Typography variant="h6">Content Owner</Typography>
              <UserCard
                type="Primary"
                reportOwner={metaData?.contentOwner}
                reportOwnerEmail={metaData?.contentOwnerEmail}
              />
              <UserCard
                type="Secondary"
                reportOwner={metaData?.secondaryOwner}
                reportOwnerEmail={metaData?.secondaryOwnerEmail}
              />

              {/* <Typography sx={{ marginTop: "1rem" }} variant="h6">
                Content Developer
              </Typography>
              <UserCard
                type="Primary"
                reportOwner={metaData?.reportDeveloper || ''}
                reportOwnerEmail={metaData?.contentDeveloperEmailID}
              />
              <UserCard
                type="Secondary"
                reportOwner={metaData?.secondaryDeveloper}
                reportOwnerEmail={metaData?.secondaryDeveloperEmailID}
              /> */}

              <Typography variant="h6">Tagged Words</Typography>
              <Paper className="tagged-word" component="form">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Type to add keyword"
                  inputProps={{ "aria-label": "Type to add keyword" }}
                  value={taggedWord || ""}
                  onChange={(event) => setTaggedWord(event.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "8px" }}
                  aria-label="search"
                  onClick={() => {
                    if (taggedWord) {
                      setAddedTaggedWords([...addedTaggedWords, taggedWord]);
                      setTaggedWord("");
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Paper>
              <Box className="tagged-words">
                {addedTaggedWords?.map((tagWord, index) => (
                  <div key={index} className="tagged-label">
                    <span>{tagWord}</span>
                    <CloseIcon
                      sx={{ color: "#52495C" }}
                      onClick={() => handleDeleteTaggedWord(tagWord)}
                    />
                  </div>
                ))}
              </Box>

              <Typography variant="h6">Theme</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="Theme"
                  id="theme"
                  multiple
                  value={theme}
                  onChange={handleThemeChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {filterList.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={theme.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography variant="h6">Workspace</Typography>
              <TextField
                name="workspace"
                onChange={handleOnChange}
                placeholder="Workspace"
                value={state.workspace || ""}
                fullWidth
                variant="outlined"
              />

              <Typography variant="h6">Workspace ID</Typography>
              <TextField
                name="workSpaceID"
                onChange={handleOnChange}
                placeholder="WorkspaceID"
                value={state.workSpaceID || ""}
                fullWidth
                variant="outlined"
              />

              <Typography variant="h6">Persona</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="Persona"
                  id="persona"
                  value={persona}
                  onChange={handlePersonaChange}
                  input={<OutlinedInput />}
                  // renderValue={(selected) => selected}
                >
                  {metaData?.allowedPersonas?.map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography variant="h6">Rank order</Typography>
              <TextField
                name="rankOrder"
                onChange={handleOnChange}
                placeholder="Rank order"
                value={state.rankOrder || ""}
                fullWidth
                variant="outlined"
                type="number"
              />

              <Typography variant="h6">Thumbnail</Typography>
            <img className="report-img" src={imageSource ||  "/images/report-1.jpg"}
            />
              <div className="thumbnail-div">
              <Button color="warning" variant="contained" size="small" sx={{fontSize:"0.7rem", textTransform:"none"}}
              onClick={() => fileInput.current.click()}>
                Choose Image
              </Button>
              <input
                type="file"
                id="update_thumbnail"
                ref={fileInput}
                onChange={handleImageChange}
                accept="image/x-png,image/jpg,image/jpeg"
                style={{display:"none"}}
              />
              </div>

              <FormControlLabel
                sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                control={
                  <Checkbox
                    name="keyReport"
                    onChange={(event) => handleOnChangeKeyReport(event)}
                    checked={keyReport === "N" ? false : true}
                  />
                }
                label="This is a key content for this persona"
              />
            </Box>

            <Stack direction="row" spacing={2} className="metaDrawerFooter">
              <Button
                onClick={() =>
                  dispatch({ type: SET_EDIT_META_DRAWER, payload: false })
                }
                size="small"
                color="secondary"
                variant="contained"
              >
                CANCEL
              </Button>
              <Button
                onClick={onSubmitHandler}
                size="small"
                color="primary"
                variant="contained"
              >
                {isLoading ? (
                  <DownloadLoader
                    style={{ height: "24px !important" }}
                    loadingText="Loading..."
                  />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default MetaDataEditDrawer;
