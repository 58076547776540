import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import './mainTile.css'

const MainTile = (props) => {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '10px',
    height: '320px',
    position: 'relative',
    maxWidth: '240px'
  }));

  return (
    <Link to={props.link} target={props.newTab == true ? "_blank" : undefined}>
      <Item>
        <div style={{ backgroundColor: 'rgb(212 212 212 / 19%)', borderRadius: '50%', height: '80px', width: '80px', margin: '30px auto' }}>
          <img style={{ width: '40px' }} src={props.src} alt='tile_icon' />
        </div>
        <Typography variant='body1' sx={{ fontWeight: 'bold', margin: '40px 0 20px 0', color: "black", fontSize: '0.8rem' }}>{props.title}</Typography>
        <Typography variant='body2' sx={{ color: "#657786", marginBottom: '10px', fontSize: '0.7rem' }}>{props.content}</Typography>
        <img className='right-arrow' src='./images/right_arrow.png' />
      </Item>
    </Link>
  )
}

export default MainTile