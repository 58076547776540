import React, { useState, useEffect } from "react";
import "./reportMetrics.css";
import AdminToolHeader from "../../components/adminToolHeader";
import { USER_MANAGEMENT } from "../../constants/constants";
import ReportMetricsTable from "./reportMetricsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getallReportMetrics,
  getallReportMetricsSearch,
} from "../../redux/actions";
import { IS_META_UPDATED, IS_METRICS_LOADING } from "../../redux/constants";

const { reportMetrics } = USER_MANAGEMENT;

const ReportMetrics = () => {
  const dispatch = useDispatch();

  const [fromPage, setFromPage] = useState(0);
  const [takePage, setTakePage] = useState(15);
  const [totalRows, setTotalRows] = useState(0);
  const [payloadData, setPayloadData] = useState();
  const [urlPramsData, setUrlParamsData] = useState();

  let pagination = {};
  pagination["fromPage"] = fromPage;
  pagination["takePage"] = takePage;

  const { searchValue, isMetadataUpdated, metadataSortTerm } = useSelector(
    (state) => state.userGroupManagement
  );

  useEffect(() => {
    if (isMetadataUpdated) {
      setTimeout(() => {
        handleSort(metadataSortTerm.column, metadataSortTerm.value);
        dispatch({ type: IS_META_UPDATED, payload: false });
      }, 1000);
    }
  }, [isMetadataUpdated]);

  const reportMetricsWithSearchFilter = (search = searchValue) => {
    searchFilterSortAction({ search });
  };
  const handleSort = (sortColumn, sortValue) => {
    searchFilterSortAction({
      search: searchValue,
      sortColumn,
      sortValue,
    });
  };
  function searchFilterSortAction({ filter, search, sortColumn, sortValue }) {
    let urlparams = "";
    if (sortColumn) {
      urlparams = `&sortColumn=${sortColumn}&sortOrder=${sortValue || "asc"}`;
      setUrlParamsData(urlparams);
    }
    let searchTeam = "";
    searchTeam = search ? `/${search}` : "";
    setPayloadData(searchTeam);
    setFromPage(0);
    dispatch(
      getallReportMetricsSearch({
        searchString: searchTeam,
        urlParams: urlparams,
        pagination,
      })
    );
    dispatch({ type: IS_METRICS_LOADING, payload: true });
  }
  return (
    <div>
      <AdminToolHeader
        pageName={reportMetrics.pageName}
        searchLabel={reportMetrics.searchLabel}
        showSearch={true}
        handleChange={reportMetricsWithSearchFilter}
      />
      <ReportMetricsTable
        handleSort={handleSort}
        fromPage={fromPage}
        setFromPage={setFromPage}
        takePage={takePage}
        setTakePage={setTakePage}
        totalRows={totalRows}
        setTotalRows={setTotalRows}
        searchValue={searchValue}
        pagination={pagination}
        urlPramsData={urlPramsData}
        payloadData={payloadData}
        setUrlParamsData={setUrlParamsData}
      />
    </div>
  );
};

export default ReportMetrics;
