import React, { useState, useEffect, useCallback } from "react";
import "./manageGroupOrder.css";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-drag-reorder";
import AdminToolHeader from "../../components/adminToolHeader";
import { NO_GROUP_FOUND, USER_MANAGEMENT } from "../../constants/constants";
import { getGroupsOrder, updateGroupReorder } from "../../redux/actions";
import { Box, Button } from "@mui/material";
import {
  SET_ERROR_MODAL,
  SET_LOADER_STATUS,
  UPDATE_GROUP_REORDER_STATUS,
} from "../../redux/constants";
import { DownloadLoader } from "../../components/loader";

const { groupReorder } = USER_MANAGEMENT;

const ManageGroupOrder = () => {
  const dispatch = useDispatch();

  const [orderItems, setOrderItems] = useState([]);
  const [items, setItems] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const { reportGroups, groupReorderStatus } = useSelector(
    (state) => state.userGroupManagement
  );
  const { isLoading } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(getGroupsOrder());
  }, []);

  useEffect(() => {
    if (reportGroups?.length) {
      const groups = reportGroups.map((item) => item.groupName);
      setItems(groups);
      setOrderItems(JSON.parse(JSON.stringify(groups)));
    }
  }, [reportGroups]);

  useEffect(() => {
    if (groupReorderStatus) {
      dispatch({ type: SET_LOADER_STATUS, payload: false });
      if (groupReorderStatus === "success") {
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Group reorder saved successfully",
            modalTitle: "Success",
          },
        });
        setSaveDisabled(true);
      }
      groupReorderStatus === "failed" &&
        dispatch({
          type: SET_ERROR_MODAL,
          payload: {
            status: true,
            errorMessage: "Group reorder failed",
            modalTitle: "Failed",
          },
        });
      dispatch({ type: UPDATE_GROUP_REORDER_STATUS, payload: null }); //reset status
    }
  }, [groupReorderStatus]);

  const handleSave = () => {
    if (!isLoading) {
      // prevent this func call when loading
      dispatch({ type: SET_LOADER_STATUS, payload: true });
      dispatch(updateGroupReorder({ groupNameList: items }));
    }
  };

  const handleCancel = () => {
    setItems(orderItems);
    setOrderItems(JSON.parse(JSON.stringify(orderItems)));
    setSaveDisabled(true);
  };

  const getChangedPos = (currentPos, newPos) => {
    setItems((item) => arrayMove(item, currentPos, newPos));
    setSaveDisabled(false);
  };

  function arrayMove(array, fromIndex, toIndex) {
    const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

    if (startIndex >= 0 && startIndex < array.length) {
      const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

      const [item] = array.splice(fromIndex, 1);
      array.splice(endIndex, 0, item);
    }
    return array;
  }

  const DraggableRender = useCallback(() => {
    if (items && items.length) {
      return (
        <Draggable
          key="drag-id"
          className="draggable-div"
          onPosChange={getChangedPos}
        >
          {items.map((item, index) => (
            <div key={index} className="list-item">
              <img className="drag-img" src={"/icons/icn_re-arrange.svg"} />
              {item}
            </div>
          ))}
        </Draggable>
      );
    }
    return null;
  }, [items]);

  return (
    <div>
      <AdminToolHeader
        pageName={groupReorder.pageName}
        description={groupReorder.pageDescription}
      />
      {items?.length > 0 && (
        <div className="save-cancel-div">
          <Button
            sx={{ marginRight: "1rem" }}
            variant="contained"
            size="small"
            onClick={handleCancel}
            color="warning"
          >
            Cancel
          </Button>
          <Button
            disabled={saveDisabled}
            onClick={handleSave}
            variant="contained"
            size="small"
          >
            {isLoading ? (
              <DownloadLoader
                style={{ height: "24px !important" }}
                loadingText="Loading..."
              />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      )}
      {reportGroups?.length === 0 && (
        <Box className="no-group-found">{NO_GROUP_FOUND}</Box>
      )}
      <DraggableRender />
    </div>
  );
};

export default ManageGroupOrder;
