import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider, Grid, Typography } from '@mui/material'

import Header from '../../components/header';
import { NO_REPORT_FOUND } from '../../constants/constants'
import ReportTile from '../../components/report-tile';
import './reportList.css';

const AllSearchReports = () => {

    const { searchFilteredReports } = useSelector(state => state.search);

    return (
        <Box component="main" className='all-reports' sx={{ flexGrow: 1, paddingBottom: '2rem' }}>
            <Header />
            <Box sx={{ m: '2rem' }}>
                <Typography variant='headingTwo' sx={{ pb: '1rem' }}>Reports {`(${searchFilteredReports?.length})`}</Typography>
                <Divider sx={{ marginBottom: '0.8rem' }} />
                {
                    searchFilteredReports.length === 0 ?
                        <Box className='no-report'>{NO_REPORT_FOUND}</Box>
                        :
                        <Grid container spacing={2}>
                            {searchFilteredReports.map((report, index) => {
                                return <Grid item xs={6} md={2} sm={4} key={index}>
                                    <ReportTile content={report} />
                                </Grid>
                            })}
                        </Grid>
                }
            </Box>
        </Box>
    )
}

export default AllSearchReports