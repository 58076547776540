import { takeEvery, put } from "redux-saga/effects";
import {
  GET_ALL_REPORTS,
  GET_REPORT_DETAILS,
  SET_ALL_REPORTS,
  SET_REPORT_DETAILS,
  MARK_REPORT_LIKE_FAVOURITE,
  SET_FILTER_OPTIONS,
  GET_FILTER_OPTIONS,
  SHARE_REPORT_VIA_MAIL,
  SET_REPORT_FEEDBACK,
  SET_REPORT_RATING,
  UPLOAD_FILENAME,
  EMAIL_SENT_STATUS,
  UPLOAD_REPORT_METADATA_EXCEL_STATUS,
  UPLOAD_REPORT_METADATA_EXCEL_ERROR,
  UPLOAD_REPORT_THUMBNAIL_IMAGE,
  UPLOAD_THUMBNAIL_STATUS,
  FEEDBACK_SENT_STATUS,
  SET_CONTENT_STATUS,
  IS_METADATA_LOADING,
  IS_DASHBOARD_RESULTS_LOADING,
} from "../constants";
import AxiosInstance from "../../services/api/AxiosInstance";

// allReportSaga ------------------------------
function* getAllReport(payload) {
  try {
    const response = yield AxiosInstance.post(
      "/dashboardservice/api/dashboard",
      payload
    );
    const data = yield response.data;
    yield put({ type: SET_ALL_REPORTS, payload: data });
    if (data) {
      yield put({ type: IS_DASHBOARD_RESULTS_LOADING, payload: false });
    }
  } catch (error) {
    console.log("allReports error", error);
    yield put({ type: IS_DASHBOARD_RESULTS_LOADING, payload: false });
  }
}
export function* allReportSaga() {
  yield takeEvery(GET_ALL_REPORTS, (action) => getAllReport(action.payload));
}

// reportDetailsSaga ------------------------------
function* getReportDetails(payload) {
  try {
    const response = yield AxiosInstance.post(
      "metadataservice/api/Report/GetDetailsByReportID",
      payload
    );
    const data = yield response.data;
    yield put({ type: SET_REPORT_DETAILS, payload: data });
    if (response.status === 204) {
      yield put({ type: SET_CONTENT_STATUS, payload: true });
    } else {
      yield put({ type: SET_CONTENT_STATUS, payload: false });
    }
  } catch (error) {
    console.log("getReportDetails error", error);
  }
}

export function* reportDetailsSaga() {
  yield takeEvery(GET_REPORT_DETAILS, (action) =>
    getReportDetails(action.payload)
  );
}

// filterOptionsSaga ------------------------------
function* getFilterOptions() {
  try {
    const response = yield AxiosInstance.post(
      `/reportsearchservice/api/Search/GetAllThemes`
    );
    const data = yield response.data;
    yield put({ type: SET_FILTER_OPTIONS, payload: data });
  } catch (error) {
    console.log("getFilterOptions error", error);
  }
}

// report Like Favorite ------------------------------
function* markReportLikeFavourite(payload) {
  const response = yield AxiosInstance.post(
    `/likefavouriteservice/api/likefavourite/InsertLikeFavourite`,
    payload
  );
  const data = yield response.data;
}

// share report via mail ------------------------------
function* shareReportviaMail(shareReportPayload) {
  try {
    const response = yield AxiosInstance.post(
      `/emailservice/API/EmailService/SendEmail`,
      shareReportPayload
    );
    const data = yield response.data;
    yield put({ type: EMAIL_SENT_STATUS, payload: "success" });
  } catch (error) {
    console.log("shareReportviaMail error", error);
    yield put({ type: EMAIL_SENT_STATUS, payload: "failed" });
  }
}

function* setReportFeedback(payload) {
  try {
    const response = yield AxiosInstance.post(
      "feedbackservice/api/feedback",
      payload
    );
    const data = yield response.data;
    yield put({ type: FEEDBACK_SENT_STATUS, payload: "success" });
    console.log("setReportFeedback data", data);
  } catch (error) {
    yield put({ type: FEEDBACK_SENT_STATUS, payload: "failed" });
    console.log("setReportFeedback error", error);
  }
}

function* setReportRating(data) {
  const jsonData = JSON.stringify(data);
  try {
    const response = yield AxiosInstance.post(
      "reportrateservice/api/ReportRate",
      jsonData
    );

    console.log("setReportRating data", response.data);
  } catch (error) {
    console.log("setReportRating error", error);
  }
}

function* uploadFilename(filename) {
  try {
    const response = yield AxiosInstance.post(
      `metadataservice/api/Report/InsertMetadata?fileName=${decodeURIComponent(
        filename
      )}`
    );
    const data = yield response.data;
    console.log("uploadFilename data", data);
    yield put({
      type: UPLOAD_REPORT_METADATA_EXCEL_STATUS,
      payload: "success",
    });
  } catch (error) {
    console.log("uploadFilename error", error);
    yield put({ type: UPLOAD_REPORT_METADATA_EXCEL_STATUS, payload: "failed" });
    let errorMsg = "";
    if (error.response && error.response.data && error.response.data.length) {
      errorMsg = error.response.data;
    }
    yield put({ type: UPLOAD_REPORT_METADATA_EXCEL_ERROR, payload: errorMsg });
  }
}

function* uploadReportThumbnailImagename(payload) {
  try {
    const response = yield AxiosInstance.post(
      `metadataservice/api/Report/UpdateReportImageSource`,
      payload
    );
    const data = yield response.data;
    console.log("uploadReportThumbnailImagename data", data);
    yield put({ type: UPLOAD_THUMBNAIL_STATUS, payload: "success" });
  } catch (error) {
    console.log("uploadReportThumbnailImagename error", error);
    yield put({ type: UPLOAD_THUMBNAIL_STATUS, payload: "failed" });
  }
}

export function* reportActionsSaga() {
  yield takeEvery(GET_FILTER_OPTIONS, getFilterOptions);
  yield takeEvery(MARK_REPORT_LIKE_FAVOURITE, (action) =>
    markReportLikeFavourite(action.payload)
  );
  yield takeEvery(SHARE_REPORT_VIA_MAIL, (action) =>
    shareReportviaMail(action.payload)
  );
  yield takeEvery(SET_REPORT_FEEDBACK, (action) =>
    setReportFeedback(action.payload)
  );
  yield takeEvery(SET_REPORT_RATING, (action) =>
    setReportRating(action.payload)
  );
  yield takeEvery(UPLOAD_FILENAME, (action) => uploadFilename(action.payload));
  yield takeEvery(UPLOAD_REPORT_THUMBNAIL_IMAGE, (action) =>
    uploadReportThumbnailImagename(action.payload)
  );
}
