import {
  SET_KEYWORD_LIST,
  SET_SEARCH_RESULTS,
  SET_SEARCH_TERM,
  SET_REPORT_FILTER,
  SET_APP_FILTER
} from "../constants";

const initialState = {
  searchTerms: "",
  keywordList: [],
  searchReports: [],
  selectedSort: [],
  searchResults: [],
  searchFilteredReports: [],
  searchFilteredApps: [],
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_KEYWORD_LIST:
      return { ...state, keywordList: action.payload };
    case SET_SEARCH_TERM:
      return { ...state, searchTerms: action.payload };
    case SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload };
    case SET_REPORT_FILTER:
      return { ...state, searchFilteredReports: action.payload };
    case SET_APP_FILTER:
      return { ...state, searchFilteredApps: action.payload };
    default:
      return state;
  }
}
