// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draggable-div {
  width: 640px;
}

.list-item {
  display: flex;
  background-color: #f7f5f3;
  margin: 1rem;
  margin-left: 2rem;
  padding: 0.8rem;
  color: #444444;
  font-family: "Roboto Slab", serif;

  border: 1px solid #bfb5ab;
  border-radius: 8px;
  width: 640px;
  font-size: 13px;
}

.drag-img {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}

.save-cancel-div {
  display: flex;
  float: right;
  margin: 1rem;
}

.no-group-found {
  color: #000;
  text-align: center;
  margin-top: 4rem;
  font-family: "Roboto Slab", serif;

  font-weight: bold;
  font-size: 12px;
}

@media (max-width: 1024px) {
  .save-cancel-div {
    display: flex;
    float: right;
    margin: 1rem;
  }

  .draggable-div,
  .list-item {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/manage-group-order/manageGroupOrder.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,iCAAiC;;EAEjC,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,iCAAiC;;EAEjC,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE;IACE,aAAa;IACb,YAAY;IACZ,YAAY;EACd;;EAEA;;IAEE,UAAU;EACZ;AACF","sourcesContent":[".draggable-div {\n  width: 640px;\n}\n\n.list-item {\n  display: flex;\n  background-color: #f7f5f3;\n  margin: 1rem;\n  margin-left: 2rem;\n  padding: 0.8rem;\n  color: #444444;\n  font-family: \"Roboto Slab\", serif;\n\n  border: 1px solid #bfb5ab;\n  border-radius: 8px;\n  width: 640px;\n  font-size: 13px;\n}\n\n.drag-img {\n  width: 20px;\n  height: 20px;\n  margin-right: 1rem;\n}\n\n.save-cancel-div {\n  display: flex;\n  float: right;\n  margin: 1rem;\n}\n\n.no-group-found {\n  color: #000;\n  text-align: center;\n  margin-top: 4rem;\n  font-family: \"Roboto Slab\", serif;\n\n  font-weight: bold;\n  font-size: 12px;\n}\n\n@media (max-width: 1024px) {\n  .save-cancel-div {\n    display: flex;\n    float: right;\n    margin: 1rem;\n  }\n\n  .draggable-div,\n  .list-item {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
