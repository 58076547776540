import React, { useState, useEffect } from 'react';
import './drawer.css';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Divider } from '@mui/material';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { DrawerStyle } from './drawer.style';
import { DashboardSubMenu, VisualSubMenu, AdminSubMenu, DmsSubMenu,DataCubesSubMenu,ConsumerResearchSubMenu } from './subMenu';
import { Link } from 'react-router-dom';
import { MENU_NAME } from '../../constants/constants';
import { getUserInitials } from '../../common';
import { SET_MAIN_DRAWER } from '../../redux/constants';
import { useLocation } from 'react-router-dom';


const menuIconStyle = { width: "34px", height: "34px" };

export default function Drawer() {
  const user = useSelector(state => state.user);
  const [dashboardAnchor, setDashboardAnchor] = useState(null);
  const [visualAnchor, setVisualAnchor] = useState(null);
  const [decisionAnchor, setDecisionAnchor] = useState(null);
  const [dmsAnchor, setDmsAnchor] = useState(null);
  const [adminAnchor, setAdminAnchor] = useState(null);
  const [dataCubesAnchor, setDataCubesAnchor] = useState(null);
  const [consumerResearchAnchor, setConsumerResearchAnchor] = useState(null);

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const menuItemList = [
    { name: user?.userName, icon: user?.avatarImage ? <Avatar src={user.avatarImage} /> : <Avatar src={user?.avatarImage} >{user?.userName && getUserInitials(user.userName)}</Avatar>, visible: true },
    { name: MENU_NAME.knowledgeBase.title, icon: <img src="/icons/icn_power_bi.svg" style={menuIconStyle} />, divider: true, isMenu: true, visible: MENU_NAME.knowledgeBase.released },
    { name: MENU_NAME.visualDiscovery.title, icon: <img src="/icons/icn_visual.svg" style={menuIconStyle} />, isMenu: true, visible: MENU_NAME.visualDiscovery.released },
    { name: MENU_NAME.documentManagementSystem.title, icon: <img src="/icons/icn_dms.svg" style={menuIconStyle} />, isMenu: true, visible: MENU_NAME.documentManagementSystem.released },
    { name: MENU_NAME.dataCubes.title, icon: <img src="/icons/icn_dataCubes.png" style={menuIconStyle} />,  isMenu: true, visible: MENU_NAME.dataCubes.released },
    { name: MENU_NAME.consumerResearch.title, icon: <img src="/icons/icn_knowledgebase.svg" style={menuIconStyle} />,  isMenu: true, visible: MENU_NAME.consumerResearch.released },
  ]

  const [menuItems, setMenuItems] = useState(menuItemList);

  const open = useSelector(state => state.app.isMainDrawerOpen)
  const dispatch = useDispatch();

  const handleDrawer = () => {
    dispatch({ type: SET_MAIN_DRAWER, payload: !open })
  };

  const handleClick = (event, name) => {
    if (name === MENU_NAME.knowledgeBase.title) { setDashboardAnchor(event.currentTarget) }
    if (name === MENU_NAME.visualDiscovery.title) { setVisualAnchor(event.currentTarget) }
    if (name === MENU_NAME.documentManagementSystem.title) { setDmsAnchor(event.currentTarget) }
    if (name === MENU_NAME.dataCubes.title) { setDataCubesAnchor(event.currentTarget) }
    if (name === MENU_NAME.consumerResearch.title) { setConsumerResearchAnchor(event.currentTarget) }
    if (name === MENU_NAME.adminTools.title) { setAdminAnchor(event.currentTarget) }
  };

  const handleClose = () => {
    if (dashboardAnchor) { setDashboardAnchor(null) }
    if (visualAnchor) { setVisualAnchor(null) }
    if (decisionAnchor) { setDecisionAnchor(null) }
    if (dmsAnchor) { setDmsAnchor(null) }
    if (dataCubesAnchor) {setDataCubesAnchor(null) }
    if (consumerResearchAnchor) {setConsumerResearchAnchor(null) }
    if (adminAnchor) { setAdminAnchor(null) }
  };

  useEffect(() => {
    //add admin tool only if the user is admin
    if (user.isAdmin && !isHomePage) {
      const adminTool = menuItemList.find(menu => menu.name === MENU_NAME.adminTools.title);
      if (!adminTool) {
        menuItemList.push({ name: MENU_NAME.adminTools.title, icon: <img src="/icons/icn_settings.svg" style={menuIconStyle} />, divider: true, isMenu: true, visible: MENU_NAME.adminTools.released })
        setMenuItems(menuItemList);
      }
    }
    else {
      setMenuItems(menuItemList);
    }
  }, [user.isAdmin, isHomePage])

  const listItemStyle = {
    height: 45,
    minWidth: 45,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
  };
  const menuStyle = {
    height: 45,
    minWidth: 45,
    justifyContent: open ? 'initial' : 'center',
    px: 1.5,
    backgroundColor: "#ECE6E0",
    margin: "1rem",
    borderRadius: "13px"
  };
  const arrowStyle = { color: "#4B3C32", fontSize: "15px" };

  const menuText = {
    lineHeight: 1,
};

  return (
    <Box className='mainDrawer' sx={{ display: 'flex' }}>
      <DrawerStyle sx={{ marginTop: "1rem" }} variant="permanent" open={open}>
        <List sx={{ flex: "1", paddingTop: "0.5rem" }}>
          <ListItem key="logo" sx={{ justifyContent: "center" }}>
            <Link to={"/"}>
              <Box sx={listItemStyle}>
                {open ? <img loading="lazy" style={{ width: "120px", height: "39px", margin: "0.5rem" }} src="/icons/imperial-open.png" /> : <img loading="lazy" style={{ width: "51px", height: "59px" }} src="/icons/imperial-short.png" />}
              </Box>
            </Link>
          </ListItem>
          <br />
          {menuItems.filter(item => item.visible).map((item, index) =>
          (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              {item.divider && <Divider sx={{ backgroundColor: "lightgray", margin: "0.5rem" }} />}
              <ListItemButton sx={item.isMenu ? menuStyle : listItemStyle}
                onClick={(event) => { handleClick(event, item.name) }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 0.5 : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ style: menuText }} primary={<span className='menu-text' >{item.name}</span>} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div className="drawer-footer">
          {open && <span style={{ fontSize: "10px" }}>©2022, Imperial Brands</span>}
          <IconButton
            sx={{
              backgroundColor: "#ECE6E0",
              padding: "2px",
              borderRadius: "2px",
              float: "right",
              marginRight: "0.5rem",
            }}
            onClick={handleDrawer}>
            {open ? <ArrowLeftIcon sx={arrowStyle} /> : <ArrowRightIcon sx={arrowStyle} />}
          </IconButton>
        </div>
        <DashboardSubMenu dashboardAnchor={dashboardAnchor} handleClose={handleClose} />
        <VisualSubMenu visualAnchor={visualAnchor} handleClose={handleClose} />
        <DmsSubMenu dmsAnchor={dmsAnchor} handleClose={handleClose} />
        <DataCubesSubMenu dataCubesAnchor={dataCubesAnchor} handleClose={handleClose} />
        <ConsumerResearchSubMenu consumerResearchAnchor={consumerResearchAnchor} handleClose={handleClose} />
        <AdminSubMenu adminAnchor={adminAnchor} handleClose={handleClose} />
      </DrawerStyle>
    </Box>
  );
}
