import { combineReducers } from "redux";
import appReducer from "./appReducer";
import authReducer from "./authReducer";
import reportReducer from "./reportReducer";
import searchReducer from "./searchReducer";
import userGroupManagement from "./userGroupReducer";

export default combineReducers({
  app: appReducer,
  user: authReducer,
  report: reportReducer,
  search: searchReducer,
  userGroupManagement: userGroupManagement
});