import React, { useEffect, useState } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import Router from "./router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import Drawer from "./components/drawer/Drawer";
import Footer from "./components/footer/Footer";
import {
  getAllReports,
  getFilterOptions,
  getKeywordList,
  getUserAavatar,
  getUserEmailId,
  getUserGroupIds,
  getUserType,
} from "./redux/actions";
import {
  SET_USERNAME_AND_USERID,
  IS_DASHBOARD_RESULTS_LOADING,
} from "./redux/constants";
import jwt_decode from "jwt-decode";
import { adalConfig, authContext, getToken } from "./adalConfig";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loggedUuser, setLoggedUuser] = useState(null);

  useEffect(() => {
    const token = getToken();
    if (token) {
      sessionStorage.setItem("access_token", token);
      setInterval(checkToken, 3000);
      let userDetail = jwt_decode(token);
      setLoggedUuser(userDetail);
      if (!user.userName) {
        dispatch({
          type: SET_USERNAME_AND_USERID,
          payload: { userId: userDetail?.oid, userName: userDetail?.name },
        });
      }
      requestGraphAccessToken();
    }
  }, []);

  useEffect(() => {
    if (user.groupIds.length !== 0 && user.userId) {
      dispatch({ type: IS_DASHBOARD_RESULTS_LOADING, payload: true });
      const reqPayload = { UserId: user?.userId, groupIds: user.groupIds };
      dispatch(getUserType(reqPayload));
      dispatch(getAllReports(reqPayload));
    }
  }, [user.groupIds, user.userId]);

  async function requestGraphAccessToken() {
    new Promise(function (resolve, reject) {
      authContext.acquireToken("https://graph.microsoft.com", function (
        message,
        token,
        msg
      ) {
        if (!msg) {
          sessionStorage.setItem("graph_access_token", token);
          initialCalls();
          resolve(token);
        }
        //  else reject({
        // message: message,
        // msg: msg
        // }); // eslint-disable-line
      });
    });
  }

  function initialCalls() {
    dispatch(getUserAavatar());
    dispatch(getUserEmailId());
    dispatch(getUserGroupIds());
    // dispatch(getKeywordList());
    dispatch(getFilterOptions());
  }

  const checkToken = () => {
    const accessToken = sessionStorage.getItem("access_token");
    let tokenDetail = jwt_decode(accessToken);
    if (tokenDetail?.exp < Math.round(Date.now() / 1000)) {
      authContext.acquireToken(adalConfig.clientId, function (
        message,
        token,
        msg
      ) {
        if (!msg) {
          sessionStorage.setItem("access_token", token);
        }
      });
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#4b3c32",
        contrastText: "#fff",
      },
      secondary: {
        main: "#F1EDE9",
        contrastText: "#444",
      },
      orange: {
        main: "#F07300",
      },
    },
    typography: {
      paraBold: {
        fontSize: "0.75rem",
        fontWeight: "600",
        color: "#555555",
        fontFamily: "Roboto Slab",
      },
      paraLight: {
        fontSize: "0.75rem",
        color: "#999999",
        fontFamily: "Roboto Slab",
      },
      paraDesc: {
        fontSize: "0.75rem",
        color: "#444",
      },
      headingOne: {
        fontSize: "1.2rem",
        color: "#444",
        fontWeight: "bold",
        display: "block",
      },
      headingTwo: {
        fontSize: "1rem",
        color: "#444",
        fontWeight: "bold",
        display: "block",
      },
      fontFamily: ["Roboto", "Roboto Slab"].join(","),
    },
  });

  return (
    <>
      {loggedUuser && (
        <ThemeProvider theme={theme}>
          <Box sx={{ display: "flex" }}>
            <Drawer />
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Router />
              <Footer />
            </Box>
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
