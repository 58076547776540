import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import './dashboard.css';
import Header from '../../components/header';
import { IS_REPORT_UPDATED, SET_DASH_CONTENT_DETAILS } from '../../redux/constants';
import { getAllReports } from '../../redux/actions';
import DashboardTabs from './components/Tabs/DashboardTabs'


const Dashboard = () => {

  const dispatch = useDispatch();
  const { allReports, isReportUpdated } = useSelector(state => state.report);
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (isReportUpdated) {
      setTimeout(() => {
        dispatch(getAllReports({ UserId: user.userId, groupIds: user.groupIds }));
      }, 1000);
      dispatch({ type: IS_REPORT_UPDATED, payload: false });
    }
    // eslint-disable-next-line 
  }, [isReportUpdated])

  useEffect(() => {
    const tabDataMap = prepareTabData(allReports)
    dispatch({ type: SET_DASH_CONTENT_DETAILS, payload: tabDataMap });
  }, [allReports])

  const prepareTabData = (data) => {
    const typeMap = new Map();
    data?.forEach((tabResponse) => {
      let groupName = tabResponse.groupName;
      if (groupName !== 'Favourite Contents') {
        tabResponse.contentDetails.forEach((contentDetails) => {
          let groupName = tabResponse.groupName;
          const contentType = contentDetails.contentType;
          if (!typeMap.has(contentType)) {
            typeMap.set(contentType, new Map());
          }
          if (contentType === 'App' && groupName === 'Key Contents') {
            groupName = 'Key Apps';
          }
          if (contentType === 'Report' && groupName === 'Key Contents') {
            groupName = 'Key Reports';
          }
          if (!typeMap.get(contentType).has(groupName)) {
            typeMap.get(contentType).set(groupName, []);
          }
          typeMap.get(contentType).get(groupName).push(contentDetails);
        })
      }
    })
    const favouriteMap = new Map();
    favouriteMap.set(allReports[0]?.groupName, allReports[0]?.contentDetails)
    typeMap.set('Favourite', favouriteMap)
    return typeMap
  }

  return (
    <Box className='dashboard-page' component="main" sx={{ flexGrow: 1, paddingBottom: '2rem' }}>
      <Header />
      <DashboardTabs />
    </Box>
  )
}

export default Dashboard


