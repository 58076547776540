// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home .blank button {
  display: none;
}

.home .header-bottom {
  display: none;
}

.main-tile {
  margin-top: 30px;
  justify-content: center;
}

.main-tile img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 5px;
}

.list-box {
  justify-content: center;
}

.home .heder-box {
  display: none;
}

@media (max-width: 1024px) {
  .home .blank button {
    display: block;
    /* margin-left: 1rem; */
  }
}

.main-tile .right-arrow {
  width: 40px;
  position: absolute;
  right: 44%;
  bottom: 15px;
}

@media (max-width: 900px) {
  .main-tile .right-arrow {
    right: 48%;
    /* margin-left: 1rem; */
  }
}`, "",{"version":3,"sources":["webpack://./src/views/home/components/MainTile/mainTile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,cAAc;IACd,uBAAuB;EACzB;AACF;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;;AAEA;EACE;IACE,UAAU;IACV,uBAAuB;EACzB;AACF","sourcesContent":[".home .blank button {\r\n  display: none;\r\n}\r\n\r\n.home .header-bottom {\r\n  display: none;\r\n}\r\n\r\n.main-tile {\r\n  margin-top: 30px;\r\n  justify-content: center;\r\n}\r\n\r\n.main-tile img {\r\n  width: 80px;\r\n  height: 80px;\r\n  object-fit: contain;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.list-box {\r\n  justify-content: center;\r\n}\r\n\r\n.home .heder-box {\r\n  display: none;\r\n}\r\n\r\n@media (max-width: 1024px) {\r\n  .home .blank button {\r\n    display: block;\r\n    /* margin-left: 1rem; */\r\n  }\r\n}\r\n\r\n.main-tile .right-arrow {\r\n  width: 40px;\r\n  position: absolute;\r\n  right: 44%;\r\n  bottom: 15px;\r\n}\r\n\r\n@media (max-width: 900px) {\r\n  .main-tile .right-arrow {\r\n    right: 48%;\r\n    /* margin-left: 1rem; */\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
