// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer.footer {
  background-color: #f1ede9;
  font-size: 0.7rem;
  text-align: center;
  padding: 9px;
  color: #4b3c32;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer.footer span {
  margin-right: 30px;
  font-family: "Roboto Slab", serif;
}

footer.footer a {
  color: #4b3c32;
}

@media (max-width: 480px) {
  footer.footer {
    flex-direction: column;
    line-height: 1rem;
  }

  footer.footer span {
    margin-right: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,sBAAsB;IACtB,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":["footer.footer {\n  background-color: #f1ede9;\n  font-size: 0.7rem;\n  text-align: center;\n  padding: 9px;\n  color: #4b3c32;\n  font-weight: 600;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\nfooter.footer span {\n  margin-right: 30px;\n  font-family: \"Roboto Slab\", serif;\n}\n\nfooter.footer a {\n  color: #4b3c32;\n}\n\n@media (max-width: 480px) {\n  footer.footer {\n    flex-direction: column;\n    line-height: 1rem;\n  }\n\n  footer.footer span {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
