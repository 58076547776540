// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-box div {
  font-family: "Roboto Slab", serif;

  font-weight: bold;
  color: #444444;
  font-size: 12px;
}

.table-box td {
  font-family: "Roboto Slab", serif;

  color: #444444;
  font-size: 0.75rem;
}

.meatadata-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meatadata-pagination-box {
  border: 1px solid #bfb5ab;
  border-radius: 6px;
  width: 2.5rem;
  height: 1.8rem;
  margin: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-report-found {
  color: #000;
  text-align: center;
  margin-top: 4rem;
}

.sort-btn {
  width: 3px;
  height: 3px;
}

.sort-btn-disable {
  width: 3px;
  height: 3px;
  opacity: 0.3;
}

.sort-icon {
  width: 0.7rem;
  height: 0.7rem;
}

.card-tooltip .MuiTooltip-tooltip {
  margin-bottom: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/views/report-metrics-detail/reportMetricsDetail.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;;EAEjC,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,iCAAiC;;EAEjC,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".table-box div {\n  font-family: \"Roboto Slab\", serif;\n\n  font-weight: bold;\n  color: #444444;\n  font-size: 12px;\n}\n\n.table-box td {\n  font-family: \"Roboto Slab\", serif;\n\n  color: #444444;\n  font-size: 0.75rem;\n}\n\n.meatadata-pagination {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.meatadata-pagination-box {\n  border: 1px solid #bfb5ab;\n  border-radius: 6px;\n  width: 2.5rem;\n  height: 1.8rem;\n  margin: 0.7rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.no-report-found {\n  color: #000;\n  text-align: center;\n  margin-top: 4rem;\n}\n\n.sort-btn {\n  width: 3px;\n  height: 3px;\n}\n\n.sort-btn-disable {\n  width: 3px;\n  height: 3px;\n  opacity: 0.3;\n}\n\n.sort-icon {\n  width: 0.7rem;\n  height: 0.7rem;\n}\n\n.card-tooltip .MuiTooltip-tooltip {\n  margin-bottom: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
