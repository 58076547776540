export const prepareTabData = (data) => {
  const typeMap = new Map();
  data?.forEach((tabResponse) => {
    let groupName = tabResponse.groupName;
    if (groupName !== "Favourite Contents") {
      tabResponse.contentDetails.forEach((contentDetails) => {
        let groupName = tabResponse.groupName;
        const contentType = contentDetails.contentType;
        if (!typeMap.has(contentType)) {
          typeMap.set(contentType, new Map());
        }
        if (contentType === "App" && groupName === "Key Contents") {
          groupName = "Key Apps";
        }
        if (contentType === "Report" && groupName === "Key Contents") {
          groupName = "Key Reports";
        }
        if (!typeMap.get(contentType).has(groupName)) {
          typeMap.get(contentType).set(groupName, []);
        }
        typeMap.get(contentType).get(groupName).push(contentDetails);
      });
    }
  });
  const favouriteMap = new Map();
  favouriteMap.set(data[0]?.groupName, data[0]?.contentDetails);
  typeMap.set("Favourite", favouriteMap);
  return typeMap;
};
