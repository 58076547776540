// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-card-img {
  width: calc(100% - 12px);
  aspect-ratio: 2/1;
  border: 1px solid #eee;
  margin: 5px;
  border-radius: 3px;
}

.report-tile-title {
  font-size: 0.8rem !important;
  color: #444;
  font-weight: bold !important;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.report-tile-desc {
  font-size: 0.7rem !important;
  color: #444;
  line-height: 0.9rem !important;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.menu-copied-text {
  font-size: small;
  font-weight: bold;
  color: green;
  margin: 1rem;
}

.tile-menu-item {
  font-size: 0.75rem !important;
}

.cardContent {
  cursor: pointer;
  padding: 0px 16px !important;
  margin-bottom: -2px
}

.card-tooltip .MuiTooltip-tooltip {
  margin-bottom: 7px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/report-tile/ReportTile.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,WAAW;EACX,4BAA4B;EAC5B,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,4BAA4B;EAC5B,WAAW;EACX,8BAA8B;EAC9B,cAAc;EACd,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,4BAA4B;EAC5B;AACF;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".report-card-img {\n  width: calc(100% - 12px);\n  aspect-ratio: 2/1;\n  border: 1px solid #eee;\n  margin: 5px;\n  border-radius: 3px;\n}\n\n.report-tile-title {\n  font-size: 0.8rem !important;\n  color: #444;\n  font-weight: bold !important;\n  display: block;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.report-tile-desc {\n  font-size: 0.7rem !important;\n  color: #444;\n  line-height: 0.9rem !important;\n  display: block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.menu-copied-text {\n  font-size: small;\n  font-weight: bold;\n  color: green;\n  margin: 1rem;\n}\n\n.tile-menu-item {\n  font-size: 0.75rem !important;\n}\n\n.cardContent {\n  cursor: pointer;\n  padding: 0px 16px !important;\n  margin-bottom: -2px\n}\n\n.card-tooltip .MuiTooltip-tooltip {\n  margin-bottom: 7px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
