// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
  width: 100%;
  height: 55px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item>.button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose-file-container {
  display: flex;
  align-items: center;
}

.select-thumbnail {
  flex: 1 1;
}

.select-thumbnail input[type="file"] {
  color: white;
  width: 5.7rem;
}

.choosefile-text {
  font-size: 13px;
}

.thumbnail-img {
  max-width: 10rem;
  max-height: 10rem;
  flex: 1 1;
  margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/modal.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,aAAa;EACb,qBAAqB;EACrB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,SAAS;EACT,cAAc;EACd,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,qBAAqB;EACrB,oBAAoB;EACpB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,iBAAiB;EACjB,iBAAiB;EACjB,UAAU;EACV,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,SAAO;EACP,kBAAkB;AACpB","sourcesContent":[".input {\n  width: 100%;\n  height: 55px;\n  padding: 0 1rem;\n  margin-top: 1rem;\n  box-sizing: border-box;\n  font: inherit;\n  border-radius: 0.2rem;\n  border: 2px solid #d4d5d6;\n  color: #565656;\n}\n\n.input.has-error {\n  border-color: tomato;\n}\n\n.error {\n  margin: 0;\n  font-size: 90%;\n  color: tomato;\n}\n\n.tag-item {\n  background-color: #d4d5d6;\n  font-size: 14px;\n  border-radius: 30px;\n  height: 30px;\n  padding: 0 4px 0 1rem;\n  display: inline-flex;\n  align-items: center;\n  margin: 0 0.3rem 0.3rem 0;\n}\n\n.tag-item>.button {\n  background-color: white;\n  width: 22px;\n  height: 22px;\n  border-radius: 50%;\n  border: none;\n  cursor: pointer;\n  font: inherit;\n  margin-left: 10px;\n  font-weight: bold;\n  padding: 0;\n  line-height: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.choose-file-container {\n  display: flex;\n  align-items: center;\n}\n\n.select-thumbnail {\n  flex: 1;\n}\n\n.select-thumbnail input[type=\"file\"] {\n  color: white;\n  width: 5.7rem;\n}\n\n.choosefile-text {\n  font-size: 13px;\n}\n\n.thumbnail-img {\n  max-width: 10rem;\n  max-height: 10rem;\n  flex: 1;\n  margin-right: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
