import React, { useState, useEffect } from "react";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./reportMetrics.css";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Rating } from "@mui/material";
import {
  getallReportMetrics,
  getallReportMetricsSearch,
} from "../../redux/actions";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { NO_REPORT_FOUND } from "../../constants/constants";
import { ResponseLoader } from "../../components/loader";
import {
  SET_META_SORT_TERM,
  SEARCH_METRICS_RESPONSE_STATUS,
  SET_FROM_PAGE,
  IS_METRICS_LOADING,
} from "../../redux/constants";

const headCells = [
  {
    label: "ID",
    column: "contentId",
    sort: true,
  },
  {
    label: "Type",
    column: "contentType",
    sort: true,
  },
  {
    label: "Name",
    column: "contentName",
    sort: true,
  },
  {
    label: "Date Published",
    column: "contentPublishedDate",
    sort: true,
  },
  {
    label: "Likes",
    column: "likes",
    sort: true,
  },
  {
    label: "Ratings",
    column: "ratings",
    sort: true,
  },
  {
    label: "Feedback",
    column: "feedback",
    sort: true,
  },
  {
    label: "",
    align: "right",
    sort: false,
  },
];

export default function ReportMetricsTable(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(15);
  const {
    fromPage,
    setFromPage,
    takePage,
    payloadData,
    urlPramsData,
    pagination,
    searchValue,
    setUrlParamsData,
  } = props;

  const {
    reportMetrics,
    metadataSortTerm,
    isMetricsSearchSuccess,
    isMetricsLoading,
  } = useSelector((state) => state.userGroupManagement);
  const { reportMetrix, totalRecords } = reportMetrics;

  useEffect(() => {
    dispatch({
      type: SET_META_SORT_TERM,
      payload: { column: "", value: "" },
    });
  }, []);

  useEffect(() => {
    if (!searchValue) {
      dispatch({ type: IS_METRICS_LOADING, payload: true });
      dispatch({ type: SEARCH_METRICS_RESPONSE_STATUS, payload: false });
      dispatch({ type: SET_FROM_PAGE, payload: fromPage });
      setTimeout(() => {
        dispatch(getallReportMetrics({ urlParams: urlPramsData, pagination }));
      }, 1000);
    }
  }, [fromPage]);

  useEffect(() => {
    if (isMetricsSearchSuccess && searchValue) {
      dispatch(
        getallReportMetricsSearch({
          searchString: payloadData,
          urlParams: urlPramsData,
          pagination,
        })
      );
      dispatch({ type: IS_METRICS_LOADING, payload: true });
    }
  }, [fromPage]);

  useEffect(() => {
    setFromPage(0);
  }, [urlPramsData, searchValue]);

  useEffect(() => {
    setUrlParamsData("");
  }, [searchValue]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalRecords);

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              align={headCell?.align || "left"}
              padding={"normal"}
            >
              <Box sx={{ display: "flex" }}>
                {headCell.label}
                {headCell.sort && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "0.5rem",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      size="small"
                      className={
                        metadataSortTerm.column === headCell.column &&
                        metadataSortTerm.value === "asc"
                          ? "sort-btn-disable"
                          : "sort-btn"
                      }
                      onClick={() => {
                        dispatch({
                          type: SET_META_SORT_TERM,
                          payload: {
                            column: headCell.column,
                            value: "asc",
                          },
                        });
                        if (
                          metadataSortTerm.column !== headCell.column ||
                          metadataSortTerm.value !== "asc"
                        ) {
                          props.handleSort(headCell?.column, "asc");
                        }
                      }}
                    >
                      <img
                        className="sort-icon"
                        src="./icons/icn_uparrow.svg"
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      className={
                        metadataSortTerm.column === headCell.column &&
                        metadataSortTerm.value === "desc"
                          ? "sort-btn-disable"
                          : "sort-btn"
                      }
                      onClick={() => {
                        dispatch({
                          type: SET_META_SORT_TERM,
                          payload: {
                            column: headCell.column,
                            value: "desc",
                          },
                        });
                        if (
                          metadataSortTerm.column !== headCell.column ||
                          metadataSortTerm.value !== "desc"
                        ) {
                          props.handleSort(headCell?.column, "desc");
                        }
                      }}
                    >
                      <img
                        className="sort-icon"
                        src="./icons/icn_downarrow.svg"
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handlePrevPage = () => {
    setFromPage(Math.max(0, fromPage - takePage));
  };

  const handleNextPage = () => {
    setFromPage(fromPage + takePage);
  };

  return (
    <Box className="table-box">
      {isMetricsLoading ? (
        <ResponseLoader
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "13%",
          }}
          loadingText="  Loading..."
        />
      ) : (
        <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
          <TableContainer>
            <Table
              sx={{ width: "95%", marginLeft: "2rem", marginRight: "2rem" }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead />

              {totalRecords > 0 && (
                <TableBody>
                  {reportMetrix
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">{row.contentId}</TableCell>
                          <TableCell align="left">{row.contentType}</TableCell>
                          <TableCell align="left">{row.contentName}</TableCell>
                          <TableCell align="left">
                            {row.contentPublishedDate
                              ? Moment(row.contentPublishedDate).format(
                                  "Do MMMM YYYY"
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row.likes}</TableCell>
                          <TableCell align="center">
                            <Rating
                              size="small"
                              precision={0.5}
                              value={row.avgRating?.toFixed(1) || 0}
                              readOnly
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row.feedbackByUsersCount}
                          </TableCell>
                          <TableCell align="right">
                            {
                              <IconButton
                                size="small"
                                onClick={() =>
                                  navigate(
                                    `/report-metrics-detail/${row.contentId}`
                                  )
                                }
                              >
                                <NavigateNextIcon />
                              </IconButton>
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 20 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {totalRecords === 0 && (
            <Box className="no-report-found">{NO_REPORT_FOUND}</Box>
          )}
          {totalRecords > 0 && (
            <Box className="meatadata-pagination">
              <IconButton disabled={fromPage === 0} onClick={handlePrevPage}>
                <img src="./icons/icn_left_arrow.svg" />
              </IconButton>
              <span>Page </span>
              <div className="meatadata-pagination-box">
                {Math.round(fromPage / takePage) + 1}
              </div>
              <span> of {Math.ceil(totalRecords / rowsPerPage)}</span>
              <IconButton
                disabled={fromPage + takePage >= totalRecords}
                onClick={handleNextPage}
              >
                <img src="./icons/icn_right_arrow.svg" />
              </IconButton>
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
}
