import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import ReportList from "../../components/report-list";
import { useParams } from "react-router-dom";
import { NO_FAVOURITE_FOUND } from '../../constants/constants'

const FavReports = () => {
  const params = useParams();
  // const groupName = params?.groupName.replace(/-/g, ' ');

  const { allReports } = useSelector(state => state.report);

  const favourites = allReports[0];

  const contents = favourites?.contentDetails

  return (
    <Box component="main" className='all-reports' sx={{ flexGrow: 1, paddingBottom: '2rem' }}>
      <Header />
      {favourites?.groupName === 'Favourite Contents' ?
        <>
          {contents?.length ?
            <ReportList contents={contents} groupName={params.groupName} />
            : <Box sx={{ mt: '6rem', display: 'flex', justifyContent: 'center' }}>{NO_FAVOURITE_FOUND}</Box>
          }
        </> : <Box sx={{ mt: '6rem', display: 'flex', justifyContent: 'center' }}>{NO_FAVOURITE_FOUND}</Box>
      }

    </Box>
  )
};

export default FavReports;
