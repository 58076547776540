import { takeEvery, put } from 'redux-saga/effects';
import { MS_GRAPH_BASE_URL } from '../../AppConstant';
import AxiosInstance from '../../services/api/AxiosInstance';
import { GET_USER_TYPE, SET_USER_TYPE, GET_USER_GROUPIDS, SET_USER_GROUPIDS, GET_USER_AVATAR, SET_USER_DETAIL, GET_USER_DETAIL } from '../constants';

function getOptions(method) {
    const access_token = sessionStorage.getItem("graph_access_token");
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${access_token}`);
    return { method: method, headers: headers };
}

function* getUserEmailId() {
    const options = getOptions("GET");
    const response = yield fetch(`${MS_GRAPH_BASE_URL}me`, options);
    const data = yield response.json();
    yield put({ type: SET_USER_DETAIL, payload: data });
}

function* getAllUserGroups() {
    const options = getOptions("GET");
    const response = yield fetch(`${MS_GRAPH_BASE_URL}me/transitiveMemberOf/microsoft.graph.group?$top=999`, options);
    const data = yield response.json();
    const groupIds = data.value?.map(item => item.id) || [];
    yield put({ type: SET_USER_GROUPIDS, payload: groupIds });
}

function* getUserAvatar() {
    const options = getOptions("GET");
    let response = null;
    let request = new Request(`${MS_GRAPH_BASE_URL}me/photo/$value`, options)
    try {
        yield fetch(request).then((res) => {
            if (res.status === 200) {
                response = res.body.json();

            } else {
                response = res.status === 404 ? "" : null
            }
        });
    }
    catch (error) {
        console.log(response);
    }
    // yield put({ type: SET_USER_AVATAR, payload: response });
}

// get userType ------------------------------
function* getUserType(payload) {
    try {
        const response = yield AxiosInstance.post(`metadataservice/api/Report/GetUserType`, payload);
        const data = yield response.data;
        console.log("Get User type data:", data);
        yield put({
            type: SET_USER_TYPE, payload: data
        });
    } catch (error) {
        console.log('get user type error', error);
    }
}

export function* userSaga() {
    yield takeEvery(GET_USER_DETAIL, getUserEmailId);
    yield takeEvery(GET_USER_GROUPIDS, getAllUserGroups);
    yield takeEvery(GET_USER_AVATAR, getUserAvatar);
    yield takeEvery(GET_USER_TYPE, (action) => getUserType(action.payload));
}