import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Moment from "moment";

import MenuIcon from "@mui/icons-material/Menu";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { Box } from "@mui/system";
import Rating from "@mui/material/Rating";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import {
  getReportDetails,
  markReportLikeFavourite,
  setReportRating,
} from "../../redux/actions";
import { SET_MAIN_DRAWER, SET_REPORT_DETAILS } from "../../redux/constants";

import { ButtonBack } from "../../components/button";
import { FeedbackModal, ShareModal } from "../../components/modal";
import { DownloadLoader, ResponseLoader } from "../../components/loader";
import {
  COPIED,
  appNotFoundMessage,
  errorMessage,
  embedTokenError
} from "../../constants/constants";
import { AUTH_CONFIG } from "../../AppConstant";
import { authContext } from "../../adalConfig";
import { LikeUnlikeAction, reportRating } from "../../common";
import { EmbedAppDetail } from "./powerbiEmbedApp";
import AppNotFound from "./AppNotFound";
import ListDrawer from "./ListDrawer";

import "../report-details/reportDetails.css";

const useStyles = {
  appItem: {
    cursor: "pointer",
    borderBottom: "1px solid #826E5F",
    backgroundColor: "#D9CDC1",
    color: "#5D4F47",
    fontSize: "0.75rem",
  },
  activeItem: {
    backgroundColor: "#826E5F",
    color: "#F7F5F3",
    borderLeft: "4px solid #F07300",
  },
};

const AppDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const appId = params.id;
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.userId) {
      dispatch(getReportDetails({ UserID: user.userId, ReportID: params.id }));
    }
  }, [user.userId]);

  const { allReports, reportDetails, contentStatus } = useSelector(
    (state) => state.report
  );
  const { searchResults } = useSelector((state) => state.search);

  const [acc_token, setAcc_token] = useState("");
  const [embed_url, setEmbed_url] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [showEmbed, setShowEmbed] = useState(false);
  const [appReports, setAppReports] = useState(null);
  const [appDashboards, setAppDashboards] = useState(null);
  const [appList, setAppList] = useState([]);
  const [config, setConfig] = useState({ ...AUTH_CONFIG });
  const [selectedItem, setSelectedItem] = useState(0);
  const [isFeedback, setIsFeedback] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [appNotFound, setAppNotFound] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const publishedDate = reportDetails?.contentPublishedDate
    ? Moment(reportDetails.contentPublishedDate).format("DD MMM YYYY")
    : "";

  useEffect(() => {
    getPowerbiEmbedToken();
    return () => {
      dispatch({ type: SET_REPORT_DETAILS, payload: {} });
    };
  }, []);

  useEffect(() => {
    if (appId && acc_token) {
      getAppReports();
      getAppDashboards();
    }
  }, [appId, acc_token]);

  useEffect(() => {
    if (appReports !== null && appDashboards !== null && !contentStatus) {
      setAppList([...appReports, ...appDashboards]);
    }
  }, [appReports, appDashboards]);

  useEffect(() => {
    if (appList.length && !contentStatus) {
      setConfig({
        ...config,
        workspaceId: "61904430-80ae-4d07-a2bc-ca858391ebc4",
        reportId: appList[0].id,
      });
      getembedUrl(appList[0].reportType, appList[0].id);
    }
  }, [appList]);

  const appListOnClickHandle = (type, id, index) => {
    setConfig({ ...config, reportId: id });
    getembedUrl(type, id);
    setSelectedItem(index);
  };

  function getPowerbiEmbedToken() {
    new Promise(function (resolve, reject) {
      authContext.acquireToken(
        "https://analysis.windows.net/powerbi/api",
        function (message, token, msg) {
          if (!msg) {
            setAcc_token(token);
            sessionStorage.setItem("emb_access_token", token);
            resolve(token);
          } else{
            setAppNotFound(true);
            setError(embedTokenError);
          }
          //else reject({
          //   message: message,
          //   msg: msg,
          // });
        }
      );
    });
  }

  function getAppReports() {
    setIsLoading(true);
    fetch("https://api.powerbi.com/v1.0/myorg/apps/" + appId + "/reports", {
      headers: {
        Authorization: "Bearer " + acc_token,
      },
      method: "GET",
    })
      .then(function (response) {
        response
          .json()
          .then(function (body) {
            // Successful response
            if (response.ok && response.status === 200) {
              setIsLoading(false);
              setAppReports(body["value"]);
            } else if (response.status === 401) {
              setIsLoading(false);
              setAppNotFound(true);
              setError(appNotFoundMessage);
            } else {
              setIsLoading(false);
              setAppNotFound(true);
              setError(errorMessage);
            }
          })
          .catch(function () {
            console.log("Error inside app reports fetch api");
            setAppReports([]);
            setIsLoading(false);
            setAppNotFound(true);
            setError(errorMessage);
          });
      })
      .catch(function (err) {
        console.log("Error in making the App reports API call");
        console.log(err);
        setAppReports([]);
        setIsLoading(false);
        setAppNotFound(true);
        setError(errorMessage);
      });
  }

  function getAppDashboards() {
    setIsLoading(true);
    fetch("https://api.powerbi.com/v1.0/myorg/apps/" + appId + "/dashboards", {
      headers: {
        Authorization: "Bearer " + acc_token,
      },
      method: "GET",
    })
      .then(function (response) {
        response
          .json()
          .then(function (body) {
            // Successful response
            if (response.ok && response.status === 200) {
              setIsLoading(false);
              setAppDashboards(body["value"]);
            } else if (response.status === 401) {
              setIsLoading(false);
              setAppNotFound(true);
              setError(appNotFoundMessage);
            } else {
              setIsLoading(false);
              setAppNotFound(true);
              setError(errorMessage);
            }
          })
          .catch(function () {
            console.log("Error inside app reports fetch api");
            setAppDashboards([]);
            setIsLoading(false);
            setAppNotFound(true);
            setError(errorMessage);
          });
      })
      .catch(function (err) {
        console.log("Error in making the App reports API call");
        console.log(err);
        setAppDashboards([]);
        setIsLoading(false);
        setAppNotFound(true);
        setError(errorMessage);
      });
  }

  function getembedUrl(type, id) {
    setConfig({ ...config, type: type ? "report" : "dashboard", reportId: id });
    let embedApiUrl = "";
    if (type === "PowerBIReport" || type === "PaginatedReport") {
      embedApiUrl = "https://api.powerbi.com/v1.0/myorg/reports/" + id;
    } else {
      embedApiUrl = "https://api.powerbi.com/v1.0/myorg/dashboards/" + id;
    }
    fetch(embedApiUrl, {
      headers: {
        Authorization: "Bearer " + acc_token,
      },
      method: "GET",
    })
      .then(function (response) {
        response
          .json()
          .then(function (body) {
            // Successful response
            if (response.ok) {
              setEmbed_url(body["embedUrl"]);
              showEmbed(true);
              setShowIframe(false);
            }
            // If error message is available
            else {
              if (response.status !== 200) {
                setShowIframe(true);
                setShowEmbed(false);
              }
            }
          })
          .catch(function () {
            if (response.status !== 200) {
              setShowIframe(true);
              setShowEmbed(false);
            }
          });
      })
      .catch(function (err) {
        // Error in making the API call
        console.log(err);
      });
  }

  const PowerBiReport = useCallback(() => {
    return (
      <>
        {acc_token && embed_url ? (
          <EmbedAppDetail
            accessToken={acc_token}
            embedUrl={embed_url}
            config={config}
          />
        ) : (
          showIframe && (
            <div
              className="powerBiDiv"
              dangerouslySetInnerHTML={{ __html: reportDetails.embedUrl }}
            ></div>
          )
        )}
      </>
    );
  }, [acc_token, embed_url, showIframe]);

  const open = useSelector((state) => state.app.isMainDrawerOpen);
  const handleDrawer = () => {
    dispatch({ type: SET_MAIN_DRAWER, payload: !open });
  };

  const handleLike = () => {
    if (reportDetails?.contentID) {
      let payload = {
        Report_Id: reportDetails.contentID,
        Workspace_Id: reportDetails.workSpaceID,
        user_id: user.userId,
        emailid: user.userEmailId,
        IsLiked: !reportDetails.isLiked ? "1" : "0",
        IsFavourite: reportDetails.isFavourite ? "1" : "0",
      };
      dispatch(markReportLikeFavourite(payload));
      const totalLiked =
        reportDetails.totalLikes + (!reportDetails.isLiked ? 1 : -1);
      reportDetails.totalLikes = totalLiked;
      LikeUnlikeAction({
        reportId: reportDetails?.contentID,
        isLiked: !reportDetails.isLiked,
        likeCount: totalLiked,
        allReports,
        searchReports: searchResults,
        dispatch,
      });
    }
  };

  const handleRating = (event, newValue) => {
    console.log("handleRating called", reportDetails?.contentID);
    if (reportDetails?.contentID) {
      dispatch(
        setReportRating({
          UserId: user.userId,
          emailid: user.userEmailId,
          Rating: newValue,
          reportId: reportDetails.contentID,
          workspaceId: reportDetails.workSpaceID,
        })
      );
      reportRating({
        reportId: reportDetails.contentID,
        reportRate: newValue,
        allReports,
        searchReports: searchResults,
        dispatch,
      });
    } else {
      console.log("handleRating failed");
    }
  };

  const handleRaiseTicket = () => {
    if (reportDetails.serviceNowUrlLink) {
      window.open(reportDetails.serviceNowUrlLink, "_blank");
    }
  };

  const handleFeedback = () => {
    setIsFeedback(true);
  };

  const handleShare = () => {
    setIsShare(true);
  };

  const handleFeebackModal = () => {
    setIsFeedback(!isFeedback);
  };

  const handleShareModal = () => {
    setIsShare(!isShare);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/app-details/${params.id}`
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const menuList = [
    {
      title: "Raise Ticket",
      src: "/icons/icn_ticket.svg",
      clickFunc: handleRaiseTicket,
    },
    {
      title: "Feedback",
      src: "/icons/icn_feedback.svg",
      clickFunc: handleFeedback,
    },
    {
      title: "Share App",
      src: "/icons/icn-email-share.svg",
      clickFunc: handleShare,
    },
    {
      title: "Copy Link",
      src: "/icons/icn_copy.svg",
      clickFunc: handleCopyLink,
    },
  ];

  const IconText = ({ index, icon, title, onClick }) => (
    <Box key={index} className="report-dtl-IconText" onClick={onClick}>
      {icon}
      <span
        style={{
          fontSize: "0.75rem",
          paddingLeft: "5px",
          fontWeight: "600",
          color: "#5D4F46",
        }}
      >
        {title}
      </span>
    </Box>
  );

  return (
    <Box className="main-box">
      <Box className="report-dtl-header">
        <Box className="report-dtl-display">
          <Box>
            <ButtonBack />
          </Box>
          <Box className="report-detail-box">
            <span style={{ fontSize: "normal", fontWeight: "bold" }}>
              {reportDetails?.contentName || "App Name"}
            </span>
            <div className="report-info">
              <span>Published on {publishedDate},</span>
              <span>
                Created by - {reportDetails?.contentOwner || "App Owner"}
              </span>
            </div>
            <Box className="liked-count-view">
              <Stack sx={{ alignItems: "center" }} direction="row" spacing={0}>
                <IconButton
                  size="small"
                  aria-label="add to favorites"
                  onClick={handleLike}
                >
                  {reportDetails?.isLiked ? (
                    <ThumbUpAltIcon
                      sx={{ color: "#F07300" }}
                      fontSize="inherit"
                    />
                  ) : (
                    <ThumbUpOffAltIcon fontSize="inherit" />
                  )}
                </IconButton>
                <Typography sx={{ fontSize: "11px", fontWeight: "600" }}>
                  {reportDetails?.totalLikes || 0} Likes
                </Typography>
              </Stack>
              <Rating
                onChange={handleRating}
                precision={0.5}
                size="small"
                style={{ verticalAlign: "middle", marginLeft: "30px" }}
                value={reportDetails?.rating || reportDetails?.reportRate || 0}
              />
              <Typography
                style={{
                  paddingLeft: "5px",
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                }}
              >
                {reportDetails?.rating || reportDetails?.reportRate || 0}/5
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="icon-box-main">
          {isCopied && <span className="copied-text">{COPIED}</span>}
          <Box className="icon-box">
            {menuList.map((item, index) =>
              item.showDownload ? (
                <Box className="icon-box-main">
                  <DownloadLoader loadingText="Downloading..." />
                </Box>
              ) : (
                <IconText
                  key={index}
                  icon={<img className="avatar-icon" src={item.src} />}
                  title={item.title}
                  onClick={item.clickFunc}
                />
              )
            )}
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <ResponseLoader
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "13%",
          }}
          loadingText="  Loading..."
        />
      ) : (
        <>
          {appNotFound ? (
            <AppNotFound message={error} />
          ) : (
            <div className="app-dtl-box">
              {Object.keys(reportDetails).length ? (
                <ListDrawer
                  selectedItem={selectedItem}
                  appListOnClickHandle={appListOnClickHandle}
                  appList={appList}
                  appName={reportDetails?.contentName}
                />
              ) : (
                ""
              )}
              {!contentStatus ? (
                <Box
                  sx={{ flexGrow: 8, maxHeight: "100vh", minHeight: "100vh" }}
                >
                  <PowerBiReport />
                </Box>
              ) : (
                ""
              )}

              {
                <FeedbackModal
                  reportId={reportDetails?.contentID}
                  modalTitle="Feedback Form"
                  inputPlaceHolder="Write a feedback"
                  openFormDialog={isFeedback}
                  handleClose={handleFeebackModal}
                />
              }
              {isShare && (
                <ShareModal
                  openShareModal={isShare}
                  handleClose={handleShareModal}
                  reportId={reportDetails?.contentID}
                  contentType={reportDetails?.contentType}
                />
              )}
            </div>
          )}
        </>
      )}
      <Box className="responsiveMenu">
        <IconButton onClick={handleDrawer} aria-label="menu" size="small">
          {window.innerWidth <= 1024 && <MenuIcon fontSize="inherit" />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default AppDetails;
