import React from 'react';
import "../App.css";
import HomePage from '../views/home';

const Authentication = () => {

    return (<HomePage />)
}

export default Authentication;
