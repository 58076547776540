import {
  SET_ALL_GROUPS,
  SET_ALL_PERSONA,
  SET_ALL_REPORT_METADATA,
  SET_ALL_REPORT_METRICS,
  SET_ALL_REPORT_METRICS_DETAIL,
  SET_EDIT_META_DRAWER,
  SET_USER_MGMT_FILTER_VALUE,
  SET_META_DRAWER,
  SET_PERSONAGROUP_MAPPINGS,
  SET_USER_MGMT_SEARCH_VALUE,
  SET_REPORT_METADATA,
  SET_GROUP_REORDER,
  UPDATE_GROUP_REORDER_STATUS,
  REPORT_METADATA_EDIT_STATUS,
  UPLOAD_REPORT_METADATA_EXCEL_STATUS,
  UPLOAD_REPORT_METADATA_EXCEL_ERROR,
  SET_META_SORT_TERM,
  IS_META_UPDATED,
  SET_ALL_DOWNLOAD_CONTENT,
  UPDATE_IS_HIDE_VALUE,
  SEARCH_RESPONSE_STATUS,
  SEARCH_METRICS_RESPONSE_STATUS,
  SET_FROM_PAGE,
  IS_METADATA_LOADING,
  IS_METRICS_LOADING,
} from "../constants";

const initialState = {
  isMetaDrawerOpen: false,
  isMetaEditDrawerOpen: false,
  personaList: [],
  reportMetadata: {},
  reportMetadataForEdit: {},
  metadataEditStatus: null,
  metadataSortTerm: { column: "", value: "" },
  isMetadataUpdated: false,
  reportMetrics: {},
  reportMetricsDetail: {},
  reportGroups: null,
  groups: [],
  personaGroupMappings: null,
  searchValue: "",
  filterValue: "",
  groupReorderStatus: null,
  uploadReportExcelStatus: null,
  uploadReportExcelError: null,
  downloadExcelContent: {},
  isHideStatus: false,
  isSearchSuccess: false,
  isMetricsSearchSuccess: false,
  fromPageValue: null,
  isMetadatLoading: null,
  isMetricsLoading: null,
};

export default function userGroupManagement(state = initialState, action) {
  switch (action.type) {
    case SET_META_DRAWER:
      return { ...state, isMetaDrawerOpen: action.payload };
    case SET_EDIT_META_DRAWER:
      return { ...state, isMetaEditDrawerOpen: action.payload };
    case SET_ALL_PERSONA:
      return { ...state, personaList: action.payload };
    case SET_ALL_REPORT_METADATA:
      return { ...state, reportMetadata: action.payload };
    case SET_REPORT_METADATA:
      return { ...state, reportMetadataForEdit: action.payload };
    case REPORT_METADATA_EDIT_STATUS:
      return { ...state, metadataEditStatus: action.payload };
    case SET_META_SORT_TERM:
      return { ...state, metadataSortTerm: action.payload };
    case IS_META_UPDATED:
      return { ...state, isMetadataUpdated: action.payload };
    case SET_ALL_REPORT_METRICS:
      return { ...state, reportMetrics: action.payload };
    case SET_ALL_REPORT_METRICS_DETAIL:
      return { ...state, reportMetricsDetail: action.payload };
    case SET_GROUP_REORDER:
      return { ...state, reportGroups: action.payload };
    case SET_ALL_GROUPS:
      return { ...state, groups: action.payload };
    case SET_PERSONAGROUP_MAPPINGS:
      return { ...state, personaGroupMappings: action.payload };
    case SET_USER_MGMT_SEARCH_VALUE:
      return { ...state, searchValue: action.payload };
    case SET_USER_MGMT_FILTER_VALUE:
      return { ...state, filterValue: action.payload };
    case UPDATE_GROUP_REORDER_STATUS:
      return { ...state, groupReorderStatus: action.payload };
    case UPLOAD_REPORT_METADATA_EXCEL_STATUS:
      return { ...state, uploadReportExcelStatus: action.payload };
    case UPLOAD_REPORT_METADATA_EXCEL_ERROR:
      return { ...state, uploadReportExcelError: action.payload };
    case SET_ALL_DOWNLOAD_CONTENT:
      return { ...state, downloadExcelContent: action.payload }
    case UPDATE_IS_HIDE_VALUE:
      return { ...state, isHideStatus: action.payload };
    case SEARCH_RESPONSE_STATUS:
      return { ...state, isSearchSuccess: action.payload };
    case SEARCH_METRICS_RESPONSE_STATUS:
      return { ...state, isMetricsSearchSuccess: action.payload };
    case SET_FROM_PAGE:
      return { ...state, fromPageValue: action.payload };
    case IS_METADATA_LOADING:
      return { ...state, isMetadatLoading: action.payload };
    case IS_METRICS_LOADING:
      return { ...state, isMetricsLoading: action.payload };
    default:
      return state;
  }
}
